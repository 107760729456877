import { OwnerSelectOption } from '../budget-settings-page/budget-settings-page.type';
import { BudgetTimeframe } from 'app/shared/types/timeframe.interface';

export enum BudgetTableRecordType {
  Group = 'Group',
  Segment = 'Segment'
}

export interface BudgetTableRecordValues {
  allocated: Record<number, number>;
  forecasted?: Record<number, number>;
  total: number;
  projected?: number;
  totalForecast?: number;
}

export interface BudgetTableRecord {
  id: number;
  key: string;
  name: string;
  type: BudgetTableRecordType;
  owner: Partial<OwnerSelectOption>;
  values: BudgetTableRecordValues;
  segmentGroup?: number;
  segmentGroupKey?: string;
  nestedRecords?: BudgetTableRecord[];
}

export enum BudgetTableActionType {
  SegmentChange = 'SegmentChange',
  SegmentAmountChange = 'SegmentAmountChange',
  TimeframeLockChange = 'TimeframeLockChange',
  SegmentCreate = 'SegmentCreate',
  SegmentDelete = 'SegmentDelete',
  SegmentDuplicate = 'SegmentDuplicate',
  SegmentsUngroup = 'SegmentsUngroup',
  SegmentsGroup = 'SegmentsGroup',
  SegmentsMove = 'SegmentsMove',
  GroupChange = 'GroupChange',
  GroupSegmentCreate = 'GroupSegmentCreate',
  GroupDuplicate = 'GroupDuplicate',
}

export interface BudgetTableChangeEvent {
  actionType: BudgetTableActionType;
  payload: any;
}

export interface BudgetTableExpenseTotals {
  segments: Record<number, BudgetTableRecordValues>;
  groups: Record<number, BudgetTableRecordValues>;
  grandTotal: BudgetTableRecordValues;
}

export interface BudgetTableGesturesDataSource {
  record: BudgetTableRecord;
  timeframe: BudgetTimeframe;
}
