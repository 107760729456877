<td class="name-col">
  <div class="create-item-line"
       *ngIf="!performanceMode && editPermission && !newItemCreationActive && (record.type === manageTableRowType.Campaign || record.type === manageTableRowType.ExpenseGroup)"
       (click)="createNewItemTemplate.next()"></div>

  <div class="cell-content">
    <div class="name-cell-wrapper">
      <ng-container
        *ngTemplateOutlet="controlsCellTemplate; context: { $implicit: record, level: level, parent: parent }"
      ></ng-container>
    </div>
    <div class="performance-cell">
      <metric-performance-arrow
        *ngIf="record.type === manageTableRowType.Campaign && performanceColumnData"
        metricPerformanceTooltip
        [showTooltip]="true"
        [progressState]="performanceColumnData.progressState"
        [estimatedDiffPercentage]="performanceColumnData.estimatedDiffPercentage"
        [current]="performanceColumnData.current"
        [target]="performanceColumnData.estimatedTarget"
        [name]="performanceColumnData.name"
        (onClick)="handlePerformanceClick.next(performanceColumnData.mappingId)"
      ></metric-performance-arrow>
    </div>
  </div>
</td>

<td *ngFor="let timeframe of timeframes; let i = index; trackBy: identifyTimeframe"
    class="timeframe-col"
    [ngClass]="{
      'locked': timeframe.locked,
      'is-hidden': !budgetMode || !timeframe.isVisible
   }"
   [id]="'timeframe-data' + i"
>
  <ng-container *ngIf="showDataCells">
    <ng-container
      *ngTemplateOutlet="allocationBreakdownTemplate; context: {
        $implicit: record,
        timeframeAllocations: ((timeframe.isOriginal ? allocations : presentationAllocations) || {})[timeframe.id],
        timeframe: timeframe
      }"
    ></ng-container>
  </ng-container>
</td>

<td *ngIf="performanceMode" class="performance-column" @slideFromRightColumn>
  <div class="cell-content sub-columns" *ngIf="showDataCells">
    <div *ngFor="let column of performanceColumns;" class="default-value">
        <span *ngIf="record.performanceData && record.performanceData[column.id]; else emptyValue">
          {{ record.performanceData[column.id][0] | absoluteNumber: '-' }}
        </span>
        <ng-template #emptyValue>
          <span *ngIf="record.type !== manageTableRowType.Goal">-</span>
        </ng-template>
    </div>
  </div>
</td>

<ng-template #controlsCellTemplate let-record let-level="level" let-parent="parent">
  <div class="selection" *ngIf="!performanceMode">
    <mat-checkbox
      *ngIf="editPermission && record.isSelectable"
      color="primary"
      class="control checkbox-control pl-mat-checkbox"
      (change)="selectionChange.emit($event.checked)"
      [checked]="selectionState.records[record.id]?.value === SelectionValue.Active"
      [indeterminate]="selectionState.records[record.id]?.value === SelectionValue.Indeterminate"
    ></mat-checkbox>
  </div>
  <div class="hierarchy-info" *ngIf="hasHiddenHierarchy">
    <ng-container *ngIf="record.hierarchyInfo">
      <icon-fa-duotone-list-tree
        (mouseleave)="hideHierarchyTooltip()"
        (mouseenter)="showHierarchyTooltip(record, $event)"
        [size]="'16'"></icon-fa-duotone-list-tree>
    </ng-container>
  </div>
  <div class="level" [ngStyle]="{ 'margin-left': (12 + (level || 0) * 26) + 'px' }">
    <arrow-toggler
      *ngIf="record.children?.length"
      [active]="!togglingState[record.id]"
      (change)="handleToggleChange.emit($event)"
    ></arrow-toggler>
    <div class="level-indentation" *ngIf="!record.children?.length"></div>
  </div>
  <object-name-cell
    *ngIf="record.id"
    [name]="record.name + (record.isClosed ? ' [Closed]' : '')"
    [isClickable]="routeActionByRowType[record.type]"
    dragImage
    [plDraggable]="allowDrag(record)"
    [icon]="iconByRowType[record.type]"
    [objectTypeName]="objectTypeNameMap[record.objectTypeId] || null"
    [imageName]="record.name + (record.isClosed ? ' [Closed]' : '')"
    [selectedRecordsCount]="selectedRecords?.length"
    [isClosed]="record.isClosed"
    [rowType]="record.type"
    (onClick)="onNameClick.emit()"
    (dragStart)="onEntityDragStart.emit(record)"
    (dragEnd)="onEntityDragEnd.emit()"
  >
  </object-name-cell>
  <ng-container *ngIf="!record.segmentRelated; else segmentRowsDescription">
    <object-ceg-name-input
      *ngIf="record.id === null"
      [icon]="iconByRowType[record.type]"
      [objectType]="record.type"
      (nameChanged)="createItemFromTemplate.emit($event)"></object-ceg-name-input>
    <div class="scr-info" *ngIf="record.sharedCostRuleId">
      <icon-shared
        class="icon-shared"
        matTooltipPosition="above"
        matTooltipClass="dark-theme-tooltip above multi-line"
        sharedCostRulesTooltip
        [actionTooltipContext]="record.sharedCostRuleId"
        [segments]="segments"
        [sharedCostRules]="sharedCostRules"
        [state]="record.sharedCostRuleId | sharedCostState:
          (!level || parent.type !== manageTableRowType.Campaign ? null : parent) : (!level || parent.type !== manageTableRowType.Campaign ? record.children : []) : 'sharedCostRuleId'"
        [showTooltip]="true"
        [size]="20"
      ></icon-shared>
    </div>
    <div class="status">{{ record.status }}</div>
    <button *ngIf="editPermission && !record.segmentRelated && record.isSelectable && !performanceMode"
            mat-icon-button
            class="record-actions-trigger"
            [ngClass]="{ 'is-active': contextMenuTargetId === record.id }"
            (click)="openContextMenu(record, $event)">
      <fa-icon [icon]="['far', 'ellipsis-v']"></fa-icon>
    </button>
    <new-entity-label [createdDate]="record.createdDate">
      <span #lastCreatedElementAnchor *ngIf="lastCreatedObjectId === record.objectId"></span>
    </new-entity-label>
  </ng-container>
</ng-template>

<ng-template #segmentRowsDescription>
  <div class="segment-breakdown" [ngClass]="{ 'with-formula': segmentBreakdownConfig.formula }">
    <div class="sub-row-item"
         *ngIf="segmentBreakdownConfig.campaignsAndPrograms || segmentBreakdownConfig.unallocated">
      <span class="sub-title">Available</span>
      <span class="title">{{ labelByRowType[record.type] }}</span>
    </div>
    <div class="sub-row-item" *ngIf="segmentBreakdownConfig.campaignsAndPrograms">
      <span class="operand">-</span>
      <span class="title">Campaigns & Exp.Groups</span>
    </div>
    <div class="sub-row-item" *ngIf="segmentBreakdownConfig.unallocated">
      <span class="operand">=</span>
      <span class="title">Unallocated</span>
    </div>
  </div>
</ng-template>

<ng-template #allocationBreakdownTemplate let-record let-timeframeAllocations="timeframeAllocations" let-timeframe="timeframe">
  <div *ngIf="timeframeAllocations" class="cell-content sub-columns">
    <div *ngFor="let column of budgetColumns; trackBy: identifyColumn"
         [ngClass]="{ 'is-hidden': !budgetColumnsVisibility[column.id] }">
      <div class="segment-breakdown" [id]="'value-' + column.id">
        <div class="sub-row-item" [class.p-0]="column.id === budgetColumnName.Budget || column.id === budgetColumnName.Forecast">
          <ng-container
            *ngTemplateOutlet="valueCell;
              context: { $implicit: timeframeAllocations, rowType: record.type, columnName: column.id, rowKey: segmentBreakdownRow.ownAmount, timeframe: timeframe }"
          ></ng-container>
        </div>
        <ng-container *ngIf="record.segmentRelated">
          <div class="sub-row-item" [class.p-0]="column.id === budgetColumnName.Budget || column.id === budgetColumnName.Forecast" *ngIf="segmentBreakdownConfig.campaignsAndPrograms">
            <ng-container
              *ngTemplateOutlet="valueCell; context: { $implicit: timeframeAllocations, columnName: column.id, rowKey: segmentBreakdownRow.campaignsAndPrograms, timeframe: timeframe }"
            ></ng-container>
          </div>
          <div class="sub-row-item" [class.p-0]="column.id === budgetColumnName.Budget || column.id === budgetColumnName.Forecast" *ngIf="segmentBreakdownConfig.unallocated">
            <ng-container
              *ngTemplateOutlet="valueCell; context: { $implicit: timeframeAllocations, columnName: column.id, rowKey: segmentBreakdownRow.unallocated, timeframe: timeframe }"
            ></ng-container>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #valueCell let-allocation let-rowType="rowType" let-columnName="columnName" let-rowKey="rowKey" let-timeframe="timeframe">
  <ng-container *ngIf="columnName === budgetColumnName.Budget" [ngSwitch]="rowKey">
    <ng-container *ngSwitchCase="'ownAmount'">
      <ng-container [ngSwitch]="rowType">
        <budget-allocation-cell
          *ngSwitchCase="manageTableRowType.Segment"
          [allocated]="allocation[columnName][rowKey]"
          [spent]="null"
          [remaining]="allocation[budgetColumnName.Available][rowKey]"
          [disabled]="record.isClosed || timeframe?.locked"
          [editable]="isAdmin && timeframe?.isOriginal"
          [showDifference]="isAdmin && timeframe?.isOriginal"
          [allowGestures]="!record.isClosed && !timeframe?.locked"
          [hasRemainingBudget]="true"
          [actionTooltipContext]="segmentTooltipContext"
          [droppable]="!restrictedFromDrop[record.id]"
          (onChange)="handleSegmentAllocationChange($event, { record: record, timeframe: timeframe })"
          (onDoubleClick)="handleDoubleClick($event, { record: record, timeframe: timeframe })"
          (onDrop)="handleOnDrop($event, { record: record, timeframe: timeframe })"
          (onDragStart)="handleOnDragStart($event, { record: record, timeframe: timeframe })"
          (onDragEnd)="handleOnDragEnd()"
          (onFocus)="handleOnFocus($event, { record: record, timeframe: timeframe })"
          [id]="timeframe?.shortName"
        >
        </budget-allocation-cell>
        <budget-allocation-cell
          *ngSwitchCase="rowType === manageTableRowType.Campaign || rowType === manageTableRowType.ExpenseGroup ? rowType : ''"
          [allocated]="allocation[columnName][rowKey]"
          [spent]="null"
          [remaining]="allocation[budgetColumnName.Available][rowKey]"
          [editable]="editPermission && !record.isClosed && record.isEditable && timeframe?.isOriginal"
          [disabled]="record.isClosed || timeframe.locked"
          [showDifference]="timeframe?.isOriginal"
          [allowGestures]="editPermission && !record.isClosed"
          [hasRemainingBudget]="true"
          [actionTooltipContext]="tooltipContext"
          [droppable]="isCegDropAllowed(record)"
          (onChange)="handleAllocationChange($event, { record: record, timeframe: timeframe })"
          (onDoubleClick)="handleDoubleClick($event, { record: record, timeframe: timeframe })"
          (onDrop)="handleOnDrop($event, { record: record, timeframe: timeframe })"
          (onDragStart)="handleOnDragStart($event, { record: record, timeframe: timeframe })"
          (onDragEnd)="handleOnDragEnd()"
          (onFocus)="handleOnFocus($event, { record: record, timeframe: timeframe })"
          [id]="timeframe?.shortName"
        >
        </budget-allocation-cell>
        <div *ngSwitchDefault class="default-value">
          {{ allocation[columnName][rowKey] | absoluteNumber }}
        </div>
      </ng-container>
    </ng-container>
    <ng-container *ngSwitchDefault>
      <div class="default-value">
        {{ allocation[columnName][rowKey] | absoluteNumber }}
      </div>
    </ng-container>
  </ng-container>

  <ng-container *ngIf="columnName === budgetColumnName.Forecast" [ngSwitch]="rowKey">
    <ng-container *ngSwitchCase="'ownAmount'">

    <ng-container [ngSwitch]="rowType" >
      
      <budget-allocation-cell
          *ngSwitchCase="manageTableRowType.Segment"
          [allocated]="allocation[columnName][rowKey]"
          [spent]="null"
          [remaining]="null"
          [disabled]="record.isClosed || timeframe?.locked"
          [editable]="isAdmin && timeframe?.isOriginal"
          [showDifference]="false"
          [allowGestures]="!record.isClosed && !timeframe?.locked"
          [hasRemainingBudget]="false"
          [actionTooltipContext]="segmentTooltipContext"
          [droppable]="!restrictedFromDrop[record.id]"
          (onChange)="handleSegmentAllocationChange($event, { record: record, timeframe: timeframe, type: 'forecast' })"
          (onDoubleClick)="handleDoubleClick($event, { record: record, timeframe: timeframe })"
          (onDrop)="handleOnDrop($event, { record: record, timeframe: timeframe })"
          (onDragStart)="handleOnDragStart($event, { record: record, timeframe: timeframe })"
          (onDragEnd)="handleOnDragEnd()"
          [id]="timeframe?.shortName"
          class="forecast-editable-value-cell"
        >
        </budget-allocation-cell>

      <budget-allocation-cell
      *ngSwitchCase="record.isEditable && rowType === manageTableRowType.Campaign || (rowType === manageTableRowType.ExpenseGroup) ? rowType : ''"
          [allocated]="allocation[columnName][rowKey]"
          [spent]="null"
          [remaining]="null"
          [editable]="editPermission && !record.isClosed && record.isEditable && timeframe?.isOriginal"
          [disabled]="record.isClosed || timeframe.locked"
          [showDifference]="false"
          [allowGestures]="editPermission && !record.isClosed"
          [hasRemainingBudget]="false"
          [actionTooltipContext]="tooltipContext"
          [droppable]="isCegDropAllowed(record)"
          (onChange)="handleAllocationChange($event, { record: record, timeframe: timeframe , type: 'forecast' })"
          (onDoubleClick)="handleDoubleClick($event, { record: record, timeframe: timeframe })"
          (onDrop)="handleOnDrop($event, { record: record, timeframe: timeframe })"
          (onDragStart)="handleOnDragStart($event, { record: record, timeframe: timeframe })"
          (onDragEnd)="handleOnDragEnd()"
          [id]="timeframe?.shortName"
          class="forecast-editable-value-cell"
      >
      </budget-allocation-cell>  
      <span
       *ngSwitchDefault
       class="simple-value forecast-uneditable"
        [class.clickable]="false"
        (click)="onActualClick(columnName, allocation[columnName][rowKey], timeframe, rowKey)"
  > - </span>


    </ng-container>
  </ng-container>
  <ng-container *ngSwitchDefault>
    <div class="simple-value forecast-uneditable" [class.clickable]="false" (click)="onActualClick(columnName, allocation[columnName][rowKey], timeframe, rowKey)">
      -
    </div>
  </ng-container>

</ng-container>
  <span *ngIf="columnName !== budgetColumnName.Budget && columnName !== budgetColumnName.Forecast"
        class="simple-value"
        [class.clickable]="isActualCellClickable(columnName, allocation[columnName][rowKey], timeframe, rowKey)"
        (click)="onActualClick(columnName, allocation[columnName][rowKey], timeframe, rowKey)"
  >{{ allocation[columnName][rowKey] | absoluteNumber }}</span>
</ng-template>

<td *ngIf="totalsSidebarState !== sidebarState.Hidden"
    class="total-column"
    [@slideFromRightColumn]
    [@.disabled]="freezeTotalsSidebarAnimations"
>
  <row-totals
    *ngIf="showDataCells"
    class="cell-content sub-columns"
    [record]="record"
    [timeframes]="timeframes"
    [budgetColumns]="budgetColumns"
    [budgetColumnsVisibility]="budgetColumnsVisibility"
    [segmentBreakdownConfig]="segmentBreakdownConfig"
    [totalsSidebarState]="totalsSidebarState"
  ></row-totals>
</td>
