import { BudgetObjectSegmentDO } from './budget-object-segment-do.interface';

export enum SegmentDataInheritanceAction {
  None = 'none',
  Keep = 'keep',
  Replace = 'replace'
}

export interface SegmentDataInheritance {
  action: SegmentDataInheritanceAction;
  payload: BudgetObjectSegmentDO;
}

export enum ParentCustomFieldInheritanceAction {
  None = 'none',
  Keep = 'keep',
  Replace = 'replace',
  Update = 'update'
}

