import { inject, Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { PfmV2Service } from '../../../shared/services/base/pfm-v2.service';
import { BudgetAndSpentDataDO } from './budget-minus-expenses-widget.interface';

@Injectable()
export class BudgetMinusExpenseWidgetService {
  private v2_api_manager = inject(PfmV2Service);
  public budgetMinusExpenseData = new Subject<BudgetAndSpentDataDO>();
  public budgetMinusExpenseData$ = this.budgetMinusExpenseData.asObservable();

  private endpoints = {
    budget_and_spent_data: (id: number) => `budget/${id}/budget_and_spent_data/`
  };

  public getBudgetMinusExpenses(budget_id: number, company_id: number) : void {
    const preparedEndpoint = this.endpoints.budget_and_spent_data(budget_id);
    this.v2_api_manager.get<BudgetAndSpentDataDO>(
      preparedEndpoint,
      {
        "company":company_id
      }
    ).subscribe(data => {
      this.budgetMinusExpenseData.next(data);
    });
    this.budgetMinusExpenseData.next(null);
  }
}
