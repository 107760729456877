import { ChangeDetectorRef, Component, inject, Input, OnDestroy, OnInit } from '@angular/core';
import { WidgetConfig, WidgetState } from '../../types/widget.interface';
import { WidgetStateService } from '../../services/widget-state.service';
import { BudgetDataService } from 'app/dashboard/budget-data/budget-data.service';
import { distinctUntilChanged, filter, debounceTime, take, takeUntil } from 'rxjs/operators';
import {
  HierarchySelectConfig,
  HierarchySelectItem
} from 'app/shared/components/hierarchy-select/hierarchy-select.types';
import { Configuration } from 'app/app.constants';
import { BudgetSegmentAccess } from 'app/shared/types/segment.interface';
import { combineLatest, forkJoin, Observable, Subject, tap } from 'rxjs';
import { CampaignTypeNames } from 'app/shared/enums/campaign-types.enum';
import { CampaignDO, LightCampaign } from 'app/shared/types/campaign.interface';
import { CampaignService } from 'app/shared/services/backend/campaign.service';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { AppRoutingService } from 'app/shared/services/app-routing.service';
import { HomePageService } from '../../services/home-page.service';
import { SegmentMenuHierarchyService } from 'app/shared/services/segment-menu-hierarchy.service';
import { ObjectAccessManagerService } from 'app/shared/services/object-access-manager.service';

@Component({
  selector: 'assign-campaigns-segments-widget',
  templateUrl: './assign-campaign-segments-widget.component.html',
  styleUrls: ['./assign-campaign-segments-widget.component.scss']
})
export class AssignCampaignSegmentsWidgetComponent implements OnInit, OnDestroy {
  private readonly widgetStateManager = inject(WidgetStateService);
  private readonly cdRef = inject(ChangeDetectorRef);
  private readonly budgetDataService = inject(BudgetDataService);
  private readonly campaignService = inject(CampaignService);
  private readonly configuration = inject(Configuration);
  private readonly homePageService = inject(HomePageService);
  private readonly appRoutingService = inject(AppRoutingService);
  private readonly segmentMenuService = inject(SegmentMenuHierarchyService);
  private readonly objectAccessManager = inject(ObjectAccessManagerService);
  private defaultSegment;
  private campaigns;

  @Input() config: WidgetConfig;

  public widgetState = WidgetState;
  public state = WidgetState.INITIAL;
  public adsCampaigns: LightCampaign[] = [];
  public selectItems: HierarchySelectItem[] = [];
  public selectedValue = '';
  public selectConfig: HierarchySelectConfig = {
    fieldLabel: '',
    withSearch: true,
    emptyValueLabel: '',
    searchPlaceholder: 'Search Segments',
    allGroups: false,
    allPlural: 'Segments'
  };
  public selectedCampaigns: { [key: string]: boolean } = {};
  public readonly ASSIGN_SEGMENT_NOTICE = 'When you select a segment for these campaigns, all the campaigns’ expenses will also be assigned to that segment.';

  private readonly destroy$ = new Subject<void>();
  private allowedADSTypes: Array<string> = [CampaignTypeNames.GOOGLE_ADS, CampaignTypeNames.LINKEDIN_ADS];

  ngOnInit(): void {

    this.setState(WidgetState.LOADING);
    this.homePageService.noBudgets$
    .pipe(
      takeUntil(this.destroy$),
      take(1)
      )
      .subscribe(
        () => {
          this.setState(WidgetState.HIDDEN);
          this.destroy$.next();
        });

    this.budgetDataService.lightCampaignListBehavior$.pipe(
      filter((data: any[]) => data !== null),
      takeUntil(this.destroy$)
    ).subscribe((data) => {
      this.campaigns = data;
      this.loadData();
    });

  }

  loadData() {
    combineLatest([
      this.budgetDataService.segmentGroupListBehavior$,
      this.homePageService.contextData$
    ] as any).pipe(
      filter(([segmentGroups, contextData]) => {
        return contextData !== null
          && contextData.campaignTypes?.length>0
          && contextData.campaignTypes[0].companyId === contextData.company.id;
      }),
      tap(([segmentGroups, contextData]) => {
        this.defaultSegment = contextData?.segments.find(segment => segment.name === this.configuration.defaultSegmentName);
      }),
      takeUntil(this.destroy$)
    ).subscribe(([segmentGroups, contextData]) => {
      const {campaignTypes, segments, sharedCostRules} = contextData;
      this.selectedCampaigns = {};
      const adsTypes = campaignTypes.filter(type => this.allowedADSTypes.includes(type.name)).map(type => type.id);

      if (!this.defaultSegment) {
        this.setState(WidgetState.EMPTY);
        return;
      }

      this.selectedValue = this.defaultSegment ? this.configuration.OBJECT_TYPES.segment + '_' + this.defaultSegment.id : '';
      this.adsCampaigns = this.filterAdsCampaigns(this.campaigns, adsTypes, this.defaultSegment);
      this.selectItems =
        this.segmentMenuService.prepareDataForSegmentMenu({
          segments: segments,
          groups: segmentGroups,
          rules: this.objectAccessManager.getAllowedSharedCostRules(sharedCostRules, segments)
        });
      this.adsCampaigns.length ? this.setState(WidgetState.READY) : this.setState(WidgetState.EMPTY);
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  get selectedCampaignLength(): number {
    return Object.keys(this.selectedCampaigns).length;
  }

  assignSegment(campaignId: number, selectedItem: HierarchySelectItem): void {
    const data = new Set([String(campaignId), ...Object.keys(this.selectedCampaigns)]);

    const segmentId = selectedItem.objectType === this.configuration.OBJECT_TYPES.segment ? selectedItem.objectId : null;
    const sharedCostRuleId = selectedItem.objectType === this.configuration.OBJECT_TYPES.sharedCostRule ? selectedItem.objectId : null;

    const requestChunks: Observable<any>[] = [];
    const body: Partial<CampaignDO> = {
      company_budget_segment1: segmentId,
      split_rule: sharedCostRuleId,
      process_nested: true
    };

    Array.from(data).forEach(campaign => {
      const request = this.campaignService.updateCampaign(Number(campaign), body);
      requestChunks.push(request);
    })

    forkJoin(requestChunks)
      .pipe(
        takeUntil(this.destroy$)
      )
      .subscribe((assignedCampaigns: Array<CampaignDO>) => {
        const updatedCampaigns = this.adsCampaigns.filter(campaign => !assignedCampaigns.some(c => campaign.id === c.id));
        this.adsCampaigns = [...updatedCampaigns];
        this.cdRef.detectChanges();
        if (!this.adsCampaigns.length) {
          this.setState(WidgetState.EMPTY);
        }
      });
  }

  selectCampaigns(event: MatCheckboxChange): void {
    if (event.checked) {
      this.adsCampaigns.forEach(item => this.selectedCampaigns[item.id] = event.checked);
      return;
    }
    this.selectedCampaigns = {};
  }

  selectCampaign(event: MatCheckboxChange, campaignID: number): void {
    event.checked ? this.selectedCampaigns[campaignID] = event.checked : delete this.selectedCampaigns[campaignID];
  }

  openCampaignDetails(campaignID: number): void {
    this.appRoutingService.openCampaignDetails(campaignID);
  }

  private setState(state): void {
    this.state = state;
    this.widgetStateManager.setState(this.state, this.config);
    this.cdRef.detectChanges();
  }

  private filterAdsCampaigns(campaignList: LightCampaign[], campaignTypes: number[], defaultSegment: BudgetSegmentAccess): LightCampaign[] {
    return campaignList.filter(campaign => {
      return campaignTypes.includes(campaign.campaignTypeId) && campaign.budgetSegmentId === defaultSegment.id;
    });
  }
}
