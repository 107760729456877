import { Component, inject, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {
  BudgetTimeframeBrief,
  ManageCegTableCellAllocations,
  ManageCegTableRow,
  ManageCegTableRowAllocations,
  ManageTableBudgetColumn,
  ManageTableBudgetColumnName,
  ManageTableBudgetColumnsVisibility,
  SegmentBreakdownConfig,
  TotalsSidebarState
} from '@manage-ceg/types/manage-ceg-page.types';
import { ManageCegPageService } from '@manage-ceg/services/manage-ceg-page.service';
import { getAmountsForTimeframes } from '@manage-ceg/services/manage-ceg-table-row-data/amounts-loader.helpers';
import { ManageCegTableDataService } from '@manage-ceg/services/manage-ceg-table-data.service';
import { ManageTableRowType } from '@shared/enums/manage-table-row-type.enum';

@Component({
  selector: 'row-totals',
  templateUrl: './row-totals.component.html',
  styleUrls: ['./row-totals.component.scss']
})
export class RowTotalsComponent implements OnInit, OnChanges, OnDestroy {
  private readonly tableDataService = inject(ManageCegTableDataService);

  @Input() budgetColumns: ManageTableBudgetColumn[];
  @Input() budgetColumnsVisibility: ManageTableBudgetColumnsVisibility;
  @Input() segmentBreakdownConfig: SegmentBreakdownConfig;
  @Input() totalsSidebarState: TotalsSidebarState;
  @Input() record: ManageCegTableRow;

  protected readonly budgetColumnName = ManageTableBudgetColumnName;
  protected readonly sidebarState = TotalsSidebarState;
  protected isClosed: boolean;
  protected segmentRelated: boolean;
  protected recordTotals: ManageCegTableCellAllocations = null;
  private visibleTimeframeIds: (string | number)[];
  private allocationsAll: ManageCegTableRowAllocations;
  private readonly destroy$ = new Subject<void>();
  public ManageTableForecastRowTypes = [ManageTableRowType.Campaign, ManageTableRowType.Segment, ManageTableRowType.ExpenseGroup];

  @Input() set timeframes(timeframes: BudgetTimeframeBrief[]) {
    this.visibleTimeframeIds = ManageCegPageService.mapTimeframesToIds(timeframes);
    this.calculateTotals();
  }

  ngOnInit(): void {
    this.tableDataService.refreshTable$.pipe(
      takeUntil(this.destroy$)
    ).subscribe(() => this.updatedRecordRelatedData());
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.record) {
      this.updatedRecordRelatedData();
    }
  }

  calculateTotals(): void {
    if (!this.visibleTimeframeIds || !this.allocationsAll) {
      return;
    }
    this.recordTotals = getAmountsForTimeframes(this.visibleTimeframeIds, this.allocationsAll);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private updatedRecordRelatedData(): void {
    this.allocationsAll = { ...this.record.allocations, ...this.record.presentationAllocations };
    this.isClosed = this.record.isClosed;
    this.segmentRelated = this.record.segmentRelated;
    this.calculateTotals();
  }
}
