<div
  class="budget-allocation-cell"
  [ngClass]="{
    'gestures-allowed': editable && allowGestures,
    'dnd-allowed': editable && allowDnd,
    'is-focused': isFocused,
    'is-editable': editable && allocatedValue >= 0,
    'is-disabled': disabled,
    'billion-cap-mode': allocatedValue >= billionCapLimit,
    'is-dragged': isDragged,
    'is-drag-over': isDragOver,
    'with-difference': showDifference,
    'global-drag-started': globalDragStarted
  }"
  [plDroppable]="isDropAllowed"
  (dropOn)="handleDropOn()"
  (dragEnter)="handleDragEnter()"
  (dragLeave)="handleDragLeave()"
  [id]="id ? 'allocation-' + id : null"
>
  <div
    *ngIf="showDifference && differenceValue !== 0"
    class="difference"
    budgetAllocationActionTooltip
    [actionTooltipContext]="actionTooltipContext"
    [matTooltip]="disabled ? disabledDiffTooltip : null"
    matTooltipPosition="above"
    matTooltipClass="dark-theme-tooltip simple multi-line"
    [withDnd]="isDragAllowed"
    [showTooltip]="allowGestures && editable && !disabled && !isDragged && (isDragAllowed || hasRemainingBudget)"
    [class.overspend]="differenceValue < 0"
    [plDraggable]="isDragAllowed"
    (dragStart)="handleDragStart()"
    (dragEnd)="handleDragEnd()"
    (dblclick)="handleDoubleClick()"
    id="differenceValue"
  >
    {{ (differenceValue < 0 ? -differenceValue : differenceValue) | number: decimalPipeFormat }}
  </div>

  <div
    id="negativeAllocatedValue"
    [maxLength]="maxLength"
    class="value-input"
    [attr.title]="allocatedValue | number: decimalPipeFormat"
    *ngIf="allocatedValue < 0">
    [{{-allocatedValue | number: decimalPipeFormat}}]
  </div>
  <input
    *ngIf="allocatedValue >= 0"
    [ngClass]="{ 'input-animation-disabled': inputAnimationDisabled }"
    type="text"
    class="value-input"
    currencyMask
    [selectTextOnFocus]="true"
    [maxLength]="maxLength"
    [disabled]="!editable || disabled"
    [attr.title]="allocatedValue | number: decimalPipeFormat"
    [(ngModel)]="allocatedValue"
    [options]="currencyMaskOptions"
    (focus)="setFocus(true)"
    (blur)="handleBlur()"
    [draggable]="draggable" 
    (dragstart)="preventDragAction($event)" 
    (drop)="preventDropAction($event)"
    blurOnEnterKeydown
    id="allocatedValue"
  >
</div>
