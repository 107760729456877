import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, Input, NgZone, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { filter, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { WidgetConfig, WidgetState } from '../../types/widget.interface';
import { WidgetStateService } from '../../services/widget-state.service';
import { HomePageService } from '../../services/home-page.service';
import { Budget } from 'app/shared/types/budget.interface';
import { Company } from 'app/shared/types/company.interface';
import { UtilityService } from 'app/shared/services/utility.service';
import { MetricsProgressChartField, MetricsProgressDataItem } from '../../types/metrics-progress-chart.type';
import { MetricsProgressDO } from 'app/shared/types/metrics-progress.interface';
import { Configuration } from 'app/app.constants';
import { FilterName } from 'app/header-navigation/components/filters/filters.interface';
import { FilterManagementService } from 'app/header-navigation/components/filters/filter-services/filter-management.service';
import { ViewSectionName } from 'app/dashboard/dashboard.types';
import { BudgetDataService } from 'app/dashboard/budget-data/budget-data.service';
import { Campaign } from 'app/shared/types/campaign.interface';
import { CompanyDataService } from 'app/shared/services/company-data.service';
import { parseCompositeObjectKey } from 'app/shared/utils/common.utils';
import { MetricsUtilsService } from 'app/budget-object-details/services/metrics-utils.service';
import { BudgetObjectDetailsManager } from 'app/budget-object-details/services/budget-object-details-manager.service';
import { COLORS } from 'app/shared/constants/colors.constants';
import WidgetLegendItem from '../../types/widget-legend-item.interface';
import WidgetLegendIcon from '../../types/widget-legend-icon.type';
import { MetricType } from 'app/shared/types/budget-object-metric.interface';
import { ProductDO } from 'app/shared/services/backend/product.service';


@Component({
  selector: 'metrics-progress-widget',
  styleUrls: ['./metrics-progress-widget.component.scss'],
  templateUrl: './metrics-progress-widget.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MetricsProgressWidgetComponent implements OnInit, OnDestroy {
  private readonly widgetStateManager = inject(WidgetStateService);
  private readonly utilityService = inject(UtilityService);
  private readonly homePageService = inject(HomePageService);
  private readonly budgetDataService = inject(BudgetDataService);
  private readonly companyDataService = inject(CompanyDataService);
  private readonly budgetObjectDetailsManager = inject(BudgetObjectDetailsManager);
  private readonly metricsUtilsService = inject(MetricsUtilsService);
  private readonly router = inject(Router);
  private readonly configuration = inject(Configuration);
  private readonly filterManagementService = inject(FilterManagementService);
  private readonly zone = inject(NgZone);
  private readonly cdRef = inject(ChangeDetectorRef);

  @Input() config: WidgetConfig;

  private readonly destroy$ = new Subject<void>();
  private currentBudget: Budget = null;
  private company: Company = null;
  private campaigns: Campaign[] = [];
  private progressData: MetricsProgressDO;
  private products: ProductDO[] = [];
  private contextChanged = false;
  public metrics: MetricType[] = [];
  public state = WidgetState.INITIAL;
  public widgetState = WidgetState;
  public chartData: MetricsProgressDataItem[] = [];

  public legendItems: WidgetLegendItem[] = [
    {
      icon: WidgetLegendIcon.Square,
      color: COLORS.CORPORATE_MAGENTA,
      label: 'My Current YTD'
    },
    {
      icon: WidgetLegendIcon.Square,
      color: COLORS.VIOLET,
      label: 'Total Current YTD'
    },
    {
      icon: WidgetLegendIcon.Square,
      color: COLORS.NAVY_VIOLET,
      opacity: .2,
      label: 'FY Target'
    },
    {
      icon: WidgetLegendIcon.TriangleRight,
      color: COLORS.CORPORATE_PURPLE,
      label: 'Current Target'
    }
  ];

  ngOnInit(): void {
    this.setState(WidgetState.LOADING);
    this.loadContextData();
    this.homePageService.noBudgets$
      .pipe(
        takeUntil(this.destroy$),
        take(1)
      )
      .subscribe(
        () => {
          this.setState(WidgetState.HIDDEN);
          this.destroy$.next();
        }
      );
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private setState(state) {
    this.state = state;
    this.widgetStateManager.setState(this.state, this.config);
    this.cdRef.detectChanges();
  }

  private handleError(err) {
    this.utilityService.handleError(err);
  }

  private loadContextData() {
    const campaignsList$ = this.budgetDataService.lightCampaignList$
      .pipe(
        filter(data => data != null),
        tap(data => {
          this.campaigns = this.homePageService.filterObjectsBySegment(
            this.budgetDataService.segmentsSnapshot,
            this.budgetDataService.sharedCostRulesSnapshot,
            data
          ) as Campaign[];
        }),
        take(1)
      );

    const progressData$ = this.homePageService.metricsProgress$
      .pipe(
        filter(event => event && event.budgetId === this.currentBudget?.id),
        filter(event => event.forceReload || this.contextChanged),
        tap(event => {
          this.progressData = event.data;
          this.contextChanged = false;
        })
      );

    const metricsList$ = this.budgetObjectDetailsManager.getMetricTypes()
      .pipe(
        filter(data => data != null),
        tap(metrics => this.metrics = metrics),
        take(1)
      );

    const productsList$ = this.companyDataService.products$
      .pipe(
        filter(data => data != null),
        tap(data => this.products = data),
        take(1)
      );

    this.homePageService.contextData$
      .pipe(
        filter(data => data != null && !this.homePageService.loadingHomePage),
        tap((data) => {
          this.contextChanged = true;
          this.currentBudget = data.budget;
          this.company = data.company;
        }),
        switchMap(() => campaignsList$),
        switchMap(() => metricsList$),
        switchMap(() => productsList$),
        switchMap(() => progressData$),
        takeUntil(this.destroy$)
      )
      .subscribe(
        () => { this.prepareData(); },
        (err) => this.handleError(err)
      );
  }

  public prepareData() {
    const progressKeys = Object.keys(this.progressData || {});
    if (!this.campaigns.length || !progressKeys.length) {
      this.setState(WidgetState.EMPTY);
      return;
    }

    const productsMap: Record<number, ProductDO> = this.products.reduce((result, product) => {
      result[product.id] = {...product};
      return result;
    }, {});

    this.chartData = progressKeys
      .map(key => {
        const { objectID: metricId, objectName: metricName } = parseCompositeObjectKey(key);
        const { full_total_target, full_total_amount, users_total_amount, current_total_target } = this.progressData[key];
        const metric = this.metrics.find(mt => mt.id === Number(metricId));
        const product = productsMap[metric?.productId];
        const productName = product?.name;
        return {
          metricId,
          metricName,
          productName,
          metaInfo: {
            isCustom: metric?.isCustom,
            withCurrency: metric?.withCurrency,
            isDecimal: this.metricsUtilsService.isMetricTypeDecimal(metric),
          },
          values: {
            [MetricsProgressChartField.TotalTarget]: full_total_target,
            [MetricsProgressChartField.UserContributionYTD]: users_total_amount,
            [MetricsProgressChartField.TeamContributionYTD]: full_total_amount,
            [MetricsProgressChartField.CurrentTarget]: current_total_target,
          }
        }
      });

    this.setState(WidgetState.READY);
  }

  public handleChartCategoryClicked(metricId: string) {
    this.zone.run(() => {
      this.filterManagementService.updateCurrentFilterSet({
        [FilterName.Metrics]: [+metricId]
      });
      this.router.navigate([ this.configuration.ROUTING_CONSTANTS.MANAGE_PAGE, ViewSectionName.campaigns ]);
    });
  }
}
