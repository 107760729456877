import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, inject, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { WidgetConfig, WidgetState } from '../../types/widget.interface';
import { WidgetStateService } from '../../services/widget-state.service';
import { Subject } from 'rxjs';
import { filter, take, takeUntil, tap } from 'rxjs/operators';
import { CompanyDO } from 'app/shared/types/company.interface';
import { Budget, BudgetTimeframesType } from 'app/shared/types/budget.interface';
import { UtilityService } from 'app/shared/services/utility.service';
import { BudgetTimeframe } from 'app/shared/types/timeframe.interface';
import {
  BudgetMinusExpenseRow,
  BudgetMinusExpenseRowType
} from '../../types/budget-minus-expenses-widget.types';
import { Configuration } from 'app/app.constants';
import { FilterName, FilterSet } from 'app/header-navigation/components/filters/filters.interface';
import { FilterManagementService } from 'app/header-navigation/components/filters/filter-services/filter-management.service';
import { BudgetSegmentAccess } from 'app/shared/types/segment.interface';
import { HomePageService } from '../../services/home-page.service';
import { BudgetMinusExpenseWidgetService } from './budget-minus-expenses-widget.service';

@Component({
  selector: 'budget-minus-expenses-widget',
  templateUrl: './budget-minus-expenses-widget.component.html',
  styleUrls: ['./budget-minus-expenses-widget.component.scss'],
  providers: [BudgetMinusExpenseWidgetService]
})
export class BudgetMinusExpensesWidgetNewWorldComponent implements OnInit, OnDestroy {
  @Input() config: WidgetConfig;
  @Output() onLoaded = new EventEmitter();

  private readonly cdRef = inject(ChangeDetectorRef);

  private readonly destroy$ = new Subject<void>();
  public state = WidgetState.INITIAL;
  public company: CompanyDO;
  public currentBudget: Budget;
  public widgetState = WidgetState;
  public budgetTimeframes: BudgetTimeframe[];
  public budgetSegments: BudgetSegmentAccess[];

  public budgetType: BudgetTimeframesType;
  public currencySymbol: string;
  public tableHeaderCells: string[] = [];
  public tableRows: BudgetMinusExpenseRow[] = [];

  private currentBudgetId;

  constructor(
    private readonly widgetStateManager: WidgetStateService,
    private readonly utilityService: UtilityService,
    private readonly router: Router,
    private readonly configuration: Configuration,
    private readonly filterManager: FilterManagementService,
    private readonly homePageService: HomePageService,
    private readonly budgetMinusExpenseWidgetService: BudgetMinusExpenseWidgetService
  ) { }

  ngOnInit(): void {
    this.homePageService.noBudgets$
      .pipe(
        takeUntil(this.destroy$),
        take(1)
      )
      .subscribe(
        () => {
          this.setState(WidgetState.HIDDEN);
          this.destroy$.next();
        }
      );

    this.loadHomeData();

    this.budgetMinusExpenseWidgetService.budgetMinusExpenseData$.pipe(
      filter(data => data !== null),
      takeUntil(this.destroy$),
    ).subscribe((data) => {
      this.prepareTableData(data);
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private loadHomeData() {
    this.homePageService.contextData$.pipe(
      filter(contextData => {
        let loadHomeData = false;
        if(contextData?.budget){
          if(this.currentBudgetId === undefined && contextData.budget){
            // for first load
            loadHomeData = true;
          } else {
            // for alternative loads, on budget and company switchs
            loadHomeData = this.currentBudgetId !== contextData.budget.id;
          }
          this.currentBudgetId = contextData.budget.id;
        }
        return contextData !== null
          && this.currentBudgetId !== undefined
          && loadHomeData;
      }),
      tap((contextData: any) => {
        this.currentBudget = contextData.budget;
        this.company = contextData.company;
        this.budgetTimeframes = contextData.timeframes;
        this.budgetSegments = contextData.segments;
        this.onBudgetSelected(contextData.budget);
        this.setState(WidgetState.LOADING);
      }),
      takeUntil(this.destroy$)
    ).subscribe(() => {
      this.budgetMinusExpenseWidgetService.getBudgetMinusExpenses(
        this.currentBudget.id,
        this.company.id
      );
    });
  }

  private setState(state: WidgetState) {
    this.state = state;
    this.widgetStateManager.setState(this.state, this.config);
    this.cdRef.detectChanges();
  }

  private onBudgetSelected(budget: Budget) {
    this.budgetType = budget && (<any> BudgetTimeframesType)[budget.type];
    this.currencySymbol = this.company && this.company.currency_symbol;
  }

  private goToExpensesPage(filterSet?: FilterSet) {
    this.navigateTo([this.configuration.ROUTING_CONSTANTS.SPENDING_MANAGEMENT], filterSet)
  }

  private goToDashboardPage(filterSet?: FilterSet) {
    this.navigateTo([this.configuration.ROUTING_CONSTANTS.DASHBOARD], filterSet);
  }

  private navigateTo(commands: any[], filterSet?: FilterSet) {
    if (filterSet) {
      this.filterManager.updateCurrentFilterSet(filterSet);
    }
    this.router.navigate(commands);
  }


  private prepareTableData(data) {
    this.tableHeaderCells = ['', 'This Year', ''];
    const budgetDataRow: BudgetMinusExpenseRow = {
      rowType: BudgetMinusExpenseRowType.data,
      title: 'Budget',
      FYAmountCell: {
        value: data.total_budget,
        onClick: () => this.goToDashboardPage({})
      }
    };

    const expenseDataRow: BudgetMinusExpenseRow = {
      title: 'Expenses',
      rowType: BudgetMinusExpenseRowType.data,
      FYAmountCell: {
        value: data.total_spent,
        onClick: () => this.goToExpensesPage({})
      }
    };

    const totalDataRow: BudgetMinusExpenseRow = {
      title: '',
      rowType: BudgetMinusExpenseRowType.total,
      FYAmountCell: {
        value: data.total_budget -  data.total_spent,
        hasProblem: data.total_budget < data.total_spent,
        highlighted: true
      }
    };

    if (this.budgetType !== BudgetTimeframesType.Year && data.current_alloc_id) {
      const filtersByCurrentTf = {[FilterName.Timeframes]: [data.current_alloc_id]};

      budgetDataRow.currentTFAmountCell = {
        value: data.current_alloc_budget,
        onClick: () => this.goToDashboardPage(filtersByCurrentTf)
      };

      expenseDataRow.currentTFAmountCell = {
        value: data.current_alloc_spent,
        onClick: () => this.goToExpensesPage(filtersByCurrentTf)
      };

      totalDataRow.currentTFAmountCell = {
        value: data.current_alloc_budget -  data.current_alloc_spent,
        hasProblem: data.current_alloc_budget < data.current_alloc_spent,
        highlighted: true
      };

      this.tableHeaderCells.splice(1, 0, `This ${this.budgetType}`);
    }

    this.tableRows = [budgetDataRow, expenseDataRow, totalDataRow];
    this.setState(WidgetState.READY);
  }
}
