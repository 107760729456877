<div class="confirmation-dialog">
  <header *ngIf="!context?.noTitle" mat-dialog-title [class.centered]="context?.headerIcon">
    <p class="header-icon" *ngIf="context?.headerIcon"><fa-duotone-icon [icon]="context.headerIcon"></fa-duotone-icon></p>
    <p>{{ context?.title }}</p>
  </header>
  <div [class.no-title-content]="context?.noTitle" mat-dialog-content *ngIf="context?.content" [innerHTML]="context?.content"></div>
  <div mat-dialog-content *ngIf="!context?.content">
    <ng-content></ng-content>
  </div>
  <div *ngIf="context?.parentChangeInheritance">
    <mat-checkbox color="primary" [(ngModel)]="applyToAllChildren">Apply to all children</mat-checkbox>
  </div>
  <div mat-dialog-actions class="dialog-actions">
    <ng-container *ngIf="!context?.actions?.length">
      <button
        (click)="callHandler(context?.cancelAction?.handler)"
        mat-flat-button
        class="cancel-button"
        [disabled]="context?.cancelAction?.disabled"
        *ngIf="context?.cancelAction"
      >
        {{ context?.cancelAction?.label }}
      </button>
      <button
        (click)="callHandler(context?.submitAction?.handler)"
        mat-flat-button
        class="submit-button"
        color="primary"
        cdkFocusInitial
        [disabled]="context?.submitAction?.disabled"
        *ngIf="context?.submitAction"
      >
        {{ context?.submitAction?.label }}
      </button>
    </ng-container>
    <ng-container *ngFor="let action of context?.actions">
      <button
        (click)="callHandler(action.handler)"
        mat-button
        color="primary"
        [disabled]="action.disabled"
        *ngIf="!action.type || action.type === ACTION_TYPE.DEFAULT"
        #button
      >
        {{ action.label }}
      </button>
      <button
        (click)="callHandler(action.handler)"
        mat-stroked-button
        color="primary"
        [disabled]="action.disabled"
        *ngIf="action.type === ACTION_TYPE.STROKED"
        #button
      >
        {{ action.label }}
      </button>
      <button
        (click)="callHandler(action.handler)"
        mat-flat-button
        color="primary"
        [disabled]="action.disabled"
        *ngIf="action.type === ACTION_TYPE.FLAT && !context?.parentChangeInheritance"
        #button
      >
        {{ action.label }}
      </button>
      <button
        (click)="inheritancePropagationCallHandler(action.label, action.handler, ACTION_TYPE.FLAT)"
        mat-flat-button
        color="primary"
        [disabled]="action.disabled"
        *ngIf="context?.parentChangeInheritance && action.type === ACTION_TYPE.FLAT"
        #button
      >
        {{ action.label }}
      </button>
      <button
        (click)="callHandler(action.handler)"
        mat-flat-button
        color="warn"
        [disabled]="action.disabled"
        *ngIf="action.type === ACTION_TYPE.WARNING"
        #button
      >
        {{ action.label }}
      </button>
    </ng-container>
  </div>
</div>
