import { Injectable, OnDestroy, TemplateRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ModalWithIconHeaderComponent, ModalContext } from '../components/modal-with-icon-header/modal-with-icon-header.component';
import { UtilityService } from './utility.service';

@Injectable({
  providedIn: 'root',
})
export class IconHeaderModalService implements OnDestroy {
  private destroy$ = new Subject<void>();

  constructor(public dialog: MatDialog, private utilityService: UtilityService) {}

  openInitialUpdatePopup(
    data: ModalContext,
    contentTemplate?: TemplateRef<any>,
    redHeader: boolean = false,
    panelClass?: string,
    enableSecondaryIcon: boolean = false,
    showDontShowAgainCheckbox: boolean = false,
    checkboxChangeCallback?: (checked: boolean) => void
  ): Observable<any> {
    const dialogRef = this.dialog.open(ModalWithIconHeaderComponent, {
      width: '570px',
      data: data,
      autoFocus: false,
    });

    if (contentTemplate) {
      dialogRef.componentInstance.customContent = true;
      dialogRef.componentInstance.contentTemplate = contentTemplate;
    }

    dialogRef.componentInstance.redHeader = redHeader;
    dialogRef.componentInstance.panelClass = panelClass;

    if (enableSecondaryIcon) {
      dialogRef.componentInstance.secondaryIcon = true;
    }

    dialogRef.componentInstance.showDontShowAgainCheckbox = showDontShowAgainCheckbox;
    if (checkboxChangeCallback) {
      dialogRef.componentInstance.dontShowAgainChanged
        .pipe(takeUntil(this.destroy$))
        .subscribe((checked: boolean) => {
          checkboxChangeCallback(checked);
        });
    }

    this.utilityService.showLoading(false);
    return dialogRef.afterClosed();
  }

  closePopup() {
    this.dialog.closeAll();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}