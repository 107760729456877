import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { Budget } from 'app/shared/types/budget.interface';
import { BudgetDataService } from 'app/dashboard/budget-data/budget-data.service';
import { BudgetStatus } from 'app/shared/types/budget-status.type';
import { BudgetStatusLabel } from 'app/shared/constants/budget-status.constants';

@Component({
  selector: 'budget-select',
  styleUrls: ['./budget-select.component.scss'],
  templateUrl: './budget-select.component.html'
})
export class BudgetSelectComponent implements OnDestroy, OnInit {
  private readonly destroy$ = new Subject<void>();
  BudgetStatusLabel = BudgetStatusLabel;
  companyBudgets: Budget[] = [];
  selectedBudget: Budget = null;
  selectedBudgetTitle = '';
  budgetsOrdering = {
    [BudgetStatus.Live]: 1,
    [BudgetStatus.Draft]: 2,
    [BudgetStatus.Hidden]: 3,
    [BudgetStatus.Reference]: 4,
    [BudgetStatus.Test]: 5
  };
  lastLiveIndex = null;

  constructor(
    private readonly budgetDataService: BudgetDataService
  ) {}

  ngOnInit(): void {
    this.setBudgetsList(this.budgetDataService.budgetListSnapshot);
    this.applyExternalSelectedBudget(this.budgetDataService.selectedBudgetSnapshot);

    this.budgetDataService.companyBudgetList$
      .pipe(
        filter(budgets => !!budgets),
        takeUntil(this.destroy$)
      )
      .subscribe(budgetList => this.setBudgetsList(budgetList));

    this.budgetDataService.selectedBudget$
      .pipe(takeUntil(this.destroy$))
      .subscribe(budget => this.applyExternalSelectedBudget(budget))
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onSelectBudget(budgetId) {
    this.budgetDataService.setLightBehaviorCampaigns(null);
    sessionStorage.setItem(`integration-authPopup`, 'false');
    this.budgetDataService.selectBudget(
      budgetId,
      () => this.selectedBudget = this.companyBudgets.find(b => b.id === budgetId)
    );
  }

  private applyExternalSelectedBudget(budget: Budget) {
    if (!budget) {
      this.selectedBudget = null;
    } else {
      this.selectedBudget = budget;
    }
  }

  private setBudgetsList(budgets: Budget[]) {
    const sortByName = (prev, next) => {
      const prevName = prev.name.toLowerCase();
      const nextName = next.name.toLowerCase();
      return prevName.localeCompare(nextName);
    };

    this.lastLiveIndex = null;
    this.companyBudgets = budgets
      .sort((prev, next) => prev.status !== next.status
        ? this.budgetsOrdering[prev.status] - this.budgetsOrdering[next.status]
        : sortByName(prev, next)
      );
    this.companyBudgets.forEach((budget, idx) => {
      if (budget.status === BudgetStatus.Live) {
        this.lastLiveIndex = idx;
      }
    });
  }
}
