import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs'
import { Validations } from 'app/app.validations';
import { ActiveToast, ToastrService } from 'ngx-toastr';
import { CustomToastrComponent } from '@common-lib/lib/corporate-page/components/custom-toastr/custom-toastr.component';
import { IndividualConfig } from 'ngx-toastr/toastr/toastr-config';
import { NavigationToastComponent } from '../components/navigation-toast/navigation-toast.component';
import { LocalStorageService } from '@common-lib/services/local-storage.service';
import { NavigationToastrData } from '@shared/types/navigation-toastr.type';

export const TOASTR_TIMEOUT = 8000;
export const DEFAULT_SNACKBAR_DURATION = 3000;

@Injectable({
  providedIn: 'root'
})
export class UtilityService {
  private loadingState: boolean;
  private loadingPropertyChanged = new Subject<boolean>();
  private popupLoadingPropertyChanged = new Subject<boolean>();
  private graphLoadingPropertyChanged = new Subject<boolean>();
  private onDragNdropItems = new Subject<boolean>();
  private budgetDataPropertyChanged = new Subject<any>();
  private expenseTypeListPropertyChanged = new Subject<any>();
  private proPlanAlertPropertyChanged = new Subject<any>();
  private tabPropertyChanged = new Subject<any>();
  private loadGlCodePropertyChanged = new Subject<any>();
  private resetAccountDetails = new Subject<any>();
  private optionPropertyChanged = new Subject<any>();
  private budgetIdPropertyChanged = new Subject<any>();
  private superadminPropertyChanged = new Subject<any>();
  private cleanUserDataProperty = new Subject<number>();
  private individualConfig: Partial<IndividualConfig> = {
    timeOut: TOASTR_TIMEOUT,
    tapToDismiss: true,
    positionClass: 'toast-bottom-center',
    closeButton: true,
    toastComponent: CustomToastrComponent
  }
  loadingPropertyChanged$: Observable<any> = this.loadingPropertyChanged.asObservable();
  onDragNdropItems$: Observable<any> = this.onDragNdropItems.asObservable();
  popupLoadingPropertyChanged$: Observable<any> = this.popupLoadingPropertyChanged.asObservable();
  graphLoadingPropertyChanged$: Observable<any> = this.graphLoadingPropertyChanged.asObservable();
  expenseTypeListPropertyChanged$: Observable<any> = this.expenseTypeListPropertyChanged.asObservable();
  proPlanAlertPropertyChanged$: Observable<any> = this.proPlanAlertPropertyChanged.asObservable();
  tabPropertyChanged$: Observable<any> = this.tabPropertyChanged.asObservable();
  loadGlCodePropertyChanged$: Observable<any> = this.loadGlCodePropertyChanged.asObservable();
  resetAccountDetails$: Observable<any> = this.resetAccountDetails.asObservable();
  superadminPropertyChanged$: Observable<any> = this.superadminPropertyChanged.asObservable();
  cleanUserDataProperty$: Observable<number> = this.cleanUserDataProperty.asObservable();

  lastChangeLoading = {
    showTs: 0
  };

  constructor(
    public validations: Validations,
    private toastr: ToastrService
  ) {}

  checkLoading() {
    if(this.loadingState) {
      if (this.lastChangeLoading.showTs + 5000 < Date.now()) { // just normal hide.
        this.loadingPropertyChanged.next(false);
        this.loadingState = false;
        return;
      }
      setTimeout(() => this.checkLoading(), 5000);
    }
  }

  showLoading(value) {
    if (value) {
      this.lastChangeLoading.showTs = Date.now();
      // this is to handle any consumers who forget to set loading to false
      this.checkLoading();
    }
    this.loadingState = value;
    this.loadingPropertyChanged.next(value);
  }

  forceHideLoading() {
    this.loadingPropertyChanged.next(false);
  }

  showPopupLoading(value) {
    this.popupLoadingPropertyChanged.next(value)
  }

  showGraphLoading(value) {
    this.graphLoadingPropertyChanged.next(value)
  }

  dragNdrop(value) {
    this.onDragNdropItems.next(value)
  }

  showProPlanAlert(value) {
    this.proPlanAlertPropertyChanged.next(value);
  }

  setTeamTabActive(value) {
    this.tabPropertyChanged.next(value);
  }

  budgetDataUpdated(value) {
    this.budgetDataPropertyChanged.next(value);
  }

  showExpenseTypeList(value) {
    this.expenseTypeListPropertyChanged.next(value);
  }

  loadGlCode(value) {
    this.loadGlCodePropertyChanged.next(value);
  }

  resetAccount(value) {
    this.resetAccountDetails.next(value);
  }

  hideBudgetOptions(value) {
    this.optionPropertyChanged.next(value);
  }

  setBudgetId(value: any) {
    this.budgetIdPropertyChanged.next(value);
  }

  refreshSuperadminList(value: any) {
    this.superadminPropertyChanged.next(value);
  }

  cleanUserData(userId: number) {
    this.cleanUserDataProperty.next(userId);
  }

  isNumber(value) {
    return typeof value === 'number';
  }

  setNoRegistrationFlag(value = true): void {
    LocalStorageService.addToStorage('no_reg_sub', value);
  }

  getNoRegistrationFlag(): boolean {
    return LocalStorageService.getFromStorage('no_reg_sub');
  }

  /**
 * Utility function to escape special HTML characters.
 */
  public escapeHtml(input: string): string {
    return input
      .replace(/&/g, '&amp;')   // Escape &
      .replace(/</g, '&lt;')    // Escape <
      .replace(/>/g, '&gt;')    // Escape >
  }

  private hasHtmlCharacters(input: string): boolean {
    const htmlRegex = /[<>&]/;
    return htmlRegex.test(input);
  }


  handleError(error) {
    let msg = '';
    if (error && error.target && error.target.status == 0) {
      msg = this.validations.ValiditionMessages.SERVER_CONNECTION_LOST;
    } else {
      msg = error.detail || error.message || 'Operation failed';
    }
    this.showToast({ Title: '', Message: msg, Type: 'error' });
    this.showLoading(false);
  }

  showToast(toast: { Title?: string; Message: string; action?: string; Type?: string; }, enableHTML = false) {
    // "Title" and "Type" are artefacts and currently not used
    // 'Close' used as default in case action not provided. If 'null' provided - we should not show Button at all
    const buttonText = toast.action === null ? null : (toast.action || 'Close');
    const toastClass = `ngx-toastr ${toast.Type}`
    this.showCustomToastr(toast.Message, buttonText, { timeOut: DEFAULT_SNACKBAR_DURATION, toastClass: toastClass}, enableHTML) ;
  }

  public showCustomToastr(text: string, buttonText?: string, toastrConfig?: Partial<IndividualConfig>, enableHTML: boolean = false) {
    const escapedText = this.escapeHtml(text);
    const containsHtml = this.hasHtmlCharacters(text) && enableHTML;
    const displayText =  containsHtml ? escapedText : text;

    let config = { ...this.individualConfig, ...toastrConfig, enableHtml: containsHtml };

    const toast = this.toastr.show(displayText, '', config);
    toast.toastRef.componentInstance.toastID = toast.toastId;
    toast.toastRef.componentInstance.buttonText = buttonText;
    toast.toastRef.componentInstance.hideButton = buttonText === null;
    return toast;
  }

  hideToastr(id: number) {
    this.toastr.clear(id);
  }

  public showToastrNav(customComponentData: NavigationToastrData): ActiveToast<any> {
    const config: Partial<IndividualConfig> = {
      ...this.individualConfig,
      disableTimeOut: true,
      positionClass: 'go-back-toast',
    };
    const toast = this.toastr.show('', '', config);
    toast.toastRef.componentInstance.inverted = true;
    toast.toastRef.componentInstance.customComponent = NavigationToastComponent;
    toast.toastRef.componentInstance.customComponentData = customComponentData;
    toast.toastRef.componentInstance.toastID = toast.toastId;
    return toast;
  }
}
