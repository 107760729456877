import { Injectable } from '@angular/core';
import { LocalStorageService } from '@common-lib/services/local-storage.service';
import { defaultViewConfig } from '@manage-ceg/services/manage-ceg-table-configuration.service';
import { ManageTableViewConfig } from '@manage-ceg/types/manage-ceg-page.types';
import { CEG_TABLE_CONFIG } from '@shared/constants/modes.constant';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ForecastBudgetService {
  private readonly forecastEnabledSubject = new BehaviorSubject<boolean>(false);

  // Observable to listen to forecastEnabled changes
  forecastEnabled$ = this.forecastEnabledSubject.asObservable();

  setForecastEnabled(value: boolean): void {
    localStorage.setItem('forecastEnabled', JSON.stringify(value));
    this.forecastEnabledSubject.next(value);
  }
  
  getForecastEnabled(): boolean {
    const storedValue = localStorage.getItem('forecastEnabled');
    return storedValue !== null ? JSON.parse(storedValue) : false;
  }

  setManagePageForecastConfig(value: boolean) {
    const storedConfig = LocalStorageService.getFromStorage(CEG_TABLE_CONFIG) as ManageTableViewConfig;
    const activeTableConfig = storedConfig || defaultViewConfig;
    activeTableConfig.columns.forecast = value;
    if(value) {
      activeTableConfig.columns['columnsFormula'] = false;
      activeTableConfig.columnsFormula = false;
    }
    LocalStorageService.addToStorage(CEG_TABLE_CONFIG, activeTableConfig);

  }
  
}
