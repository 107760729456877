import { inject, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { filter } from 'rxjs/operators';
import {
  ManageTableDataConfig,
  ManageTableViewConfig,
  ManageTableViewConfigSections, TableConfigActionType,
  TableConfigKeys
} from '@manage-ceg/types/manage-ceg-page.types';
import { LocalStorageService } from '@common-lib/services/local-storage.service';
import { CEG_TABLE_CONFIG } from '@shared/constants/modes.constant';
import { tableConfiguration } from '@manage-ceg/constants/manage-ceg-page.constants';
import { createDeepCopy } from '@shared/utils/common.utils';
import { ForecastBudgetService } from 'app/budget-settings/services/forecast-budget.service';

export const defaultViewConfig: ManageTableViewConfig = {
  segmentBreakdownData: {
    campaignsAndPrograms: false,
    unallocated: false,
    formula: false,
  },
  columns: {
    budget: true,
    actual: true,
    planned: false,
    committed: false,
    committedAndPlanned: true,
    available: true,
  },
  columnsFormula: false,
}

@Injectable()
export class ManageCegTableConfigurationService {
  private _tableViewConfiguration$ = new BehaviorSubject<ManageTableViewConfig>(null);
  public tableViewConfiguration$ = this._tableViewConfiguration$.asObservable().pipe(filter(data => !!data));

  private activeTableConfig: ManageTableViewConfig;
  private forecastBudgetService = inject(ForecastBudgetService);

  constructor(
  ) {
    const storedConfig = LocalStorageService.getFromStorage(CEG_TABLE_CONFIG) as ManageTableViewConfig;
    this.activeTableConfig = storedConfig || defaultViewConfig;
    this.setTableViewConfiguration(this.activeTableConfig);
  }

  public setTableViewConfiguration(config: ManageTableViewConfig): void {
    this._tableViewConfiguration$.next(config);
  }

  public get manageTableViewConfig(): ManageTableViewConfig {
    return this._tableViewConfiguration$.value;
  }

  public getTableViewConfiguration(): ManageTableDataConfig {
    this.activeTableConfig = LocalStorageService.getFromStorage(CEG_TABLE_CONFIG) as ManageTableViewConfig || defaultViewConfig;
    const toggleParams = {
      [ManageTableViewConfigSections.segmentBreakdownData]: TableConfigKeys.formula,
      [ManageTableViewConfigSections.columns]: TableConfigKeys.columnsFormula
    }
    const manageTableViewConfig: ManageTableDataConfig = createDeepCopy(tableConfiguration);
   
    if(!this.forecastBudgetService.getForecastEnabled()) {
      const forecastColumnIndex = manageTableViewConfig[ManageTableViewConfigSections.columns].findIndex(item => item.key === TableConfigKeys.forecast);
      manageTableViewConfig[ManageTableViewConfigSections.columns][forecastColumnIndex].visible = false;
      this.activeTableConfig.columns.forecast = false;
    }else {
      const forecastColumnIndex = manageTableViewConfig[ManageTableViewConfigSections.columns].findIndex(item => item.key === TableConfigKeys.forecast)
      manageTableViewConfig[ManageTableViewConfigSections.columns][forecastColumnIndex].value = this.activeTableConfig.columns?.forecast || false;
      manageTableViewConfig[ManageTableViewConfigSections.columns][forecastColumnIndex].visible = true;
    }

    if (this.activeTableConfig) {
      Object.keys(this.activeTableConfig).forEach(rootKey => {
        const isSeparate = this.activeTableConfig[rootKey][TableConfigKeys.planned] && this.activeTableConfig[rootKey][TableConfigKeys.committed]
        if (Array.isArray(manageTableViewConfig[rootKey])) {
          manageTableViewConfig[rootKey].forEach(item => {
            const { key, value } = item;
            if (item.key === key) {
              item.value = this.activeTableConfig[rootKey][key];
            }
            if (item.key === TableConfigKeys.committedAndPlanned) {
              item.value = isSeparate || item.value;
            }
            if (item.key === TableConfigKeys.separate) {
              item.value = isSeparate;
            }
            if (rootKey === TableConfigKeys.columnsFormula) {
              manageTableViewConfig[rootKey] = value;
            }
          });
        }
        if (rootKey === ManageTableViewConfigSections.segmentBreakdownData) {
          this.checkRowsFormulaEnable(manageTableViewConfig, rootKey, toggleParams[rootKey]);
        }
        if (rootKey === ManageTableViewConfigSections.columns) {
          this.checkColumnToggleEnable(manageTableViewConfig, rootKey);
        }
      });
    }
    return manageTableViewConfig;
  }

  private checkRowsFormulaEnable(manageTableConfig: ManageTableDataConfig, key: string, toggleKey: string): void {
    const isUnchecked = manageTableConfig[key]
      .filter(item => item.type === TableConfigActionType.checkbox)
      .some(item => !item.value);
    const formula = manageTableConfig[key].find(item => item.key === toggleKey);
    formula['disabled'] = isUnchecked;
  }

  private checkColumnToggleEnable(manageTableConfig: ManageTableDataConfig, key: string): void {
    let formulaCondition = manageTableConfig[key]
      .filter(item => item.type === TableConfigActionType.checkbox && item.key !== TableConfigKeys.forecast)
      .some(item => !item.value);

    let showForecastCheckbox =  manageTableConfig[key].find(item => item.key === TableConfigKeys.forecast)?.value

    formulaCondition = formulaCondition || showForecastCheckbox;

    const committedAndPlanned = manageTableConfig[key]
      .find(item => item.key === TableConfigKeys.committedAndPlanned)
    const separateCondition = !committedAndPlanned.value;
    const toggles = {
      [TableConfigKeys.columnsFormula]: formulaCondition,
      [TableConfigKeys.separate]: separateCondition
    }

    manageTableConfig[key]
      .filter(item => item.type === TableConfigActionType.toggle)
      .forEach(item => item.disabled = toggles[item.key]);
  }
}
