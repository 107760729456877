import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { filter, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { WidgetConfig, WidgetState } from '../../types/widget.interface';
import { WidgetStateService } from '../../services/widget-state.service';
import { UtilityService } from 'app/shared/services/utility.service';
import { Configuration } from 'app/app.constants';
import { HomePageService } from '../../services/home-page.service';
import { UserBudgetSummaryDO } from 'app/shared/types/user-budget-summary.interface';
import { FilterManagementService } from 'app/header-navigation/components/filters/filter-services/filter-management.service';
import { FilterName } from 'app/header-navigation/components/filters/filters.interface';
import { Budget } from 'app/shared/types/budget.interface';

@Component({
  selector: 'campaigns-summary-widget',
  styleUrls: ['./campaigns-summary-widget.component.scss'],
  templateUrl: './campaigns-summary-widget.component.html'
})
export class CampaignsSummaryWidgetComponent implements OnInit, OnDestroy {
  @Input() config: WidgetConfig;
  @Output() onLoaded = new EventEmitter();

  private readonly destroy$ = new Subject<void>();
  private budgetSummary: UserBudgetSummaryDO = null;
  private currentBudget: Budget;
  private contextChanged = false;
  public widgetState = WidgetState;
  public state = WidgetState.INITIAL;
  public currentTimeframe: { id: number, name: string; };
  public launched: number[] = [];
  public underWay: number[] = [];
  public preparing: number[] = [];
  public concluded: number[] = [];

  constructor(
    private readonly widgetStateManager: WidgetStateService,
    private readonly utilityService: UtilityService,
    private readonly router: Router,
    private readonly configuration: Configuration,
    private readonly homePageService: HomePageService,
    private filterManagementService: FilterManagementService
  ) {}

  ngOnInit(): void {
    this.setState(WidgetState.LOADING);
    this.loadContextData();
    this.homePageService.noBudgets$
      .pipe(
        takeUntil(this.destroy$),
        take(1)
      )
      .subscribe(
        () => {
          this.setState(WidgetState.HIDDEN);
          this.destroy$.next();
        }
      )
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private setState(state: WidgetState) {
    this.state = state;
    this.widgetStateManager.setState(this.state, this.config);
  }

  private handleError(err) {
    this.utilityService.handleError(err);
  }

  private prepareData() {
    const {
      current_timeframe,
      under_way_campaigns,
      preparing_to_launch_in_current_timeframe_campaigns,
      concluded_campaigns,
      launched_ytd_campaigns
    } = this.budgetSummary;

    this.contextChanged = false;
    this.currentTimeframe = current_timeframe;
    this.launched = launched_ytd_campaigns;
    this.underWay = under_way_campaigns;
    this.preparing = preparing_to_launch_in_current_timeframe_campaigns;
    this.concluded = concluded_campaigns;
  }

  private setCampaignsFilterAndNavigate(ids: number[], route = this.configuration.ROUTING_CONSTANTS.CALENDAR) {
    if (!ids || !ids.length) {
      return;
    }

    this.filterManagementService.updateCurrentFilterSet({ [FilterName.Campaigns]: ids });
    this.router.navigate([ route ]);
  }

  public loadContextData() {
    const summary$ = this.homePageService.userBudgetSummary$
      .pipe(
        filter(event => event && event.budgetId === this.currentBudget?.id),
        filter(event => event.forceReload || this.contextChanged),
        tap(event => this.budgetSummary = event.data)
      );

    this.homePageService.contextData$
      .pipe(
        filter(data => data != null && !this.homePageService.loadingHomePage),
        tap((data) => {
          this.contextChanged = true;
          this.currentBudget = data.budget;
        }),
        switchMap(() => summary$),
        takeUntil(this.destroy$)
      )
      .subscribe(
        () => {
          this.prepareData();
          queueMicrotask(() => {
            this.setState(WidgetState.READY);
          });
        },
        (err) => this.handleError(err)
      );
  }

  public openLaunchedCampaigns() {
    this.setCampaignsFilterAndNavigate(this.launched);
  }

  public openConcludedCampaigns() {
    this.setCampaignsFilterAndNavigate(this.concluded);
  }

  public openUnderWayCampaigns() {
    this.setCampaignsFilterAndNavigate(this.underWay);
  }

  public openPreparingToLaunchCampaigns() {
    this.setCampaignsFilterAndNavigate(this.preparing);
  }
}
