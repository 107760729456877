<div class="home-page">
  <div class="home-background">
    <div class="home-container">
      <home-header [budget]="currentBudget"></home-header>
      <div class="sections">
        <priority-actions [displayTitle]="showPriorityActions"></priority-actions>
        <plan-highlights *ngIf="showPlanHighlights"></plan-highlights>
      </div>
    </div>
  </div>
</div>
