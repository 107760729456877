import { HttpClient, HttpEvent, HttpEventType } from '@angular/common/http';
import { Observable, map } from 'rxjs';
import { Injectable } from '@angular/core';

import { getRequestOptions } from 'app/shared/utils/http-request.utils';

@Injectable({
  providedIn: 'root'
})
export abstract class BaseApiService {
  protected abstract readonly apiUrl: string;
  constructor(protected http: HttpClient) {}

  get<T>(endpoint: string, params: { [key: string]: any }): Observable<T> {
    return this.http.get<T>(
      `${this.apiUrl}${endpoint}`, getRequestOptions(params)
    ).pipe(
      map(event => {
        return event as T;
      })
    );
  }

  post<T>(endpoint:string, requestData): Observable<T> {
    return this.http.post<T>(
      `${this.apiUrl}${endpoint}`,
      requestData
    ).pipe(
      map((response) => {
        return response;
      })
    );
  }
}
