import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AfterViewInit, ChangeDetectorRef, Component, inject, Inject, QueryList, ViewChildren } from '@angular/core';
import { MatButton } from '@angular/material/button';
import { DIALOG_ACTION_TYPE, DialogContext } from '../../types/dialog-context.interface';
import { CustomFieldsService } from 'app/budget-object-details/components/custom-fields/custom-field.service';
import { DrawerStackService } from 'app/budget-object-details/services/drawer-stack.service';

export const ExtendedDialogClassName = 'extended-confirmation-dialog';

@Component({
  selector: 'confirmation-dialog',
  styleUrls: ['./confirmation-dialog.component.scss'],
  templateUrl: './confirmation-dialog.component.html'
})
export class ConfirmationDialogComponent implements AfterViewInit {
  @ViewChildren('button') buttons: QueryList<MatButton>;

  public context: DialogContext;
  public ACTION_TYPE = DIALOG_ACTION_TYPE;
  applyToAllChildren: boolean = false; // Default value
  private customFieldService = inject(CustomFieldsService)
  private stackManager = inject(DrawerStackService)


  constructor(
    public dialogRef: MatDialogRef<ConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) context: DialogContext,
    private cdr: ChangeDetectorRef
  ) {
    this.context = context;
  }

  public callHandler(handler: Function): void {
    if (handler) { handler(); }
    // Passing 'true' to indicate dialog was closed by action handler not implicitly
    this.dialogRef.close(true);
  }

  public inheritancePropagationCallHandler(action: 'Keep' | 'Replace', handler: Function, type: DIALOG_ACTION_TYPE) {
    console.log("Apply to all children : ", this.applyToAllChildren);
    
    const drawerConfigs = this.stackManager.stackConfig;
    const totalOpenedDrawers = Object.keys(drawerConfigs).length;
    if(totalOpenedDrawers > 0) {
      const drawerType = Object.keys(drawerConfigs)[totalOpenedDrawers - 1];
      const drawerConfig = drawerConfigs[drawerType];
      this.customFieldService.setApplyToAllChildren({ id: drawerConfig.id, applyToAllChildren: this.applyToAllChildren, action: action });
    } 

    if (handler) { handler(); }
    this.dialogRef.close(type);
  }

  private setInitialFocus() {

    if(this.context?.parentChangeInheritance) {
      const drawerConfigs = this.stackManager.stackConfig;
      const totalOpenedDrawers = Object.keys(drawerConfigs).length;
      
      if(totalOpenedDrawers > 0) {
        const drawerType = Object.keys(drawerConfigs)[totalOpenedDrawers - 1];
        const drawerConfig = drawerConfigs[drawerType];
        if(!drawerConfig.id) {
          this.context.parentChangeInheritance = false; // Reset the flag after setting focus
          this.context.content = this.context.content.replace(/<br><br>\s*$/, '<br>') // removed the line break pertaining to Apply to children checkbox
          this.cdr.detectChanges(); // Notify Angular to re-run change detection

        }
      }
    }

    const idx = this.context.actions?.findIndex(action => action.initialFocus);
    const buttonToFocus = this.buttons?.get(idx);
    if (!buttonToFocus) {
      return;
    }

    setTimeout(() => {
      buttonToFocus.focus();
    })
  }

  ngAfterViewInit(): void {
    this.setInitialFocus();
  }
}
