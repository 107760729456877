import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  ManageCegViewMode, ManageTableViewConfig,
  ManageTableViewConfigSections, TableConfig,
  TableConfigActionType, TableConfigDialog,
  TableConfigKeys
} from '@manage-ceg/types/manage-ceg-page.types';
import { LocalStorageService } from '@common-lib/services/local-storage.service';
import { CEG_TABLE_CONFIG } from '@shared/constants/modes.constant';

@Component({
  selector: 'table-config-dialog',
  templateUrl: './table-config-dialog.component.html',
  styleUrls: ['./table-config-dialog.component.scss']
})
export class TableConfigDialogComponent {
  public tableActionType = TableConfigActionType;
  public cegViewMode = ManageCegViewMode;
  public tableConfigKeys = TableConfigKeys;
  public tableViewSections = ManageTableViewConfigSections;
  forecastCheckedTooltipMsgForFormula = 'Deselect the Forecast option to view the formula in the grid';
  disableColumnFormulaTooltipForForecast = true;

  constructor(
    public dialogRef: MatDialogRef<TableConfigDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: TableConfigDialog
  ) { 
    this.initConfigureDialogState();
  }

  initConfigureDialogState(): void {
    let configSection = this.data.config[ManageTableViewConfigSections.columns] as Array<TableConfig>;
    let showForecastCheckbox = configSection.find(configType => configType.key === TableConfigKeys.forecast)?.value;
    
    if(showForecastCheckbox) {
      this.disableColumnFormulaTooltipForForecast = false;
      let formulaToggle = configSection.find(configType => configType.key === TableConfigKeys.columnsFormula);
      formulaToggle.disabled = true;
      formulaToggle.value = false;
    }else {
      this.disableColumnFormulaTooltipForForecast = true;
    }

    let isFormulaToggleOn = configSection.find(configType => configType.key === TableConfigKeys.columnsFormula)?.value;
    if(isFormulaToggleOn) {
      let forecastCheckbox = configSection.find(configType => configType.key === TableConfigKeys.forecast);
      forecastCheckbox.disabled = true;
      forecastCheckbox.value = false;
    }
  }

  onSelectionChange(item: TableConfig, value: boolean, groupKey: ManageTableViewConfigSections): void {
    item.value = value;
    if (item.type === TableConfigActionType.checkbox) {
      this.checkFormulaAllow(this.data.config[groupKey] as Array<TableConfig>);
    }

    if(item.type === TableConfigActionType.toggle) {
      this.checkForecastCheckboxAllow(item.key, value, this.data.config[groupKey] as Array<TableConfig>);
    }

    if (groupKey === ManageTableViewConfigSections.columns) {
      this.checkColumnActions(item.key, value, this.data.config[groupKey]);
    }
  }

  onConfigChangesApply(): ManageTableViewConfig {
    const viewConfig = { segmentBreakdownData: {}, columns: {}, columnsFormula: false };
    Object.keys(this.data.config).forEach(rootKey => {
      const result = {};
      if (Array.isArray(this.data.config[rootKey])) {
        this.data.config[rootKey].forEach(item => {
          const { key, value } = item;
          result[key] = value || false;
          if (key === TableConfigKeys.columnsFormula) {
            viewConfig[key] = value || false;
          }
        });
        viewConfig[rootKey] = result;
      }
    });

    if (viewConfig[ManageTableViewConfigSections.columns][TableConfigKeys.separate]) {
      viewConfig[ManageTableViewConfigSections.columns][TableConfigKeys.committedAndPlanned] = false;
    }

    LocalStorageService.addToStorage(CEG_TABLE_CONFIG, viewConfig);
    return viewConfig as ManageTableViewConfig;
  }

  checkFormulaAllow(configSection: Array<TableConfig>): void {
    const formulaKeys = [TableConfigKeys.formula, TableConfigKeys.columnsFormula]
    const condition = configSection
      .filter(configType => configType.type === TableConfigActionType.checkbox && configType.key !== TableConfigKeys.forecast)
      .some(configType => !configType.value);
    const formula = configSection.find(configType => formulaKeys.includes(configType.key));
    if (condition) {
      formula.value = false;
      formula.disabled = true;
    } else {
      formula.disabled = false;
    }

    let showForecastCheckbox = configSection.find(configType => configType.key === TableConfigKeys.forecast)?.value;
    if(showForecastCheckbox) {
      formula.disabled = true;
      formula.value = false;
      this.disableColumnFormulaTooltipForForecast = false;
    }else {
      this.disableColumnFormulaTooltipForForecast = true;
    }


  }

  checkForecastCheckboxAllow(key: string, value: boolean, configSection: Array<TableConfig>): void {
    const forecastCheckbox = configSection.find(item => item.key === TableConfigKeys.forecast);
    if (key === TableConfigKeys.columnsFormula) {
      forecastCheckbox.disabled = value;
      if (value) {
        forecastCheckbox.value = false;
      }
    }
  }

  checkColumnActions(key: string, value: boolean, configSection: Array<TableConfig>): void {
    const handleCommittedAndPlannedCheckbox = () => {
      const relatedItems = configSection.filter(item => item?.connectedWith?.includes(key));
      const separateToggle = relatedItems.find(item => item.key === TableConfigKeys.separate);

      relatedItems.forEach(item => {
        if (!item.type) {
          item.value = value && !separateToggle.value ? separateToggle.value : value;
        }
        if (item.type === TableConfigActionType.toggle) {
          item.disabled = !value;
          if (!value) {
            item.value = value;
          }
        }
      });
    }
    const handleSeparateToggle = () => {
      const relatedItems = configSection.filter(item => item?.connectedWith?.includes(key));
      relatedItems.forEach(item => item.value = value);
    }
    const keyActions = {
      [TableConfigKeys.committedAndPlanned]: handleCommittedAndPlannedCheckbox,
      [TableConfigKeys.separate]: handleSeparateToggle
    }
    if (keyActions[key]) {
      keyActions[key]();
    }
  }

}
