import { inject, Injectable } from '@angular/core';
import { BehaviorSubject, concat, Observable, of, Subject } from 'rxjs';
import { catchError, switchMap, tap, map } from 'rxjs/operators';
import { BudgetService } from 'app/shared/services/backend/budget.service';
import { UserBudgetSummaryDO } from 'app/shared/types/user-budget-summary.interface';
import { ExpensesService } from 'app/shared/services/backend/expenses.service';
import { ExpenseDO, ExpenseTotalsDO } from 'app/shared/types/expense.interface';
import { Budget } from 'app/shared/types/budget.interface';
import { BudgetTimeframe } from 'app/shared/types/timeframe.interface';
import { CompanyDO } from 'app/shared/types/company.interface';
import { BudgetSegmentAccess } from 'app/shared/types/segment.interface';
import { SegmentedObject } from 'app/shared/types/segmented-budget-object';
import { MetricService, MetricMappingDO } from 'app/shared/services/backend/metric.service';
import { MetricsProgressDO } from 'app/shared/types/metrics-progress.interface';
import { BudgetDataService } from 'app/dashboard/budget-data/budget-data.service';
import { LightCampaign } from 'app/shared/types/campaign.interface';
import { Goal } from 'app/shared/types/goal.interface';
import { LightProgram } from 'app/shared/types/program.interface';
import { UpdateMetricsSummaryDO } from '../types/update-metrics-summary-do.interface';
import { Configuration } from 'app/app.constants';
import { BudgetEstimatedBusinessValue, BudgetEstimatedBusinessValueCampaign } from 'app/shared/types/budget-estimated-business-value.interface';
import { BudgetObjectService } from 'app/shared/services/budget-object.service';
import { SharedCostRule } from 'app/shared/types/shared-cost-rule.interface';
import { ObjectAccessManagerService } from 'app/shared/services/object-access-manager.service';
import { BudgetObjectDetailsManager } from 'app/budget-object-details/services/budget-object-details-manager.service';
import { BudgetObjectType } from 'app/shared/types/budget-object-type.interface';

interface HomePageContextData {
  budget: Budget;
  timeframes: BudgetTimeframe[];
  company: CompanyDO;
  segments: BudgetSegmentAccess[];
  sharedCostRules: SharedCostRule[];
  campaignTypes: BudgetObjectType[];
}

type HomePageDataObject = Goal | LightCampaign | LightProgram | ExpenseDO | MetricMappingDO;

interface BudgetSpecificSubjectEvent<T> {
  budgetId: number;
  forceReload: boolean;
  data: T;
}

@Injectable({
  providedIn: 'root'
})
export class HomePageService {
  private readonly budgetService = inject(BudgetService);
  private readonly expensesService = inject(ExpensesService);
  private readonly metricService = inject(MetricService);
  private readonly budgetDataService = inject(BudgetDataService);
  private readonly configuration = inject(Configuration);
  private readonly accessManager = inject(ObjectAccessManagerService);
  private readonly budgetObjectDetailsManager = inject(BudgetObjectDetailsManager);

  private readonly userBudgetSummary = new BehaviorSubject<BudgetSpecificSubjectEvent<UserBudgetSummaryDO>>(null);
  private readonly metricsProgress = new BehaviorSubject<BudgetSpecificSubjectEvent<MetricsProgressDO>>(null);
  private readonly metricsForUpdateSummary = new BehaviorSubject<BudgetSpecificSubjectEvent<UpdateMetricsSummaryDO>>(null);
  private readonly estimatedBusinessValue = new BehaviorSubject<BudgetSpecificSubjectEvent<BudgetEstimatedBusinessValue>>(null);
  private readonly expenses = new BehaviorSubject<BudgetSpecificSubjectEvent<ExpenseDO[]>>(null);
  private readonly metricMappings = new BehaviorSubject<MetricMappingDO[]>(null);
  private readonly expenseTotals = new BehaviorSubject<BudgetSpecificSubjectEvent<ExpenseTotalsDO>>(null);
  private readonly contextData = new BehaviorSubject<Partial<HomePageContextData>>(null);
  private readonly noBudgets = new Subject<void>();

  public readonly contextData$ = this.contextData.asObservable();
  public readonly userBudgetSummary$ = this.userBudgetSummary.asObservable();
  public readonly metricsProgress$ = this.metricsProgress.asObservable();
  public readonly metricsForUpdateSummary$ = this.metricsForUpdateSummary.asObservable();
  public readonly estimatedBusinessValue$ = this.estimatedBusinessValue.asObservable();
  public readonly expenses$ = this.expenses.asObservable();
  public readonly metricMappings$ = this.metricMappings.asObservable();
  public readonly expensesTotals$ = this.expenseTotals.asObservable();
  public readonly noBudgets$ = this.noBudgets.asObservable();
  private _loadingHomePage: boolean = false;
  private rawObjectsByType = {
    [this.configuration.OBJECT_TYPES.goal]: null,
    [this.configuration.OBJECT_TYPES.campaign]: null,
    [this.configuration.OBJECT_TYPES.program]: null,
    [this.configuration.OBJECT_TYPES.expense]: null,
    [this.configuration.OBJECT_TYPES.metric]: null,
  };
  private objectsMapByType: { [key: string]: Map<number, HomePageDataObject> } = {
    [this.configuration.OBJECT_TYPES.goal]: null,
    [this.configuration.OBJECT_TYPES.campaign]: null,
    [this.configuration.OBJECT_TYPES.program]: null,
    [this.configuration.OBJECT_TYPES.expense]: null,
    [this.configuration.OBJECT_TYPES.metric]: null,
  };

  public get loadingHomePage(): boolean {
    return this._loadingHomePage;
  }

  public set loadingHomePage(value: boolean) {
    this._loadingHomePage = value;
  }

  public static normalizeDecimal(value: number, maxLimit: number): number {
    const result = Math.min(Math.round(value * 100), maxLimit);

    return isNaN(result) ? 0 : result;
  }

  public filterObjectsBySegment(
    segments: BudgetSegmentAccess[],
    sharedCostRules: SharedCostRule[],
    objects: SegmentedObject[] = []
  ) {
    if (!Array.isArray(segments) || !Array.isArray(sharedCostRules)) {
      return [];
    }

    return objects.filter(item => {
      if (BudgetObjectService.isSegmentlessObject(item)) {
        return true;
      }

      return this.accessManager.hasAccessBySegmentData(
        {
          split_rule: item.splitRuleId,
          company_budget_segment1: item.budgetSegmentId
        },
        segments,
        sharedCostRules
      );
    });
  }

  private buildObjectMap(data: { id: number }[]): Map<number, HomePageDataObject> {
    const dataMap = new Map();
    data.forEach(item => dataMap.set(item.id, item));
    return dataMap;
  }

  public setContextData(data: Partial<HomePageContextData>) {
    this.contextData.next(data);
  }

  public loadUserBudgetSummary(budgetId: number, forceReload = false) {
    this.userBudgetSummary.next(null);
    return this.budgetService.getUserBudgetSummary(budgetId)
      .pipe(
        tap((data) => this.userBudgetSummary.next({
          budgetId,
          forceReload,
          data,
        }))
      );
  }

  public loadMetricsProgress(budgetId: number, forceReload = false) {
    this.metricsProgress.next(null);
    return this.metricService.getMetricMappingsProgress(budgetId)
      .pipe(
        catchError(() => of({})),
        tap((data) => this.metricsProgress.next({
          budgetId,
          data,
          forceReload
        }))
      );
  }

  public loadMetricsForUpdate(budgetId: number, forceReload = false) {
    this.metricsForUpdateSummary.next(null);
    return this.metricService.getMetricsForUpdateSummary(budgetId)
      .pipe(
        catchError(() => of({})),
        tap((data) => this.metricsForUpdateSummary.next({
          budgetId,
          data,
          forceReload
        }))
      );
  }

  public loadEstimatedBusinessValue(budgetId: number, forceReload = false) {
    return this.budgetService.getEstimatedBusinessValue(budgetId)
      .pipe(
        catchError(() => of(null)),
        tap((data) => this.estimatedBusinessValue.next({
          budgetId,
          data,
          forceReload
        }))
      );
  }

  public filterCampaignsBusinessValueAccess(
    campaigns: BudgetEstimatedBusinessValueCampaign[],
    segments: BudgetSegmentAccess[],
    sharedCostRules: SharedCostRule[]
  ): BudgetEstimatedBusinessValueCampaign[] {
    return campaigns.filter(campaign => {
      return this.accessManager.hasAccessBySegmentData(
        {
          split_rule: campaign.split_rule,
          company_budget_segment1: campaign.company_budget_segment1
        },
        segments,
        sharedCostRules
      )
    });
  }

  public loadExpenses(
    requestData: { companyId: number, budgetId: number, allocationsIds: number[] },
    forceReload = false
    ) {
    const { companyId, budgetId, allocationsIds } = requestData;
    const params = { budget_id: budgetId };

    this.expenses.next(null);
    this.expenseTotals.next(null);

    return this.expensesService.getExpensesTotalAmount(params)
      .pipe(switchMap(data => {
        this.expenseTotals.next({
          forceReload,
          budgetId,
          data
        });
        const { total_count: totalCount } = data;
        return (data.total_count === 0) ?
          of([]) :
          this.expensesService.getExpensesByChunks(
          {
            company: companyId,
            budget:  budgetId,
            company_budget_allocation_ids: allocationsIds.join(','),
            active: true,
            include_nested: true
          },
          totalCount
        );
      }))
      .pipe(
        tap((data) => {
          this.expenses.next({
            forceReload,
            budgetId,
            data
          });
        })
      );
  }

  public loadMetricMappings(companyId: number, ids: number[] = []) {
    const mappings$ = ids && ids.length ?
      this.metricService.getMetricMappings(companyId, { ids: ids.join(',') }) :
      of([]);

    return mappings$
      .pipe(
        map(mappings => this.budgetObjectDetailsManager.filterMetricValuesForFixedDate(mappings)),
        tap(metrics => {
          this.metricMappings.next(metrics);
          this.rawObjectsByType[this.configuration.OBJECT_TYPES.metric] = metrics;
        })
      )
  }

  public setNoBudgetsFlag() {
    this.noBudgets.next();
  }

  private getObjectMapSource(type: string): Observable<HomePageDataObject[]> {
    const objectTypes = this.configuration.OBJECT_TYPES;
    switch (type) {
      case objectTypes.goal:
        return this.budgetDataService.goalsSnapshot ?
          concat(of(this.budgetDataService.goalsSnapshot), this.budgetDataService.goalList$) :
          this.budgetDataService.goalList$;

      case objectTypes.campaign:
        return this.budgetDataService.lightCampaignsSnapshot ?
          concat(of(this.budgetDataService.lightCampaignsSnapshot), this.budgetDataService.lightCampaignList$) :
          this.budgetDataService.lightCampaignList$;

      case objectTypes.program:
        return this.budgetDataService.lightProgramsSnapshot ?
          concat(of(this.budgetDataService.lightProgramsSnapshot), this.budgetDataService.lightProgramList$) :
          this.budgetDataService.lightProgramList$;

      case objectTypes.metric:
        return this.metricMappings$;

      case objectTypes.expense:
        return this.expenses.pipe(
          map(event => event.data)
        );

      default:
        return null;
    }
  }

  public getObjectMap(type): Observable<Map<number, HomePageDataObject>> {
    const source$ = this.getObjectMapSource(type);
    return source$ ?
      source$.pipe(
        map((data) => {
          if (data === this.rawObjectsByType[type]) {
            return this.objectsMapByType[type];
          }

          this.rawObjectsByType[type] = data;
          this.objectsMapByType[type] = this.buildObjectMap(data);
          return this.objectsMapByType[type];
        })
      ) :
      of(null);
  }
}
