export interface StartTimerEvent {
  event_type: 'start';
  event_name: string;
}

export interface StopTimerEvent {
  event_type: 'stop';
  event_name: string;
  request?: {
    url: string,
    token: string,
    body: any
  };
}

export interface KillWorkerEvent {
  event_type: 'kill';
}
