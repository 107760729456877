import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { AppDataLoader } from 'app/app-data-loader.service';
import { BudgetSettingsPageService } from './budget-settings-page.service';
import { BudgetStatus } from 'app/shared/types/budget-status.type';
import { Budget } from 'app/shared/types/budget.interface';
import { BudgetTimeframe } from 'app/shared/types/timeframe.interface';
import { BudgetTableChangeEvent, BudgetTableActionType } from '../budget-table/budget-table.types';
import { BudgetTableService } from '../../services/budget-table.service';
import { Currency } from 'app/shared/types/currency.interface';
import { ComponentCanDeactivate } from 'app/shared/guards/leave-page.guard';
import { ActionInterceptorDestructor, ActionInterceptorsService } from 'app/shared/services/action-interceptors.service';
import { AppRoutingInterceptors } from 'app/shared/types/app-routing-interceptors.type';
import { BudgetTableRecordInteractionsService } from '../../services/budget-table-record-interactions.service';
import { BudgetTableSelectedRecords } from '../../types/budget-table-selection.types';
import { BudgetTableContextMenuService } from '../../services/budget-table-context-menu.service';
import { BudgetObjectDialogService } from 'app/shared/services/budget-object-dialog.service';
import { catchError, takeUntil, switchMap, take } from 'rxjs/operators';
import { BudgetBaseline, BudgetService } from '@shared/services/backend/budget.service';
import { UtilityService } from '@shared/services/utility.service';
import { BudgetServiceActionType } from '@shared/enums/interceptor-action-type.enum';
import { ConnectedPosition } from '@angular/cdk/overlay';
import { PendoEventName, PendoManagerService } from '@shared/services/pendo-manager.service';
import { ForecastBudgetService } from '../../services/forecast-budget.service';

@Component({
  selector: 'budget-settings-page',
  templateUrl: './budget-settings-page.component.html',
  styleUrls: ['./budget-settings-page.component.scss'],
  providers: [
    AppDataLoader,
    BudgetSettingsPageService,
    BudgetTableRecordInteractionsService,
    BudgetTableService,
    BudgetTableContextMenuService
  ]
})
export class BudgetSettingsPageComponent implements OnInit, OnDestroy, ComponentCanDeactivate {
  public amountChanged = false;
  private destroy$ = new Subject<void>();
  private interceptorDestructors: ActionInterceptorDestructor[] = [];
  private readonly tableChangeHandlers: Record<string, (payload?: any) => void> = {
    [BudgetTableActionType.SegmentChange]: (payload?) => this.budgetSettingsPageService.updateSegment(payload),
    [BudgetTableActionType.SegmentAmountChange]: (payload?) => this.budgetSettingsPageService.updateSegmentAmounts(payload),
    [BudgetTableActionType.TimeframeLockChange]: (payload?) => this.budgetSettingsPageService.toggleAllocationLockState(payload),
    [BudgetTableActionType.SegmentDelete]: (payload?) => this.budgetSettingsPageService.removeSegmentsFromList(payload),
    [BudgetTableActionType.SegmentDuplicate]: (payload?) => this.budgetSettingsPageService.duplicateSegments(payload),
    [BudgetTableActionType.SegmentCreate]: (payload?) => this.budgetSettingsPageService.addNewSegment(),
    [BudgetTableActionType.GroupChange]: (payload?) => this.budgetSettingsPageService.updateGroup(payload),
    [BudgetTableActionType.GroupDuplicate]: (payload?) => this.budgetSettingsPageService.duplicateGroups(payload),
    [BudgetTableActionType.SegmentsGroup]: (payload?) => this.budgetSettingsPageService.groupSegments(payload),
    [BudgetTableActionType.SegmentsUngroup]: (payload?) => this.budgetSettingsPageService.ungroupSegments(payload),
    [BudgetTableActionType.SegmentsMove]: (payload?) => this.budgetSettingsPageService.moveSegments(payload),
    [BudgetTableActionType.GroupSegmentCreate]: (payload?) => this.budgetSettingsPageService.addGroupSegment(payload),
  };
  protected isBaselineChecked = false;

  confirmReadOnlyText = 'By setting this budget to Read Only, you will no longer receive updates to campaigns, expense groups, expenses, or metrics that were created from a third party integration (Google Ads, LinkedInAds, Facebook Ads, Salesforce & Hubspot). Should you switch this budget back to a Live or Draft Budget, a new integration will be required, possibly creating duplicates of everything you have today. Would you like to continue?'
  public tooltipContext = {
    header: 'Budget Baseline',
    body: 'This will create a snapshot of your budget that can then be compared with previous baselines.',
    icon: ['fas', 'calendar-days'],
    width: '235'
  };
  public tooltipPosition: ConnectedPosition = {
    originX: 'center',
    originY: 'top',
    overlayX: 'center',
    overlayY: 'bottom',
    offsetY: -5,
    offsetX: -35
  };

  public getForecastStatus: boolean;

  constructor(
    private readonly appDataLoader: AppDataLoader,
    private readonly budgetTableService: BudgetTableService,
    private readonly actionInterceptorsService: ActionInterceptorsService,
    public readonly budgetSettingsPageService: BudgetSettingsPageService,
    public readonly budgetObjectDialogService: BudgetObjectDialogService,
    private readonly budgetService: BudgetService,
    private readonly utilityService: UtilityService,
    private readonly pendoManager: PendoManagerService,
    private forecastBudgetService: ForecastBudgetService
  ) {
  }

  get budgetData(): Budget {
    return this.budgetSettingsPageService.budget;
  }

  get tableData() {
    return this.budgetTableService.tableData;
  }

  get budgetCurrency(): Currency {
    return this.budgetSettingsPageService.budgetCurrency;
  }

  get budgetAllocations(): BudgetTimeframe[] {
    return this.budgetSettingsPageService.budgetAllocations;
  }

  get selectedSegments(): string[] {
    return this.budgetSettingsPageService.selectedRecords.segments;
  }

  get hasUnsavedChanges(): boolean {
    return this.budgetSettingsPageService.hasUnsavedChanges;
  }

  get grandTotal() {
    return this.budgetTableService.grandTotal;
  }

  get editableBudget() {
    return this.budgetData?.status !== BudgetStatus.Reference;
  }

  get userIsBudgetOwner() {
    return this.budgetSettingsPageService.userIsBudgetOwner;
  }

  canDeactivate(): Observable<boolean> {
    this.amountChanged = false;
    return this.budgetSettingsPageService.canLeavePage();
  };

  public onBudgetStatusChange(newStatus: BudgetStatus) {
    const enablingReadOnlyState = newStatus === BudgetStatus.Reference;
    const confirmationResult$ = enablingReadOnlyState ? this.getReadOnlyConfirmation() : of(true);

    confirmationResult$.pipe(
      takeUntil(this.destroy$)
    ).subscribe(confirmed => {
      if (!confirmed) {
        return;
      }
      if (enablingReadOnlyState) {
        this.budgetSettingsPageService.removeActiveIntegrations();
      }
      this.budgetSettingsPageService.updateBudgetStatus(newStatus);
    })
  }

  protected get todayFixedDateEnabled(): boolean {
    return this.budgetSettingsPageService.todayFixedDateEnabled;
  }

  protected get todayFixedDate(): Date {
    return this.budgetSettingsPageService.todayFixedDate;
  }

  protected get showTodayDatePicker(): boolean {
    return this.budgetSettingsPageService.showTodayDatePicker;
  }

  protected activateTodayFixedDate(enabled: boolean): void {
    this.budgetSettingsPageService.todayDateToggleState(enabled);
  }

  protected onTodayDateChange(newDate: Date): void {
    this.budgetSettingsPageService.updateTodayFixedDate(newDate);
  }

  getReadOnlyConfirmation(): Subject<boolean> {
    const confirmationResult$ = new Subject<boolean>();
    const complete = state => {
      confirmationResult$.next(state);
      confirmationResult$.complete();
    }
    const context = {
      content: this.confirmReadOnlyText,
      submitAction: {
        handler: () => complete(true)
      },
      cancelAction: {
        handler: () => complete(false)
      }
    }

    const dialog = this.budgetObjectDialogService.openConfirmationDialog(context, { width: '560px' });
    dialog.afterClosed().subscribe(() =>  complete(false))
    return confirmationResult$;
  }

  ngOnInit(): void {
    this.appDataLoader.init();
    this.interceptorDestructors.push(
      this.actionInterceptorsService.registerInterceptor({
        actionType: AppRoutingInterceptors.OnOpeningDetails,
        handler: () => this.canDeactivate()
      })
    );
    this.interceptorDestructors.push(
      this.actionInterceptorsService.registerInterceptor({
        actionType: BudgetServiceActionType.OnChangingSelectedBudget,
        handler: () => this.canDeactivate()
      })
    );

    this.forecastBudgetService.forecastEnabled$
    .pipe(takeUntil(this.destroy$))
    .subscribe((enabled: boolean) => {
      this.getForecastStatus = enabled;
    });
    
    this.getForecastStatus = this.forecastBudgetService.getForecastEnabled();

  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    this.interceptorDestructors.forEach(interceptorDestructor => interceptorDestructor?.());
  }

  private saveBaseline(budgetId: number, baselineName: string): Observable<{[key: string]: string}> {
    return this.budgetService.saveBaseline(budgetId, baselineName);
  }

  private getBudgetBaselines(budgetId: number): Observable<BudgetBaseline[]> {
    return this.budgetService.getBaselines(budgetId);
  }

  public openEditModal() {
    this.budgetSettingsPageService.editBudget();
  }

  public handleSelectionChanged(selectedRecords: BudgetTableSelectedRecords) {
    this.budgetSettingsPageService.selectedRecords = selectedRecords;
  }

  public handleTableChanges(event: BudgetTableChangeEvent) {
    const handler = this.tableChangeHandlers[event.actionType];
    if (handler) {
      handler(event.payload);
    }
    this.amountChanged = true;
  }

  public handleRemainingChanged(remaining: number) {
    this.budgetTableService.setRemainingBudget(remaining);
  }

  public saveBudget(): void {
    if (this.isBaselineChecked) {
      const { id: budgetId } = this.budgetData;
      const context = {
        baselineList: this.getBudgetBaselines(budgetId).pipe(catchError(() => of([])))
      }

      const dialog = this.budgetObjectDialogService.openBudgetBaselineDialog(context, { width: '480px', panelClass: 'baseline-dialog' });
      dialog.afterClosed().subscribe((baselineName: string) => {
        if (baselineName) {
          this.budgetSettingsPageService.saveChanges();
          this.isBaselineChecked = false;
          const hadUnsavedChanges = this.hasUnsavedChanges;
          const onSaveTrigger$ = hadUnsavedChanges ? this.budgetSettingsPageService.onSaveCompleteTrigger$ : of(null);
          onSaveTrigger$.pipe(
              take(1),
              switchMap(() => this.saveBaseline(budgetId, baselineName)),
              takeUntil(this.destroy$)
            )
            .subscribe({
              next: () => {
                if (!hadUnsavedChanges) {
                  this.utilityService.showCustomToastr('Baseline have been saved successfully!');
                }
                this.pendoManager.track(PendoEventName.BudgetSaved, { save_as_baseline: true });
              },
              error: error => this.utilityService.handleError(error),
            });
        }
      });
      return;
    }
    this.budgetSettingsPageService.saveChanges({
      onSuccess: () => this.pendoManager.track(PendoEventName.BudgetSaved, { save_as_baseline: false })
    });
  }
}
