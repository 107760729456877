import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { filter, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { WidgetConfig, WidgetState } from '../../types/widget.interface';
import { WidgetStateService } from '../../services/widget-state.service';
import { Budget } from 'app/shared/types/budget.interface';
import { BudgetDataService } from '../../../dashboard/budget-data/budget-data.service';
import { UserManager } from 'app/user/services/user-manager.service';
import { CompanyDataService } from 'app/shared/services/company-data.service';
import { UtilityService } from 'app/shared/services/utility.service';
import { Configuration } from 'app/app.constants';
import { ProgressChartDataItem } from '../../components/progress-chart/progress-chart.component';
import { HomePageService } from '../../services/home-page.service';
import { UserBudgetSummaryDO } from 'app/shared/types/user-budget-summary.interface';
import { getBudgetYearProgress } from 'app/shared/utils/budget.utils';

@Component({
  selector: 'budget-spent-widget',
  styleUrls: ['./budget-spent-widget.component.scss'],
  templateUrl: './budget-spent-widget.component.html'
})
export class BudgetSpentWidgetComponent implements OnInit, OnDestroy {
  @Input() config: WidgetConfig;
  @Output() onLoaded = new EventEmitter();

  private readonly destroy$ = new Subject<void>();
  private budgetSummary: UserBudgetSummaryDO = null;
  private MAX_VALUE_LIMIT = 999;
  private contextChanged = false;
  public currentBudget: Budget = null;
  public widgetState = WidgetState;
  public state = WidgetState.INITIAL;
  public cegFlagEnabled = false;
  public overlayText = 'Click to go to the Dashboard and analyze your budget';

  public yearProgress;
  public budgetProgress;
  public plannedProgress;
  public yearChartData: ProgressChartDataItem[] = [];
  public budgetChartData: ProgressChartDataItem[] = [];
  public zeroExpense = null;
  public expensesCount = null;

  private summary$ = null;

  constructor(
    private readonly widgetStateManager: WidgetStateService,
    private readonly budgetDataService: BudgetDataService,
    private readonly userManager: UserManager,
    private readonly companyDataService: CompanyDataService,
    private readonly utilityService: UtilityService,
    private readonly router: Router,
    private readonly configuration: Configuration,
    private readonly homePageService: HomePageService
  ) {}

  ngOnInit(): void {
    this.setState(WidgetState.LOADING);
    this.homePageService.noBudgets$
      .pipe(
        takeUntil(this.destroy$),
        take(1)
      )
      .subscribe(
        () => {
          this.setState(WidgetState.HIDDEN);
          this.destroy$.next();
        }
      );
    this.summary$ = this.homePageService.userBudgetSummary$
      .pipe(
        filter(event => event && event.budgetId === this.currentBudget?.id),
        filter(event => event.forceReload || this.contextChanged),
        tap(event => {
          this.budgetSummary = event?.data;
        }),
        takeUntil(this.destroy$)
      );
    this.homePageService.contextData$
      .pipe(
        filter(data => data?.budget != null),
        tap((data) => {
          this.setState(WidgetState.LOADING);
          this.currentBudget = data.budget;
          this.cegFlagEnabled = !!this.currentBudget?.new_campaigns_programs_structure;
          this.contextChanged = true;
        }),
        switchMap(() => this.summary$),
        takeUntil(this.destroy$)
      )
      .subscribe({
        next: () => {
          if (this.currentBudget?.new_campaigns_programs_structure) {
            this.prepareChartData();
          } else {
            this.loadContextDataForOldWorld();
          }
        },
        error: (err) => this.handleError(err)
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private setState(state: WidgetState) {
    this.state = state;
    this.widgetStateManager.setState(this.state, this.config);
  }

  private handleError(err) {
    this.utilityService.handleError(err);
  }

  private prepareChartData() {
    const { total_spent_expenses, total_planned_expenses, total_budget } = this.budgetSummary;
    this.contextChanged = false;
    this.yearProgress = getBudgetYearProgress(this.currentBudget);
    this.zeroExpense = total_spent_expenses === 0;
    this.budgetProgress = HomePageService.normalizeDecimal(total_spent_expenses / total_budget, this.MAX_VALUE_LIMIT);
    this.plannedProgress = HomePageService.normalizeDecimal(total_planned_expenses / total_budget, this.MAX_VALUE_LIMIT);
    this.yearChartData = [
      {
        name: 'series1',
        color: '#76CEFF',
        value: this.yearProgress
      }
    ];
    this.budgetChartData = [
      {
        name: 'series1',
        color: '#7C4FFF',
        value: this.budgetProgress
      },
      {
        name: 'series2',
        color: '#E82987',
        value: this.plannedProgress
      }
    ];
    if (this.cegFlagEnabled) {
      this.budgetChartData[0].color = 'rgba(0, 99, 183, 1)';
    }
    setTimeout(() => {
      this.setState(WidgetState.READY);
    });
  }

  public loadContextDataForOldWorld() {
    const expenseTotals$ = this.homePageService.expensesTotals$
      .pipe(
        filter(event => event && event.budgetId === this.currentBudget?.id),
        tap((event) => {
          this.expensesCount = event?.data?.total_count;
        })
      );

    this.homePageService.contextData$
      .pipe(
        filter(data => data != null),
        tap((data) => {
          this.setState(WidgetState.LOADING);
          this.currentBudget = data.budget;
          this.cegFlagEnabled = !!this.currentBudget?.new_campaigns_programs_structure;
          this.contextChanged = true;
        }),
        switchMap(() => expenseTotals$),
        switchMap(() => this.summary$),
        takeUntil(this.destroy$)
      )
      .subscribe(
        () => { this.prepareChartData(); },
        (err) => this.handleError(err)
      );

  }

  public overlayAction() {
    this.router.navigate([this.configuration.ROUTING_CONSTANTS.DASHBOARD]);
  }
}
