import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil, tap } from 'rxjs/operators';
import { StateChangedEvent, WidgetStateService } from '../../services/widget-state.service';
import { WidgetConfig, WidgetState, WidgetType } from '../../types/widget.interface';
import { BudgetDataService } from 'app/dashboard/budget-data/budget-data.service';

@Component({
  selector: 'widget-section',
  styleUrls: ['./widget-section.component.scss'],
  templateUrl: './widget-section.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WidgetSectionComponent implements OnInit, OnChanges, OnDestroy {
  @Input() title = '';
  @Input() displayTitle = true;
  @Input() widgets: WidgetConfig[];

  private readonly destroy$ = new Subject<void>();
  public widgetType = WidgetType;
  public widgetClassMap = {};
  public noWidgetsVisible = false;
  public budgetCegStatusEnabled;
  public nonOptimized=false;

  constructor(
    private readonly widgetStateManager: WidgetStateService,
    private readonly cdRef: ChangeDetectorRef,
    private readonly budgetDataService: BudgetDataService
  ) {}

  ngOnInit(): void {
    const searchParams = new URLSearchParams(window.location.search);
    this.nonOptimized = searchParams.has('slow');
    const relevantWidgetTypes = this.widgets.map(widget => widget.type);
    this.widgetStateManager.stateChanged$
    .pipe(
      tap((stateEvent: StateChangedEvent) => {
        setTimeout(() => {
          this.widgetClassMap[stateEvent.target.type] = stateEvent.target.classData;
        });
      }),
      debounceTime(500),
      tap((stateEvent: StateChangedEvent) => {
          this.budgetCegStatusEnabled = this.budgetDataService.selectedBudgetSnapshot?.new_campaigns_programs_structure;
          const { values } = stateEvent;
          const hiddenWidgets = Object.entries(values).filter(([type, state]) => {
            return relevantWidgetTypes.includes(type as WidgetType) && state === WidgetState.HIDDEN;
          });
          this.noWidgetsVisible = hiddenWidgets.length === this.widgets.length;
          this.cdRef.detectChanges();
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.widgets && this.widgets) {
      this.widgetStateManager.registerWidgets(this.widgets);
    }
  }
}
