<div class="campaign-details" [formGroup]="formData">
  <details-timestamps
    [created]="currentState?.created"
    [updated]="currentState?.updated"></details-timestamps>
  <div class="details-panel">
    
    <!-- Start Date -->
    <div class="details-form-field w-50">
    <pfm-datepicker
      [label]="'Start Date'"
      (valueChange)="onHandleStartDateChange($event)"
      [value]="formData.controls.startDate.value"
      [id]="'campStartDate'"></pfm-datepicker>
    </div>
      
    <!-- End Date -->
    <div class="details-form-field w-50">
    <pfm-datepicker
      [label]="'End Date'"
      (valueChange)="onHandleEndDateChange($event)"
      [value]="formData.controls.endDate.value"
      [minDate]="minEndDate"
      [id]="'campEndDate'"></pfm-datepicker>
    </div>
      
    <!--Parent-->
    <div class="details-form-field w-50 parent">
      <div class="icon-container">
        <fa-icon [icon]="['fas', 'sitemap']" class="size-17"></fa-icon>
      </div>
      <parent-hierarchy-select
        [items]="locationItems"
        [disabled]="isReadOnlyMode"
        [showTriggerIcon]="true"
        [disableOptionCentering]="false"
        [selectedValue]="formData.get('location')?.value"
        [config]="{ selectPanelClass: 'details-parent-select', fieldAppearance: 'outline' }"
        (onChange)="handleParentSelectionChange.emit($event)"
        [id]="'parentCampaignList'"
      >
      </parent-hierarchy-select>
    </div>
    <!--Segment-->
    <div class="details-form-field w-50">
      <div class="icon-container">
        <fa-icon [icon]="['far', 'chart-pie-simple']"></fa-icon>
      </div>
      <hierarchy-select
        [items]="allowedSegmentSelectItems"
        [disabled]="isReadOnlyMode"
        [selectControlExternal]="segmentControl"
        [selectedSegmentId]="selectedSegmentId"
        [isChildCampaignCreation]="isChildCampaignCreation"
        [isSegmentControl]="true"
        [config]="selectSegmentsConfig"
        [groupSelectionAllowed]="false"
        (onChange)="handleSegmentChanged.emit($event)"
        [id]="'segmentList'"
      ></hierarchy-select>
    </div>
    <!-- Budget -->
    <div class="details-form-field w-50 planned-field" [matTooltip]="currencyTooltip"
    [matTooltipDisabled]="!!currentState?.objectId" [matTooltipShowDelay]="600"
    matTooltipClass="dark-theme-tooltip simple multi-line"
    matTooltipPosition="above" >
      <mat-select class="currency-select" 
      [class.mat-select-disabled]="!currentState?.objectId"
      formControlName="currencyCode"                  
      (valueChange)="objectCurrencyChanged.emit($event)"
      [id]="'currencyCode'">
                  
        <mat-option *ngFor="let item of currencyList" [value]="item.currency">
          {{ item.currency }}
        </mat-option>
      </mat-select>

      <div class="icon-container">
        <fa-icon [icon]="['fas', 'sack-dollar']"></fa-icon>
      </div>
      <mat-form-field *ngIf="currentState" appearance="outline" floatLabel="always" class="p-form-field-outline">
        <mat-label>Budget</mat-label>
        <input matInput
          [(ngModel)]="currentState.sourceAmount"
          [ngModelOptions]="{ standalone: true }"
          readonly
          class="text-left clickable-input"
          currencyMask
          [options]="currencyMaskOptions"
          (click)="onAmountClick.emit()"
          [id]="'currencyValue'"/>

          <mat-hint *ngIf="currentState?.currencyCode !== companyCurrencyCode" align="end">
            <span class="mr-1">{{ companyCurrencyCode }}:</span>
            <span>{{ currentState?.amount | formatBudget }}</span>
          </mat-hint>
      </mat-form-field>
    </div>

    <!-- Status -->
    <div class="details-form-field w-50">
      <div class="icon-container">
        <fa-icon [icon]="['fas', 'wave-pulse']" class="size-17"></fa-icon>
      </div>
      <amount-status-switch
        [isReadOnlyMode]="isReadOnlyMode"
        [parentCampaignIsCommitted]="parentCampaignIsCommitted"
        [statusFormControl]="formData.controls[DrawerFormFields.amountStatus]"
        (onChangeAmountState)="onChangeAmountState($event)"
        [id]="'amountStatus'"
      ></amount-status-switch>
    </div>
  </div>

  <div class="details-panel">
    <!--Owner-->
    <div class="details-form-field w-50">
      <div class="icon-container">
        <fa-icon [icon]="['fas', 'user-crown']"></fa-icon>
      </div>
      <mat-form-field [id]="'campOwner'" appearance="outline" class="p-form-field-outline">
        <mat-label>Owner</mat-label>
        <mat-select
          [placeholder]="'Owner *'"
          formControlName="ownerId"
          required
          (selectionChange)="handleOwnerChange.emit($event)"
          [panelClass]="'reset-panel-width'"
        >
          <mat-option *ngFor="let item of ownerOptions" [value]="item.id">
            {{item.name}}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="formData.controls['ownerId'].hasError('required')">
          Owner is required
        </mat-error>
      </mat-form-field>
    </div>
    <!--Source-->
    <drawer-read-only-input
      class="details-form-field w-50"
      inputTitle="Source"
      iconRotation="90"
      icon="plug"
      iconType="fas"
      [objectSource]="currentState?.source"
      [id]="'campSource'"
    ></drawer-read-only-input>

    <!--Campaign Type-->
    <div class="details-form-field w-50 ">
      <div class="icon-container">
        <fa-icon [icon]="['fas', 'rocket-launch']"></fa-icon>
      </div>

      <disabled-flag-mat-select
        class="p-form-field-outline no-validate"
        [labelName]="'Campaign Type'"
        [isRequired]="true"
        [formData]="formData"
        [optionTitle]="['name']"
        [control]="'typeId'"
        [addNullOption]="false"
        [value]="formData.controls['typeId'].value"
        [itemList]="objectTypes"
        [id]="'campType'"
      ></disabled-flag-mat-select>
    </div>
    
    <!--GL Code-->
    <div class="details-form-field w-50">
      <div class="icon-container">
        <fa-icon [icon]="['fas', 'barcode-alt']" class="size-17"></fa-icon>
      </div>
      <disabled-flag-mat-select
        class="p-form-field-outline no-validate"
        [labelName]="'GL Code'"
        [isRequired]="false"
        [formData]="formData"
        [optionTitle]="['name', 'description']"
        [control]="'glCode'"
        [addNullOption]="true"
        [nullOptionTitle]="'No GL Code'"
        [value]="formData.controls['glCode'].value"
        [itemList]="glCodes"
        [id]="'campGLCode'"
      ></disabled-flag-mat-select>
    </div>


    <!--Vendor Name-->
    <div class="details-form-field w-50">
      <div class="icon-container">
        <fa-icon [icon]="['fas', 'user-hard-hat']" class="size-22"></fa-icon>
      </div>
      <disabled-flag-mat-select
        class="p-form-field-outline no-validate"
        [labelName]="'Vendor Name'"
        [isRequired]="false"
        [formData]="formData"
        [optionTitle]="['name']"
        [control]="'vendorId'"
        [addNullOption]="true"
        [nullOptionTitle]="'No Vendor'"
        [value]="formData.controls['vendorId'].value"
        [itemList]="vendors"
        [id]="'campVendorName'"
      ></disabled-flag-mat-select>
    </div>

    <!--Campaign ID-->
    <div class="details-form-field w-50 uniq-id">
      <unique-id
        *ngIf="currentState?.externalId"
        [uniqueId]="currentState.externalId"
        [objectType]="objectType"
        [id]="'campID'">
      </unique-id>
    </div>
  </div>

  <!-- Custom Fields -->
  <!-- TODO: remove the objectId non-null check from CF after Create API handles this -->
  <div class="details-panel custom-fields-container" *ngIf="isCustomFieldsEnabledForCG && customFieldConfigs.length">
    <h2 class="panel-floating-title">Custom Fields</h2>
    <div class="custom-field-el" *ngFor="let config of customFieldConfigs; let i = index">
      <custom-field-el
        [config]="config"
        [control]="form.controls['dropdown_' + i]" 
        (stateChanged)="onDropdownStateChanged($event, i)"
        (selectDropdownClosed)="onSelectDropdownClosed($event, 'dropdown_' + i)"
        >
      </custom-field-el>
    </div>
  </div>


  <div class="details-panel area-holder">

    <div class="details-form-field w-100">
      <div class="icon-container">
        <fa-icon [icon]="['fas', 'user-astronaut']"></fa-icon>
      </div>
      <mat-form-field appearance="outline" class="p-form-field-outline no-validate textarea w-100 autosize" [id]="'campTarget'">
        <mat-label>Target Audience</mat-label>
        <textarea matInput formControlName="targetAudience" textFieldAutosize></textarea>
      </mat-form-field>
    </div>

    <div class="details-form-field w-100">
      <div class="icon-container">
        <fa-icon [icon]="['fas', 'pen']" class="size-17"></fa-icon>
      </div>
      <mat-form-field appearance="outline" class="p-form-field-outline no-validate textarea w-100 autosize" [id]="'campMessaging'">
        <mat-label>Campaign Messaging</mat-label>
        <textarea matInput formControlName="messaging" textFieldAutosize></textarea>
      </mat-form-field>
    </div>
  </div>

  <div class="details-panel">
    <!--Tags-->
    <div class="details-form-field w-100">
      <div class="icon-container">
        <fa-icon [icon]="['fas', 'tags']" class="size-17"></fa-icon>
      </div>
      <tags-control
        #tagsControl
        class="mb-1"
        fieldAppearance="outline"
        [tagsList]="currentState?.tagMappings"
        [autocompleteItems]="tagsAutocompleteItems"
        [editOnly]="true"
        [editForbidden]="isReadOnlyMode"
        (onAddTag)="addTag($event)"
        (onCreateTag)="createTag($event)"
        (onRemoveTag)="removeTag($event)"
        [id]="'campTags'"
      >
      </tags-control>
    </div>
    <!--Attachments-->
    <div class="details-form-field attachments w-100 mb-1">
      <div class="icon-container">
        <fa-icon [icon]="['far', 'paperclip']"></fa-icon>
      </div>
      <attachments-list
        [disableInput]="!currentState?.objectId"
        [objectTypeName]="objectType"
        [isReadOnlyMode]="isReadOnlyMode"
        [showAddIcon]="false"
        [attachments]="attachmentsManager.attachments"
        (onAttachFiles)="handleFileAttached.emit($event)"
        (onDeleteAttachment)="handleFileDelete.emit($event)"
        (onDownloadAttachment)="handleFileDownload.emit($event)"
        [id]="'campAttachments'"
      ></attachments-list>
    </div>
    <!--Notes-->
    <div class="details-form-field w-100 mb-1">
      <div class="icon-container">
        <fa-icon [icon]="['fas', 'clipboard']"></fa-icon>
      </div>
      <linkified-text formControlName="notes" label="Notes" appearance="outline" [id]="'campNotes'"></linkified-text>
    </div>
  </div>

  <div class="details-panel">
    <h2 class="panel-floating-title">Tasks</h2>
    <!-- isReadOnlyMode -->
    <tasks-list
      [owners]="ownerOptions"
      [currentCompanyUser]="currentCompanyUser"
      [tasks]="currentState?.tasks"
      [budgetTodayDate]="budgetTodayDate"
      [isReadOnlyMode]="isReadOnlyMode"
      [isDrawer]="true"
      (onDataChanged)="handleTasksUpdate.emit($event)"
      [id]="'campTasks'"
      #tasksList
    ></tasks-list>
  </div>
</div>
