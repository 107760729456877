import { BudgetCegTimelineService } from '@manage-ceg/services/budget-ceg-timeline.service';
import { BudgetDataService } from 'app/dashboard/budget-data/budget-data.service';
import { ChangeDetectionStrategy, Component, inject, Input, OnDestroy, OnInit } from '@angular/core';
import {
  BudgetTimeframeBrief,
  CreateCegItemTemplateEvent,
  ManageCegBudgetMode,
  ManageCegDataMode,
  ManageCegTableRow, ManageCegTableRowPerformance,
  ManageCegViewMode,
  ManageTableDataConfig,
  ManageTableTotalValues,
  PresentationTimeframe,
  ManageTableViewConfig,
  TotalsSidebarState,
  PerformanceColumnData,
  TableConfigKeys,
  ManageTableViewConfigSections
} from '@manage-ceg/types/manage-ceg-page.types';
import { ManageCegPageModeService } from '@manage-ceg/services/manage-ceg-page-mode.service';
import { Configuration } from 'app/app.constants';
import { AppRoutingService } from '@shared/services/app-routing.service';
import { ManageCegPageService } from '@manage-ceg/services/manage-ceg-page.service';
import { slideFromRightChartAnimations } from '@manage-ceg/constants/manage-ceg-page.constants';
import { TabSwitchOption } from '@shared/components/tab-switch/tab-switch.types';
import { BudgetTimeframesType } from '@shared/types/budget.interface';
import { Observable, Subscription } from 'rxjs';
import { ManageCegTableDataService } from '@manage-ceg/services/manage-ceg-table-data.service';
import { ManageCegTableConfigurationService } from '@manage-ceg/services/manage-ceg-table-configuration.service';
import { BudgetObjectType } from '@shared/types/budget-object-type.interface';
import { ManageTableActionHistoryService } from '@shared/services/manage-table-action-history.service';
import { BudgetAllocationAction } from '../../../budget-allocation/budget-allocation-gestures-actions/budget-allocation-action.types';
import { BudgetTimeframeDataItem } from 'app/dashboard/widget.service';
import { ManageTableRowType } from '@shared/enums/manage-table-row-type.enum';
import { ManageTableBudgetColumn, ManageTableBudgetColumnName } from '../../types/manage-ceg-page.types';
import { budgetAllocationColumns } from '../../constants/manage-ceg-page.constants';
import { ForecastBudgetService } from 'app/budget-settings/services/forecast-budget.service';

@Component({
  selector: 'manage-ceg-table-container',
  templateUrl: './manage-ceg-table-container.component.html',
  styleUrls: ['./manage-ceg-table-container.component.scss'],
  animations: [slideFromRightChartAnimations],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ManageCegTableContainerComponent implements OnInit, OnDestroy {
  @Input() dataMode: ManageCegDataMode;

  private readonly configuration = inject(Configuration);
  private readonly appRoutingService = inject(AppRoutingService);
  private readonly managePageModeService = inject(ManageCegPageModeService);
  private readonly tableConfigurationService = inject(ManageCegTableConfigurationService);
  private readonly managePageService = inject(ManageCegPageService);
  private readonly tableDataService = inject(ManageCegTableDataService);
  private readonly budgetCegTimelineService = inject(BudgetCegTimelineService);
  public readonly budgetDataService = inject(BudgetDataService);
  private readonly historyManager: ManageTableActionHistoryService<BudgetAllocationAction<any>> = inject(ManageTableActionHistoryService);
  private readonly forecastBudgetService = inject(ForecastBudgetService);


  public companyCurrency$: Observable<{ code: string; symbol: string }>;
  protected isLoading$: Observable<boolean> = this.tableDataService.isLoading$;
  protected readonly dataModes = ManageCegDataMode;
  protected readonly displayMode = ManageCegBudgetMode;

  protected readonly createMenuOptions = [
    {
      label: this.configuration.OBJECT_TYPES.campaign,
      icon: ['far', 'rocket-launch'],
      action: () => this.appRoutingService.openCampaignCreation()
    },
    {
      label: 'Expense Group',
      icon: ['fas', 'briefcase'],
      action: () => this.appRoutingService.openProgramCreation()
    },
    {
      label: this.configuration.OBJECT_TYPES.goal,
      icon: ['fas', 'bullseye-arrow'],
      action: () => this.appRoutingService.openGoalCreation()
    }
  ];
  protected readonly routeActionByRowType: Record<string, Function> = {
    [ManageTableRowType.Goal]: (id: number) => this.appRoutingService.openGoalDetails(id),
    [ManageTableRowType.Campaign]: (id: number) => this.appRoutingService.openCampaignDetails(id),
    [ManageTableRowType.ExpenseGroup]: (id: number) => this.appRoutingService.openProgramDetails(id),
  };
  protected readonly iconByRowType = this.configuration.iconsConfig;
  protected tableViewConfig$ = this.tableConfigurationService.tableViewConfiguration$;
  protected presentationTimeframeMode$: Observable<BudgetTimeframesType> = this.managePageModeService.presentationTimeframeMode$;
  protected presentationTimeframeIds$: Observable<(number | string)[]> = this.managePageModeService.presentationTimeframeIds$;
  protected grandTotalBudget$: Observable<Record<PresentationTimeframe, ManageTableTotalValues>> = this.tableDataService.grandTotalBudget$;
  protected grandTotalPerformance$: Observable<ManageCegTableRowPerformance> = this.tableDataService.grandTotalPerformance$;
  protected budgetTimeframes$: Observable<BudgetTimeframeBrief[]> = this.managePageService.budgetTimeframes$;
  protected timelineGraphData$: Observable<BudgetTimeframeDataItem[]> = this.budgetCegTimelineService.timelineData;
  protected manageTableDataConfig: ManageTableDataConfig;
  private forecastSubscription: Subscription  = null;

  ngOnInit() {
    this.manageTableDataConfig = this.getTableConfiguration();
    this.forecastSubscription = this.forecastBudgetService.forecastEnabled$.subscribe(_ => { this.handleManagePageConfigurationUpdateOnForecastState() });
    this.companyCurrency$ = this.budgetCegTimelineService.companyCurrency$;
    this.handleTotalText(this.viewMode);
  }

  protected get rowsData(): ManageCegTableRow[] {
    return this.tableDataService.rows;
  }

  protected get performanceColumnData(): PerformanceColumnData {
    return this.tableDataService.performanceColumnData;
  }

  protected get allRootRowsLoaded(): boolean {
    return this.tableDataService.allRootRowsLoaded;
  }

  public budgetColumns: ManageTableBudgetColumn[] = [
    budgetAllocationColumns[ManageTableBudgetColumnName.Budget],
    budgetAllocationColumns[ManageTableBudgetColumnName.Forecast],
    budgetAllocationColumns[ManageTableBudgetColumnName.Actual],
    budgetAllocationColumns[ManageTableBudgetColumnName.Committed],
    budgetAllocationColumns[ManageTableBudgetColumnName.Planned],
    budgetAllocationColumns[ManageTableBudgetColumnName.CommittedAndPlanned],
    budgetAllocationColumns[ManageTableBudgetColumnName.Available],
  ];

  public handleManagePageConfigurationUpdateOnForecastState() {
    this.manageTableDataConfig = this.getTableConfiguration();
      
      const viewConfig = { segmentBreakdownData: {}, columns: {}, columnsFormula: false };
      Object.keys(this.manageTableDataConfig).forEach(rootKey => {
        const result = {};
        if (Array.isArray(this.manageTableDataConfig[rootKey])) {
          this.manageTableDataConfig[rootKey].forEach(item => {
            const { key, value } = item;
            result[key] = value || false;
            if (key === TableConfigKeys.columnsFormula) {
              viewConfig[key] = value || false;
            }
          });
          viewConfig[rootKey] = result;
        }
     });

    if (viewConfig[ManageTableViewConfigSections.columns][TableConfigKeys.separate]) {
      viewConfig[ManageTableViewConfigSections.columns][TableConfigKeys.committedAndPlanned] = false;
    }

     this.setConfiguration(viewConfig as ManageTableViewConfig);

  }
  public get isFilteredMode(): boolean {
    return this.tableDataService.isFilteredMode;
  }

  public get hasHiddenHierarchy(): boolean {
    return this.tableDataService.hasHiddenHierarchy;
  }

  get viewMode(): ManageCegViewMode {
    return this.managePageModeService.viewMode;
  }

  get budgetDisplayMode(): ManageCegBudgetMode {
    return this.managePageModeService.budgetDisplayMode;
  }

  get presentationTimeframeOptions(): TabSwitchOption[] {
    return this.managePageModeService.presentationTimeframeOptions;
  }

  get isAdmin(): boolean {
    return this.managePageService.isAdmin;
  }

  public get editPermission(): boolean {
    return this.managePageService.editPermission;
  }

  get historySize(): number {
    return this.historyManager.historySize;
  }

  get budgetEndDate(): string {
    return this.budgetDataService.selectedBudgetSnapshot?.budget_to;
  }

  handleViewModeChange(viewMode: ManageCegViewMode): void {
    this.managePageModeService.viewMode = viewMode;
    this.handleTotalText(viewMode);
  }

  handleTotalText(viewMode: ManageCegViewMode): void {    
    if(viewMode === 'campaigns') {
      this.budgetColumns = [...this.budgetColumns];        
      this.budgetColumns[0] = { ...this.budgetColumns[0], label: ['Total', 'Campaign', 'Budget'] };
    } else {
      this.budgetColumns = [...this.budgetColumns];        
      this.budgetColumns[0] = { ...this.budgetColumns[0], label: ['Total', 'Budget'] };
    }
  }

  handleBudgetDisplayModeChange(mode: ManageCegBudgetMode): void {
    this.managePageModeService.budgetDisplayMode = mode;
  }

  setConfiguration(e: ManageTableViewConfig): void {
    this.tableConfigurationService.setTableViewConfiguration(e);
  }

  get objectTypeNameMap(): Record<number, string> {
    return this.managePageService.objectTypeNameMap;
  }

  get totalsSidebarState(): TotalsSidebarState {
    return this.managePageModeService.totalsSidebarState;
  }

  set totalsSidebarState(state: TotalsSidebarState) {
    this.managePageModeService.totalsSidebarState = state;
  }

  protected createItemFromTemplate(name: string): void {
    this.managePageService.saveNewItemTemplateWithName(name);
  }

  protected createNewItemTemplate(createItemTemplateEvent: CreateCegItemTemplateEvent): void {
    this.managePageService.createNewItemTemplate(createItemTemplateEvent);
  }

  protected setPresentationTimeframe(tfMode: string): void {
    this.managePageModeService.setPresentationTimeframeMode(tfMode as BudgetTimeframesType, []);
  }

  protected setTimeframeConfiguration(ids: (number | string)[]): void {
    this.managePageModeService.setPresentationTimeframeIds(ids);
  }

  getTableConfiguration(): ManageTableDataConfig {
    return this.tableConfigurationService.getTableViewConfiguration();
  }

  protected handleUndoClick(): void {
    this.historyManager.undo();
  }

  public get budgetObjectTypes(): BudgetObjectType[] {
    return this.managePageService.budgetObjectTypes;
  }

  ngOnDestroy(): void {
    this.forecastSubscription?.unsubscribe();
  }
}
