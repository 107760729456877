import { BudgetStatus } from './budget-status.type';

export interface Budget { // TODO: later rename properties to follow ts naming conventions, not Python ones!!!
  id: number;
  name: string;
  type: string;
  company: number;
  owner: number;
  budget_from: string;
  budget_to: string;
  mailing: boolean;
  status: BudgetStatus;
  suppress_timeframe_allocations?: boolean;
  have_locked_timeframe: boolean;
  fixed_date?: string;
  amount: number;
  crd: string;
  upd: string;
  new_campaigns_programs_structure?: boolean;
  is_forecast_enabled?: boolean;
}

export enum BudgetTimeframesType {
  Year = 'Year',
  Month = 'Month',
  Quarter = 'Quarter'
}
