import { Injectable, inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, Subject, catchError, map, of, switchMap } from 'rxjs';
import { getDiff } from 'recursive-diff';
import { getRequestOptions } from '@shared/utils/http-request.utils';
import { API_V2_URL } from '@common-lib/lib/injection-tokens/url.tokens';
import { FormGroup } from '@angular/forms';
import { DIALOG_ACTION_TYPE, DialogAction } from '@shared/types/dialog-context.interface';
import { BudgetObjectDialogService } from '@shared/services/budget-object-dialog.service';
import { ParentCustomFieldInheritanceAction } from '@shared/types/segment-data-inheritance.interface';

export interface CustomFieldListDO {
  id: number;
  name: string;
  is_mandatory: boolean;
  type: string;
  strict_inheritance: boolean;
  sort_order: number;
  options: CustomFieldOptionDO[];
  selected_values: Record<string, string>[];
  description?: string;
}

export interface CustomFieldListResponse {
  custom_fields: CustomFieldListDO[];
}

export interface CustomFieldStateConfig {
  id: number;
  cfName: string;
  defaultValue?: string | string[];
  options: string[];
  isMultiSelect: boolean;
  required: boolean;
  selectedValue: string | string[];
  optionValueIdMapping: Record<string, CustomFieldOptionDO>;
  description?: string;
  allowNoSelection?: boolean;
}

export interface CustomFieldOptionDO { 
  id: number;
  option_value: string;
  is_default: boolean;
  sort_order: number;
}

export interface CustomFieldStatusAndCountDO {
  isCFEnabledForCampaign: boolean;
  isCFEnabledForProgram: boolean;
  isCFEnabledForExpense: boolean;
  isCustomFieldEnabled: boolean;
  cfCamapaignCount: number;
  cfProgramCount: number;
  cfExpenseCount: number;
}

export interface CustomFieldStatusReponsePayload {
  is_enabled: boolean;
  cf_campaign_count: number;
  cf_program_count: number;
  cf_expense_count: number;
}

export interface CFCampaignDetailsContext {
   isCFEnabledForCampaign: boolean,
   customFieldsStateDiff: Record<number, string[]>
}

export interface CFProgramDetailsContext {
  isCFEnabledForProgram: boolean,
  customFieldsStateDiff: Record<number, string[]>
}

export interface CFExpenseDetailsContext {
  isCFEnabledForExpense: boolean,
  customFieldsStateDiff: Record<number, string[]>
}

export interface ParentCustomFieldConfirmationContext {
  title: string;
  keepAllowed: boolean;
  multiUpdate: boolean;
  parentName: string;
  objectName: string;
}


@Injectable({
  providedIn: 'root'
})
export class CustomFieldsService {
  private readonly apiV2Url = inject(API_V2_URL);
  private readonly dialogManager = inject(BudgetObjectDialogService);
  customFieldsStatusAndCount: BehaviorSubject<CustomFieldStatusAndCountDO> = new BehaviorSubject<any>(null);
  private readonly _applyCFInheritanceToAllChildren = new Subject<{ id: number, applyToAllChildren: boolean, action: 'Keep' | 'Replace' }>();
  applyCFInheritanceToAllChildren$ = this._applyCFInheritanceToAllChildren.asObservable();
  private updateStateSubject = new Subject<void>();
  updateState$ = this.updateStateSubject.asObservable();

  public apiPaths = {
    customFieldsList: 'custom_fields/consumer_cf_list/',
    customFieldsCFInheritanceValues: 'custom_fields/cf_inheritance_values/',
  }

  constructor(private http: HttpClient) {}

  fetchDropdownOptions(companyId: number, map_id: number, mapping_type: string) {    
    return this.http.get(this.apiV2Url + 'custom_fields/consumer_cf_list/', getRequestOptions({
      company: companyId, map_id,mapping_type
    })).pipe(
      switchMap((response: CustomFieldListResponse) => {
        return of(this.convertCFListToStateConfig(response));
      })
    );
  }

  fetchCustomFieldInheritanceValues(companyId: number, map_id: number, mapping_type: string, target_type: string) {
    return this.http.get(this.apiV2Url + this.apiPaths.customFieldsCFInheritanceValues, getRequestOptions({
      company: companyId, map_id, mapping_type, target_type
    }))
  }

  getStateDiff(prevCFState: any, currentCFState: any, excludeKeys: string[] = []): Record<string, any>{
    let diffMap = {};
    let diffResults = getDiff(prevCFState, currentCFState)
    
    diffResults.forEach(({ op, path, val }) => {
      const stateKey = path[0] as string;
      if (!excludeKeys.includes(stateKey)) {
        diffMap[stateKey] = currentCFState[stateKey];
      }
    });
    return diffMap;
  }

  convertCFListToStateConfig(responseData: CustomFieldListResponse) {
    
  const configData = responseData.custom_fields.map(
    cf => {
        let defaultOptions = cf.options.filter(o => o.is_default);
        let defaultValue;
        if(defaultOptions.length) {
            const defaultValueForCF =  defaultOptions[0].option_value;
            defaultValue = cf.type === 'Multi' ? [ defaultValueForCF ] : defaultValueForCF;
        }
        const optionValueIdMapping = cf.options.reduce((acc, op) => {
          return { ...acc, [op.option_value]: op  }
      }, {});
      
      let selectedOptionValue;
      let selectedValues = cf.selected_values.flatMap(v => Object.values(v));
      if(cf.type === 'Multi') {
        selectedOptionValue = selectedValues
      }else {
        selectedOptionValue = selectedValues.length ? selectedValues[0] : undefined;
      }
        return ({
            id: cf.id,
            cfName: cf.name,
            defaultValue: defaultValue,
            options: cf.options.map(o => o.option_value),
            isMultiSelect: cf.type === 'Multi',
            required: cf.is_mandatory,
            selectedValue: selectedOptionValue,
            optionValueIdMapping: optionValueIdMapping,
            description: cf.description,
            allowNoSelection: cf.type !== 'Multi' && !cf.is_mandatory,
        })
    }
  );

  return configData;
  }

  private processCustomFieldData(data: CustomFieldStatusReponsePayload): CustomFieldStatusAndCountDO {
    return {
      isCustomFieldEnabled: data.is_enabled,
      isCFEnabledForCampaign: data.is_enabled && data.cf_campaign_count > 0,
      isCFEnabledForProgram: data.is_enabled && data.cf_program_count > 0,
      isCFEnabledForExpense: data.is_enabled && data.cf_expense_count > 0,
      cfCamapaignCount: data.cf_campaign_count,
      cfProgramCount: data.cf_program_count,
      cfExpenseCount: data.cf_expense_count
    };
  }  

  fetchCustomFieldStatusForSelectedCompany(companyId: number) {
    this.http.get(`${this.apiV2Url}` + 'custom_fields/status/', getRequestOptions({ company: companyId })).pipe(
      catchError(error => {
        console.error(`Failed to fetch CF status for Company ${companyId}: `, error);
        return of(null);
      })
    ).subscribe((data: CustomFieldStatusReponsePayload) => {
      const customFieldStatusAndCountDO = this.processCustomFieldData(data);
      this.customFieldsStatusAndCount.next(customFieldStatusAndCountDO);
    })
  }

  setApplyToAllChildren(value: {id: number, applyToAllChildren: boolean, action: 'Keep' | 'Replace'}) {
    this._applyCFInheritanceToAllChildren.next(value);
  }


  triggerUpdateSelectAllState() {
    this.updateStateSubject.next();
  }

  getCFStatus(): Observable<CustomFieldStatusAndCountDO> {
    return this.customFieldsStatusAndCount.asObservable()
  }

  getCFStatusForActivationGuard(companyId: number): Observable<CustomFieldStatusAndCountDO | null> {
    return this.http.get(`${this.apiV2Url}custom_fields/status/`, { params: { company: companyId.toString() } }).pipe(
      map((data: CustomFieldStatusReponsePayload) => {
        const customFieldStatusAndCountDO = this.processCustomFieldData(data);
        this.customFieldsStatusAndCount.next(customFieldStatusAndCountDO);
        return customFieldStatusAndCountDO;
      }),
      catchError(error => {
        console.error(`Failed to fetch CF status for Company ${companyId}: `, error);
        this.customFieldsStatusAndCount.next(null);
        return of(null);
      })
    );
  }

  getSelectedOptionsIdPayloadForCF(diffMap: Record<string, any>, customFieldConfigs: CustomFieldStateConfig[]) {

    let data: Record<any, any> = {};
    if(customFieldConfigs.length === 0) {
      return {};
    }
    Object.keys(diffMap).forEach(k => {
      let configIndex = k.split('_')[1];
      let optionValuePreferenceAndKeys = [diffMap[k]].flat()
      let optionValueKeys = optionValuePreferenceAndKeys.map(v => v?.selectedValueForCF);
      let currentCFConfig = customFieldConfigs[configIndex]
      let val = optionValueKeys.flat().reduce((acc, key) => {
        return [...acc, currentCFConfig.optionValueIdMapping[key]];
      }, []);
      // Filtering out null from single select options signifying no selection 
      data = { 
        ...data,
         [currentCFConfig.id]:  {
          allow_inheritance: optionValuePreferenceAndKeys[0].triggerInheritance,
          options : [val].flat().map(v => v?.id || null).filter(id => id !== null) 
      }
    }
    });
   
    return data;
  }

  initDefaultCustomFieldsFormData(objectId: number, form: FormGroup<any>,) {
    // This signifies creation of new artifact CG, EG, Expense
    if(!objectId) {
      let initialFormState = form.getRawValue();
      let initialDropdownState: Record<string, any[]> = {}
      for(let key in initialFormState) {
        initialDropdownState[key] = Array.isArray(initialFormState[key]) ? []:  null;
      }
      return initialDropdownState;
    }
    return null;
  }

  rearrangeSelectedOptionValues(selectedValues, optionValues) {
    // Filter out the selectedValues from optionValues
    const nonSelectedValues = optionValues.filter(id => !selectedValues.includes(id));
    
    // Combine the selectedValues followed by the nonSelectedValues
    return [...selectedValues, ...nonSelectedValues];
  }

  getOptionValuesByIds(ids: number[], records: Record<string, any>) {
    const result = Object.entries(records)
                    .filter(([_, value]) => ids.includes(value.id)) // Check if the id exists in the provided list
                    .map(([_, value]) => value.option_value); // Extract the option_value

    return result;
  }

  public handleCustomFieldInheritanceOnParentChange(objectType: string,  parentType: string, context: ParentCustomFieldConfirmationContext) : Observable<ParentCustomFieldInheritanceAction> {
    const isCustomFieldInheritancePopupApplicable = this.isCustomFieldEnabledForObjectType(parentType) && this.isCustomFieldEnabledForObjectType(objectType)

    if(isCustomFieldInheritancePopupApplicable) {
      return this.requestParentInheritanceConfirmation(objectType, parentType, context);    
    }

    return of(null);

  }

  public isCustomFieldEnabledForObjectType(objectType: string) {
    const status = this.customFieldsStatusAndCount.value;
    switch(objectType) {
      case 'Campaign':
        return status?.isCFEnabledForCampaign;
      case 'Program':
        return status?.isCFEnabledForProgram;
      case 'Expense':
        return status?.isCFEnabledForExpense;
      default:
        return false;
    }
  }


  public requestInheritancePropagationConfirmation(context: ParentCustomFieldConfirmationContext, actions ?: Record<string, () => void>): Observable<ParentCustomFieldInheritanceAction> {
    return new Observable(observer => {
      const cancelAction: DialogAction = {
        label: 'Cancel',
        handler: () => {
          observer.next(ParentCustomFieldInheritanceAction.None);
          observer.complete();
        },
        type: DIALOG_ACTION_TYPE.STROKED
      };

      const keepAction: DialogAction = {
        label: 'Update',
        handler: () => {
          observer.next(ParentCustomFieldInheritanceAction.Keep);
          observer.complete();
        },
        type: DIALOG_ACTION_TYPE.FLAT
      };

      const modalActions = context.keepAllowed ? [cancelAction, keepAction] : [cancelAction];
      // const modalText = this.getConfirmationMessage(context);
      const modalText = this.getCFInheritancePropogationConfirmationMessage();

      this.dialogManager.openConfirmationDialog({
        title: context.title,
        content: modalText,
        actions: modalActions
      });
    });
  }

  getCFInheritancePropogationConfirmationMessage() {
    return `Updating this value will update all associated child values.`
  }

  public requestParentInheritanceConfirmation(objectType: string, parentType: string, context: ParentCustomFieldConfirmationContext): Observable<ParentCustomFieldInheritanceAction> {
    return new Observable(observer => {
      const keepAction: DialogAction = {
        label: 'Keep',
        handler: () => {
          observer.next(ParentCustomFieldInheritanceAction.Keep);
          observer.complete();
        },
        type: DIALOG_ACTION_TYPE.FLAT
      };
      const replaceAction: DialogAction = {
        label: 'Replace',
        handler: () => {
          observer.next(ParentCustomFieldInheritanceAction.Replace);
          observer.complete();
        },
        type: DIALOG_ACTION_TYPE.FLAT
      };

      // const modalActions = context.keepAllowed ? [cancelAction, keepAction, replaceAction] : [cancelAction, replaceAction];
      const modalActions = context.keepAllowed ? [keepAction, replaceAction] : [replaceAction];
      // const modalText = this.getConfirmationMessage(context);
      objectType = this.getObjectTypeName(objectType);
      parentType = this.getObjectTypeName(parentType);

      const modalText = this.getCFInheritanceParentChangeConfirmationMessage({objectType, parentType});

      this.dialogManager.openConfirmationDialog({
        title: context.title,
        content: modalText,
        actions: modalActions,
        parentChangeInheritance: objectType !== 'Expense' // No need for apply to all children checkbox for Expense
      });
    });
  }

  private getCFInheritanceParentChangeConfirmationMessage({ objectType, parentType}: { objectType: string, parentType: string }) {  
    return `
    The selected ${parentType} may have different Custom Field values than this ${objectType}.
    <br><br>
    Would you like to keep the ${objectType + "'s"} current values or replace it with the new parent values?
     ${ objectType !== 'Expense' ? "<br><br>" : '<br>'}`
  }

  private getObjectTypeName(objectType: string) {
    switch(objectType) {
      case 'Campaign':
        return 'Campaign';
      case 'Program':
        return 'Expense Group';
      case 'Expense':
        return 'Expense';
      default:
        return '';
    }
  }


}