<h4 class="pl-modal-title">Planned Budget Details</h4>
<div class="form-container" [formGroup]="form">
  <div class="w-100">
    <div class="form-field-wrap">
      <div class="icon-container">
        <fa-duotone-icon [icon]="['fad', 'sack-dollar']" [size]="'2x'"></fa-duotone-icon>
      </div>
      <mat-form-field appearance="outline" class="p-form-field-outline">
        <mat-label>Budget Name*</mat-label>
        <input matInput formControlName="name" trim>
        <mat-error *ngIf="form.controls['name'].hasError('required')">
          Name is required for budget!
        </mat-error>
        <mat-error *ngIf="form.controls['name'].hasError('maxlength')">
          Name can't be longer than {{ MAX_TEXT_INPUT_LENGTH }} characters!
        </mat-error>
        <mat-error *ngIf="form.controls['name'].hasError('trim')">
          Name can't be empty!
        </mat-error>
      </mat-form-field>
    </div>
  </div>

  <div class="w-50">
    <div class="form-field-wrap">
      <div class="icon-container">
        <fa-duotone-icon [icon]="['fad', 'user-crown']" [size]="'2x'"></fa-duotone-icon>
      </div>
      <mat-form-field appearance="outline" class="p-form-field-outline">
        <mat-label>Owner</mat-label>
        <mat-select formControlName="owner">
          <mat-option *ngFor="let owner of data.owners" [value]="owner.id">
            {{owner.title}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>

  <div class="w-50">
    <div class="form-field-wrap">
      <div class="icon-container">
        <fa-duotone-icon [icon]="['fad', 'piggy-bank']" [size]="'2x'"></fa-duotone-icon>
      </div>
      <mat-form-field appearance="outline" class="p-form-field-outline">
        <mat-label>Budget Amount({{ data.companyCurrency }})</mat-label>
        <input matInput
               formControlName="amount"
               currencyMask
               [options]="currencyMaskOptions"/>
      </mat-form-field>
    </div>
  </div>

  <div class="w-50">
    <div class="form-field-wrap">
      <div class="icon-container">
        <fa-duotone-icon [icon]="['fad', 'hourglass-half']" [size]="'2x'"></fa-duotone-icon>
      </div>
      <mat-form-field appearance="outline" class="p-form-field-outline">
        <mat-label>Timeframe Type</mat-label>
        <mat-select formControlName="type">
          <mat-option *ngFor="let type of timeframeTypes" [value]="type.id">
            {{type.title}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>

  <div class="w-50">
    <div class="form-field-wrap">
      <div class="icon-container">
        <fa-duotone-icon [icon]="['fad', 'calendar-day']" [size]="'2x'"></fa-duotone-icon>
      </div>
      <mat-form-field appearance="outline" class="p-form-field-outline">
        <mat-label>Fiscal Year Start Date</mat-label>
        <input matInput [matDatepicker]="pickerStartDate"
               formControlName="startDate"
               autocomplete="off"
               (focus)="$event.target.select()"
               (mousedown)="pickerStartDate.open()"/>
        <mat-datepicker #pickerStartDate></mat-datepicker>
      </mat-form-field>
    </div>
  </div>

  <div class="toggle-holder" *ngIf="data?.isPlanfulUser">
    <mat-slide-toggle
      formControlName="cegStatusesEnabled"
      disableRipple="true"
    >Enable Campaigns and Expense Group statuses</mat-slide-toggle>
  </div>

  <!-- Forecast Toggle Budget settings -->
  <div class="forecast-toggle-holder" *ngIf="showForecastToggle">
    <mat-slide-toggle
      formControlName="forecastEnabled"
      disableRipple="true"
    >Forecast</mat-slide-toggle>
    <fa-icon 
      [icon]="['fas', 'info-circle']" 
      class="forecast-info-icon" 
      [matTooltip]="forecastTooltip"
      matTooltipClass="dark-theme-tooltip above multi-line"
      matTooltipPosition="above"
      matTooltipShowDelay="300"
      ></fa-icon>
  </div>

</div>

<div class="d-flex jc-end">
  <button mat-button
          color="primary"
          class="mr-1"
          (click)="close()">Cancel</button>
  <button mat-flat-button
          color="primary"
          (click)="save()"
          [disabled]="form.invalid">{{ editMode ? 'Save Edits' : 'Create Budget' }}</button>
</div>
