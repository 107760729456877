import { Component, Input, Output, EventEmitter, inject, ViewChild, OnInit, SimpleChange } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { CampaignDetailsState } from 'app/budget-object-details/types/budget-object-details-state.interface';
import { HierarchySelectConfig, HierarchySelectItem } from '@shared/components/hierarchy-select/hierarchy-select.types';
import { MatSelectChange } from '@angular/material/select';
import { SelectOption } from '@shared/types/select-option.interface';
import { BudgetObjectType } from '@shared/types/budget-object-type.interface';
import { filter, Observable, Subscription } from 'rxjs';
import { GLCode, Vendor } from '@shared/services/company-data.service';
import { TagControlEvent, TagsControlComponent } from '@shared/components/tags-control/tags-control.component';
import { AutocompleteItem } from '@shared/types/autocomplete-item.type';
import { BudgetObjectActionsShared } from 'app/budget-object-details/services/budget-object-actions-shared';
import { Attachment } from '@shared/types/attachment.interface';
import { BudgetObjectAttachmentsService } from 'app/budget-object-details/services/budget-object-attachments.service';
import { CompanyUserDO } from '@shared/types/company-user-do.interface';
import { CurrencyDO } from '@shared/services/backend/company-currency.service';
import { TaskListChangeEvent } from 'app/budget-object-details/components/tasks-list/tasks-list.component';
import { DrawerFormFields } from 'app/budget-object-details/components/containers/details-drawer-form';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { CustomFieldStateConfig, CustomFieldsService } from '../../custom-fields/custom-field.service';
import { capitalizeString } from '@shared/utils/common.utils';
import { ParentCustomFieldInheritanceAction } from '@shared/types/segment-data-inheritance.interface';
import { UtilityService } from '@shared/services/utility.service';

@Component({
  selector: 'campaign-drawer-details-form',
  templateUrl: './campaign-details-form.component.html',
  styleUrls: ['./campaign-details-form.component.scss']
})
export class CampaignDetailsFormComponent implements OnInit {
  private readonly budgetObjectActionsShared = inject(BudgetObjectActionsShared);
  private readonly customFieldsService = inject(CustomFieldsService);
  public readonly utilityService: UtilityService = inject(UtilityService);

  @Input() isReadOnlyMode: boolean;
  @Input() objectType: string;
  @Input() currentCompanyUser: CompanyUserDO;
  @Input() budgetTodayDate: Date;
  @Input() formData: FormGroup;
  @Input() currentState: CampaignDetailsState;
  @Input() parentCampaignIsCommitted: boolean;
  @Input() companyCurrencyCode: string;
  @Input() currencyList: CurrencyDO[];
  @Input() attachmentsManager: BudgetObjectAttachmentsService;
  @Input() glCodes: GLCode[];
  @Input() vendors: Vendor[];
  @Input() tagsAutocompleteItems: AutocompleteItem[];
  @Input() locationItems: HierarchySelectItem[];
  @Input() getVendorName: string;
  @Input() maxVendorNameLength: number;
  @Input() autocompleteVendors: Observable<Vendor[]>;
  @Input() selectSegmentsConfig: HierarchySelectConfig;
  @Input() allowedSegmentSelectItems: HierarchySelectItem[];
  @Input() objectTypes: BudgetObjectType[];
  @Input() unsavedCustomTypeId: number;
  @Input() isCustomTypeEntering: boolean;
  @Input() ownerOptions: SelectOption[];
  @Input() selectedSegmentId: number;
  @Input() isChildCampaignCreation: boolean;
  @Input() companyId: number;
  @Input() objectId: number;
  @Input() isCustomFieldsEnabledForCG: boolean;
  @Input() set resetCustomFieldsFormGroup (val: boolean) {
    if (typeof val === 'boolean' && val) {

      if(this.form) {
        this.initialDropdownState = this.form.getRawValue();
      }
      Object.keys(this.initialDropdownInheritanceState).forEach(key => {
        this.initialDropdownInheritanceState[key] = this.inheritancePropogationStateForCFMap[key] || false;
      });

      if(this.objectId && !this.cfDropdownSubscriptions.length) {
        this.setupCFInheritanceSubscriptions();
      }
    }
  }
  @Input() resetFormAndFetchCustomField: boolean;
  @Input() commonCFInheritanceValues: Record<string, number[]>;

  @Output() handleOwnerChange = new EventEmitter<MatSelectChange>();
  @Output() handleTypeChange = new EventEmitter<void>();
  @Output() handleCustomTypeChange = new EventEmitter<void>();
  @Output() handleSegmentChanged = new EventEmitter<HierarchySelectItem>();
  @Output() handleVendorChange = new EventEmitter<void>();
  @Output() handleParentSelectionChange = new EventEmitter<string>();
  @Output() objectCurrencyChanged = new EventEmitter<string>();
  @Output() handleFileAttached = new EventEmitter<Attachment>();
  @Output() handleFileDelete = new EventEmitter<Attachment>();
  @Output() handleFileDownload = new EventEmitter<Attachment>();
  @Output() handleTasksUpdate = new EventEmitter<TaskListChangeEvent>();
  @Output() syncUnsavedChangesFlag = new EventEmitter<boolean>();
  @Output() syncCustomFieldsUsageChanges = new EventEmitter<boolean>();
  @Output() syncCustomFieldsFormValidity = new EventEmitter<boolean>();
  @Output() syncCustomFieldsFormGroup = new EventEmitter<FormGroup>();
  @Output() customFieldsStateDiff = new EventEmitter<any>();
  @Output() onAmountClick = new EventEmitter<void>();

  @ViewChild('tagsControl') tagsControl: TagsControlComponent;

  customFieldConfigs: CustomFieldStateConfig[] = [];
  inheritancePropogationStateForCFMap: Record<string, boolean> = {};
  initialDropdownInheritanceState: Record<string, boolean> = {};
  currentDropdownValue: any;
  previousDropdownValue: any;
  customFieldDropdownStateSnapshot: Record<string, any> = {};
  cfInheritanceSubscription: Subscription;
  cfDropdownSubscriptions: Subscription[] = [];
  applyCFToAllChildren: boolean = false;

  form: FormGroup;
  initialDropdownState: Record<string, any[]> = {};
  customFieldsPayload: Record<string, string[]> = {};

  constructor(private formBuilder: FormBuilder){}

  ngOnInit() {
    this.cfInheritanceSubscription =  this.customFieldsService.applyCFInheritanceToAllChildren$
    .pipe(
      filter(({ id }) => id == this.objectId)
    )
    .subscribe(({ action, applyToAllChildren }) => {
      this.handleApplyToAllChildrenPropagationState(action, applyToAllChildren);
    })
  }

  ngOnChanges(changes: { [propKey: string]: SimpleChange }) {
    if(
      (changes.isCustomFieldsEnabledForCG && changes.isCustomFieldsEnabledForCG.currentValue)
      ||
      (this.isCustomFieldsEnabledForCG && changes.resetFormAndFetchCustomField && changes.resetFormAndFetchCustomField.currentValue)
    ) {
      this.fetchCustomFieldsForCG();
    }

    if(changes.commonCFInheritanceValues && changes.commonCFInheritanceValues.currentValue && Object.keys(this.customFieldConfigs).length) {
      this.applyCommonCFInheritanceValues(changes.commonCFInheritanceValues.currentValue);
    }

  }

  protected readonly DrawerFormFields = DrawerFormFields;
  protected readonly currencyTooltip ="In order to save this campaign in a different currency, please save it and then edit the currency";
  protected currencyMaskOptions = { decimal: '.', precision: 2, align: 'right', allowNegative: true, prefix: '' };

  protected minEndDate = null;

  public onDropdownStateChanged(selectedValue: any, index: number, propagateInheritance = true) {
    // Handle state change here
    const controlName = 'dropdown_' + index;
    this.onCustomFieldValueChange(controlName, propagateInheritance);

  }

  public initializeCustomFieldsForm() {
    const controlsConfig = this.customFieldConfigs.reduce((acc, config, index) => {
      let defaultValue = config.defaultValue !== undefined ? config.defaultValue : null;
      let selectedValue: any = config.selectedValue !== undefined ? config.selectedValue : this.currentState?.parentCampaignId ? defaultValue : undefined;

      if(config.isMultiSelect) {
        selectedValue = config.selectedValue.length ? config.selectedValue : this.currentState?.parentCampaignId ? defaultValue : [];
      }

      acc['dropdown_' + index] = [selectedValue, config.required ? Validators.required : null];
      this.initialDropdownInheritanceState['dropdown_' + index] = false;
      return acc;
    }, {});
    this.form = this.formBuilder.group(controlsConfig);
    if(this.objectId) {
      this.setupCFInheritanceSubscriptions();
    }

    this.initialDropdownState = this.form.getRawValue();
    this.syncCustomFieldsFormValidity.emit(this.form.valid);
    if(!this.objectId && this.currentState?.parentCampaignId) {
      this.initialDropdownState = this.customFieldsService.initDefaultCustomFieldsFormData(this.objectId, this.form);

    let currentInheritanceAndDataState =  {};
    let cfFormState = this.form.getRawValue()
    Object.keys(cfFormState).forEach(key  => {
      currentInheritanceAndDataState[key] =  {
        triggerInheritance :this.inheritancePropogationStateForCFMap[key] || false,
        selectedValueForCF: cfFormState[key]
      };
    })

    let initialInheritanceAndDataState =  {};
    Object.keys(this.initialDropdownInheritanceState).forEach(key  => {
      initialInheritanceAndDataState[key] =  {
        triggerInheritance :this.initialDropdownInheritanceState[key] || false,
        selectedValueForCF: this.initialDropdownState[key]
      };
    })

      let diffMap = this.customFieldsService.getStateDiff(initialInheritanceAndDataState, currentInheritanceAndDataState);
      this.customFieldsPayload = this.customFieldsService.getSelectedOptionsIdPayloadForCF(diffMap, this.customFieldConfigs);
      this.customFieldsStateDiff.emit(this.customFieldsPayload);
    }

    this.syncCustomFieldsFormGroup.emit(this.form);
  }

  public fetchCustomFieldsForCG() {
    if(this.form) {
      this.form.reset();
    }
    this.customFieldsService.fetchDropdownOptions(
      this.companyId,
      this.objectId,
      capitalizeString(this.objectType)
    )
    .subscribe(data => {
      data.forEach(config => {
        if(config.isMultiSelect){
          let output = this.customFieldsService.rearrangeSelectedOptionValues(config.selectedValue, config.options);
          config.options = output;
        }
      })
      this.customFieldConfigs = data;
      this.initializeCustomFieldsForm();

      if(this.currentState?.parentObject?.id && this.commonCFInheritanceValues) {
        this.applyCommonCFInheritanceValues(this.commonCFInheritanceValues);
      }
     });
  }

  public applyCommonCFInheritanceValues(data: Record<number, number[]>) {
    Object.keys(data).forEach(cfId => {
      let configIndex = this.customFieldConfigs.findIndex(config => Number(config.id) === Number(cfId));
      let cfConfig = this.customFieldConfigs[configIndex];
      let dropdownControl = this.form.get('dropdown_' + configIndex);
      const selectedValues = this.customFieldsService.getOptionValuesByIds(data[cfId], cfConfig.optionValueIdMapping)

      if(cfConfig.isMultiSelect) {
        dropdownControl.setValue(selectedValues);
      } else {
        dropdownControl.setValue(selectedValues[0]);
      }
      this.inheritancePropogationStateForCFMap['dropdown_' + configIndex] = this.applyCFToAllChildren;
    });

    // Propogate the changes to the parent component to update the state and save the CF changes
    this.onCustomFieldValueChange();
    this.customFieldsService.triggerUpdateSelectAllState();

  }

  public onCustomFieldValueChange(controlName?: string, propagateInheritance = true) {

    if(controlName) {
      this.inheritancePropogationStateForCFMap[controlName] = this.objectId ? propagateInheritance: false;
    }

    let currentInheritanceAndDataState =  {};
    let cfFormState = this.form.getRawValue()
    Object.keys(cfFormState).forEach(key  => {
      currentInheritanceAndDataState[key] =  {
        triggerInheritance :this.inheritancePropogationStateForCFMap[key] || false,
        selectedValueForCF: cfFormState[key]
      };
    })

    let initialInheritanceAndDataState =  {};
    Object.keys(this.initialDropdownState).forEach(key  => {
      initialInheritanceAndDataState[key] =  {
        triggerInheritance :this.initialDropdownInheritanceState[key] || false,
        selectedValueForCF: this.initialDropdownState[key]
      };
    })


    let diffMap = this.customFieldsService.getStateDiff(initialInheritanceAndDataState, currentInheritanceAndDataState);
    this.customFieldsPayload = this.customFieldsService.getSelectedOptionsIdPayloadForCF(diffMap, this.customFieldConfigs);

    console.log('diffMap: ', diffMap);
    console.log('customFieldsPayload: ', this.customFieldsPayload);



    if(Object.keys(diffMap).length > 0){
      this.syncCustomFieldsUsageChanges.emit(true);
    }else {
      this.syncCustomFieldsUsageChanges.emit(false);
    }

    if( this.form.valid && Object.keys(diffMap).length > 0){
      this.syncUnsavedChangesFlag.emit(true);
      this.customFieldsStateDiff.emit(this.customFieldsPayload);
    }else {
      this.syncUnsavedChangesFlag.emit();
    }
    this.syncCustomFieldsFormValidity.emit(this.form.valid);
  }

  setupCFInheritanceSubscriptions() {
    Object.keys(this.form.controls).forEach((key, index) => {
      const initialValue = this.form.get(key).value;
      this.customFieldDropdownStateSnapshot[key] =  {
        previousDropdownValue: initialValue,
        currentDropdownValue: initialValue
      };

      let cfDropdownSubscription = this.form.get(key).valueChanges.subscribe((value) => {
        // this.inheritancePropogationStateForCFMap[key] = true;
        this.customFieldDropdownStateSnapshot[key].currentDropdownValue = value;
      });

      this.cfDropdownSubscriptions.push(cfDropdownSubscription);

    });
  }

  public onSelectDropdownClosed(_event, cfDropdownKey) {

    if(!this.objectId) { return; }

    const previousDropdownValue = this.customFieldDropdownStateSnapshot[cfDropdownKey].previousDropdownValue;
    const currentDropdownValue = this.customFieldDropdownStateSnapshot[cfDropdownKey].currentDropdownValue;

    const onCancel = (cfDropdownKey: string) => {
      this.inheritancePropogationStateForCFMap[cfDropdownKey] = false;
      this.onCustomFieldValueChange(cfDropdownKey, false);

    };

    const actions = {
      [ParentCustomFieldInheritanceAction.Keep]: () => {
        this.inheritancePropogationStateForCFMap[cfDropdownKey] = true;
      },
      [ParentCustomFieldInheritanceAction.None]: () => onCancel(cfDropdownKey),
    };

    if(JSON.stringify(previousDropdownValue) !== JSON.stringify(currentDropdownValue)) {
      this.customFieldDropdownStateSnapshot[cfDropdownKey].previousDropdownValue = currentDropdownValue
      const currentCFName = this.customFieldConfigs[cfDropdownKey.split('_')[1]].cfName;
      this.customFieldsService.requestInheritancePropagationConfirmation(
        {
        title: `Update '${currentCFName}' Custom Field`,
        keepAllowed: true,
        multiUpdate: false,
        parentName: "Parent",
        objectName: "Campaign"
      },
      actions
    )
    .subscribe((action) => {
      console.log(action);
      actions[action]();
    });
    }

  }

  handleApplyToAllChildrenPropagationState(action: 'Keep' | 'Replace', applyToAllChildren: boolean) {
    const mapId = this.currentState?.parentObject?.id;
    const parentType = this.currentState?.parentObject?.type;

    this.applyCFToAllChildren = applyToAllChildren;
    if(action === 'Replace') return;

    this.utilityService.showLoading(true);
    this.customFieldsService.fetchCustomFieldInheritanceValues(this.companyId, mapId, parentType, this.objectType ).subscribe(data => {
        console.log("Inheritance values on keep: ", data);
        Object.keys(data).forEach((key) => {
          let cfDropdownIndex = this.customFieldConfigs.findIndex(config => config.id === Number(key));
          let cfInheritanceStateKey = 'dropdown_' + cfDropdownIndex;
          this.inheritancePropogationStateForCFMap[cfInheritanceStateKey] = applyToAllChildren;
          this.utilityService.showLoading(false);
        });
      this.onCustomFieldValueChange();
    });
  }

  protected onChangeAmountState(value: string): void {
    this.formData.get(DrawerFormFields.amountStatus).setValue(value);
    this.syncUnsavedChangesFlag.emit();
  }

  protected get glCodeActiveName(): string {
    const glId = this.formData.controls[DrawerFormFields.glCode]?.value;
    const glItem = this.glCodes?.find(code => code.id === glId);
    return glItem ? glItem.name : '';
  }

  protected createTag(tag: TagControlEvent): void {
    this.budgetObjectActionsShared.createTag(tag, this.currentState.tagMappings);
    this.syncUnsavedChangesFlag.emit();
  }

  protected addTag(tag: TagControlEvent): void {
    this.budgetObjectActionsShared.addTag(tag, this.currentState.tagMappings);
    this.syncUnsavedChangesFlag.emit();
  }

  protected removeTag(tag: TagControlEvent): void {
    this.budgetObjectActionsShared.removeTag(tag, this.currentState.tagMappings);
    this.syncUnsavedChangesFlag.emit();
  }

  protected get segmentControl(): AbstractControl {
    return this.formData.get(DrawerFormFields.segment);
  }

  protected onHandleStartDateChange(e): void {
    this.minEndDate = e;
    this.formData.controls.startDate.setValue(e);
  }

  protected onHandleEndDateChange(e): void {
    this.formData.controls.endDate.setValue(e);
  }

  ngOnDestroy() {
    this.cfInheritanceSubscription?.unsubscribe();
    this.cfDropdownSubscriptions.forEach(sub => sub?.unsubscribe());
  }

}
