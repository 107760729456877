import { ParentHierarchyTooltipData } from '../../manage-table/types/parent-hierarchy-tooltip-data.type';
import { MetricMilestones } from '../../budget-object-details/types/metric-milestone.interface';
import { MetricProgressState } from '@shared/types/metric-progress-state.type';
import { CheckboxValue } from '@shared/enums/checkbox-value.enum';
import { BudgetTimeframe } from '@shared/types/timeframe.interface';
import { BudgetAllocationCellGesturesEvent } from '../../budget-allocation/components/budget-allocation-cell/budget-allocation-cell.types';
import { BudgetTimeframesType } from '@shared/types/budget.interface';
import { LightCampaign } from '@shared/types/campaign.interface';
import { LightProgram } from '@shared/types/program.interface';
import { Goal } from '@shared/types/goal.interface';
import { BudgetSegmentAccess } from '@shared/types/segment.interface';
import { SegmentGroup } from '@shared/types/segment-group.interface';
import { SharedCostRule } from '@shared/types/shared-cost-rule.interface';
import { CompanyUserDO } from '@shared/types/company-user-do.interface';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { TableRowsDataLoader } from '@manage-ceg/types/manage-ceg-table-row-data.types';
import { ManageTableRowType } from '@shared/enums/manage-table-row-type.enum';


export enum ManageCegDataMode {
  Budget = 'budget',
  Performance = 'performance'
}

export enum ManageCegViewMode {
  Segments = 'segments',
  Goals = 'goals',
  Campaigns = 'campaigns'
}

export enum ManageCegBudgetMode {
  Table = 'table',
  Chart = 'chart'
}

export const GroupingRowTypes = [
  ManageTableRowType.Goal,
  ManageTableRowType.Segment,
  ManageTableRowType.SegmentGroup,
];

export const ManageTableRowTypeLabel = {
  [ManageTableRowType.Segment]: 'Segment',
  [ManageTableRowType.SegmentGroup]: 'Segment Group',
  [ManageTableRowType.Goal]: 'Goal',
  [ManageTableRowType.Campaign]: 'Campaign',
  [ManageTableRowType.ExpenseGroup]: 'Expense Group',
};

export interface ManageCegTableCellAllocationAmount {
  ownAmount: number;
  campaignsAndPrograms?: number;
  unallocated?: number;
}

export type ManageCegTableCellAllocations = Record<ManageTableBudgetColumnName, ManageCegTableCellAllocationAmount>

export interface ManageCegTableRowAllocations {
  [timeframeId: string]: ManageCegTableCellAllocations;
}

export interface ManageCegTableRowTotals {
  segment: any;
}

export interface ManageCegTableRowPerformance {
  [ManageTablePerformanceColumnName.TargetReturn]: number[];
  [ManageTablePerformanceColumnName.CurrentReturn]: number[];
  [ManageTablePerformanceColumnName.LowForecast]: number[];
  [ManageTablePerformanceColumnName.HighForecast]: number[];
  [ManageTablePerformanceColumnName.TargetRoi]: number[];
  [ManageTablePerformanceColumnName.CurrentRoi]: number[];
  [ManageTablePerformanceColumnName.Owner]: string[];
}

export interface ManageCegTableRow {
  id: string;
  objectId: number;
  itemId: number | string; // it's just object Id for regular rows and subId for pseudo object rows
  type: ManageTableRowType;
  name: string;
  hierarchyInfo?: ParentHierarchyTooltipData;
  hierarchyBreakLine?: boolean; // set true for first child in hidden parent
  parentId?: string;
  segmentId?: number;
  sharedCostRuleId?: number;
  isClosed?: boolean;
  segmentRelated?: boolean;
  isEditable?: boolean;
  isSelectable?: boolean;
  isFilteredOut?: boolean;
  processed?: boolean;
  children?: ManageCegTableRow[];
  childRowsDataLoader?: TableRowsDataLoader; // Overrides the root or parent TableRowsDataLoader
  loadedChildren?: ManageCegTableRow[];
  allChildrenLoaded?: boolean;
  shownChildren?: ManageCegTableRow[];
  createdDate?: Date;
  objectTypeId?: number;
  externalId?: string;
  currencyCode?: string;

  status?: string; // TODO: remove option mark later!!!

  allocations?: ManageCegTableRowAllocations;
  presentationAllocations?: ManageCegTableRowAllocations;

  performanceData?: ManageCegTableRowPerformance;
  campaign_integrated_source?: boolean;
}

export interface CegRecordAction {
  title: string;
  tooltip?: string;
  icon: IconProp;
  handler: Function;
  hidden: boolean;
  disabled: boolean;
  customCssClass?: string;
}

export enum ManageCegTableMenuAction {
  Duplicate = 'Duplicate',
  MoveTo = 'MoveTo',
  Close = 'Close',
  Reopen = 'Reopen',
  CopyId = 'CopyId',
  Delete = 'Delete',
  ChangeType = 'ChangeType',
  AddTags = 'AddTags',
  RemoveTags = 'RemoveTags',
  Export = 'Export',
  AddMetrics = 'AddMetrics',
  ChangeStatus = 'ChangeStatus'
}

export interface OpenExpensesEvent {
  timeframeId?: number;
  withoutParent?: boolean;
}

export interface CreateOverlayEvent {
  record: ManageCegTableRow;
  event: MouseEvent;
}

export interface ManageTableGrandTotal {
  [ManageTableDataMode.Budget]: Record<PresentationTimeframe, ManageTableTotalValues>;
  [ManageTableDataMode.Performance]: ManageCegTableRowPerformance;
}

export interface ManageCegTableActionDataSource {
  record: ManageCegTableRow;
  timeframe: BudgetTimeframe | null;
  type ?: string;
}

export interface ManageCegTableAllocationUpdatePayload {
  [objectId: string]: {
    record: ManageCegTableRow,
    updates: {
      timeframeId: number;
      amount: number;
      type?: string;
    }[];
  };
}

export interface ManageCegTableActionEvent {
  amount?: number;
  gestureEvent?: BudgetAllocationCellGesturesEvent;
  dataSource: ManageCegTableActionDataSource;
  type?: string;
}

export interface ManageCegTableSelectionState {
  records: Record<string, ManageCegTableSelectionRecord>;
  goals: Set<number>;
  campaigns: Set<number>;
  expGroups: Set<number>;
  segments: Set<number>;
  segmentGroups: Set<number>;
  childCampaigns?: Set<number>;
  childExpGroups?: Set<number>;
  selectAllValue: CheckboxValue;
}

export interface ManageCegTableSelectionRecord {
  selectedChildren: Set<string>;
  value: CheckboxValue;
}

export interface BulkActionTargets {
  goals?: number[];
  campaigns?: number[];
  expGroups?: number[];
}

export interface BulkActionPayloads<T> {
  goals?: T[];
  campaigns?: T[];
  expGroups?: T[];
}

export interface BudgetTimeframeBrief {
  id: number | string;
  shortName: string;
  name?: string;
  locked?: boolean;
  isVisible: boolean;
  isOriginal?: boolean;
}

export interface ManageTableViewConfig {
  segmentBreakdownData: SegmentBreakdownConfig;
  columns: ManageTableBudgetColumnsVisibility;
  columnsFormula: boolean;
}

export enum ManageTableViewMode {
  Segments = 'segments',
  Goals = 'goals',
  Campaigns = 'campaigns',
}

export enum ManageTableDataMode {
  Budget = 'budget',
  Performance = 'performance',
}

export interface SegmentBreakdownConfig {
  campaignsAndPrograms: boolean;
  unallocated: boolean;
  formula: boolean;
}

export interface ManageTableBudgetColumn {
  id: ManageTableBudgetColumnName;
  label: string[];
}

export interface ManageTablePerformanceColumn {
  id: ManageTablePerformanceColumnName;
  label: string;
}

export enum ManageTableBudgetColumnName {
  Budget = 'budget',
  Forecast = 'forecast',
  ConvertedBudget = 'convertedBudget',
  Actual = 'actual',
  Committed = 'committed',
  Planned = 'planned',
  CommittedAndPlanned = 'committedAndPlanned',
  Available = 'available',
}

export enum ManageTablePerformanceColumnName {
  TargetReturn = 'targetReturn',
  CurrentReturn = 'currentReturn',
  LowForecast = 'lowForecast',
  HighForecast = 'highForecast',
  TargetRoi = 'targetRoi',
  CurrentRoi = 'currentRoi',
  Owner = 'owner',
}

export interface ManageTableBudgetColumnsVisibility {
  [ManageTableBudgetColumnName.Budget]: boolean;
  [ManageTableBudgetColumnName.Actual]: boolean;
  [ManageTableBudgetColumnName.Committed]: boolean;
  [ManageTableBudgetColumnName.Planned]: boolean;
  [ManageTableBudgetColumnName.CommittedAndPlanned]: boolean;
  [ManageTableBudgetColumnName.Available]: boolean;
  [ManageTableBudgetColumnName.Forecast]?: boolean;
}

export interface ManageTableTotalValues {
  [ManageTableBudgetColumnName.Budget]: number;
  [ManageTableBudgetColumnName.Actual]: number;
  [ManageTableBudgetColumnName.Committed]: number;
  [ManageTableBudgetColumnName.Planned]: number;
  [ManageTableBudgetColumnName.CommittedAndPlanned]?: number;
  [ManageTableBudgetColumnName.Available]: number;
}

export interface PerformanceColumnDataItem {
  mappingId: number;
  current: number;
  target: number;
  milestones: MetricMilestones;
  name: string;
  estimatedTarget: number;
  estimatedDiffPercentage: number;
  startDate: any;
  progressState: MetricProgressState;
}

export interface PerformanceColumnData {
  [campaignId: string]: PerformanceColumnDataItem;
}

export enum ManageTableMenuAction {
  Duplicate = 'Duplicate',
  MoveTo = 'MoveTo',
  Close = 'Close',
  Reopen = 'Reopen',
  CopyId = 'CopyId',
  Delete = 'Delete',
  ChangeType = 'ChangeType',
  AddTags = 'AddTags',
  RemoveTags = 'RemoveTags',
  Export = 'Export',
  AddMetrics = 'AddMetrics'
}

export enum TotalsSidebarState {
  Hidden = 'hidden',
  Preview = 'preview',
  FullWidth = 'fullWidth'
}

export enum PresentationTimeframe {
  Q1 = 'q1',
  Q2 = 'q2',
  Q3 = 'q3',
  Q4 = 'q4',
  Year = 'year',
}

export enum TableConfigKeys {
  campaignsAndPrograms = 'campaignsAndPrograms',
  unallocated = 'unallocated',
  formula = 'formula',
  budget = 'budget',
  planned = 'planned',
  committed = 'committed',
  actual = 'actual',
  committedAndPlanned = 'committedAndPlanned',
  available = 'available',
  columnsFormula = 'columnsFormula',
  separate = 'separate',
  forecast = 'forecast',
}

export enum ManageTableViewConfigSections {
  segmentBreakdownData = 'segmentBreakdownData',
  columns = 'columns',
  columnsFormula = 'columnsFormula'
}

export enum TableConfigActionType {
  checkbox = 'checkbox',
  toggle = 'toggle'
}

export interface TableConfig {
  value: boolean;
  visible: boolean;
  disabled?: boolean;
  type?: TableConfigActionType;
  label?: string;
  key: TableConfigKeys;
  connectedWith?: Array<string>;
}

export interface ManageTableDataConfig {
  [ManageTableViewConfigSections.segmentBreakdownData]: Array<TableConfig>;
  [ManageTableViewConfigSections.columns]: Array<TableConfig>;
  [ManageTableViewConfigSections.columnsFormula]: boolean;
}

export interface TableConfigDialog {
  viewMode: ManageCegViewMode;
  config: ManageTableDataConfig;
  onSubmit: (ManageTableViewConfig) => void;
  onCancel: () => void;
}

export interface TimeframeConfigDialog {
  timeframeMode: BudgetTimeframesType;
  fiscalYearLabel: string;
  budgetTimeframes: BudgetTimeframe[];
  selectedTimeframes: (number | string)[];
  onSubmit: (selectedTimeframes: (number | string)[]) => void;
}

export type ManageCegTableData = ManageCegTableRow[];

export interface CreateCegItemTemplateEvent {
  contextRow: ManageCegTableRow;
  position: number;
}

export interface NewManageCegTableRowTemplate {
  rowTemplate: ManageCegTableRow;
  parent: ManageCegTableRow;
  recordsArray: ManageCegTableRow[];
  index: number;
}
export interface ManageCegViewModeChange {
  value: ManageCegViewMode;
  prevValue: ManageCegViewMode;
}

export interface ManageCegPageModes {
  viewModeChange: ManageCegViewModeChange;
  dataMode: ManageCegDataMode;
}

export interface ManageCEGTableContextData {
  timeframes: BudgetTimeframe[];
  viewModeChange: ManageCegViewModeChange;
  dataMode: ManageCegDataMode;
  campaigns: LightCampaign[];
  expGroups: LightProgram[];
  goals: Goal[];
  segments: BudgetSegmentAccess[];
  segmentGroups: SegmentGroup[];
  sharedCostRules: SharedCostRule[];
  currentCompanyUser: CompanyUserDO;
}

export enum SegmentBreakdownRow {
  ownAmount = 'ownAmount',
  campaignsAndPrograms = 'campaignsAndPrograms',
  unallocated = 'unallocated',
}

export enum ManagePageExportParamKeys {
  viewMode = 'view_mode',
  timeframeMode = 'timeframe_mode',
  showCampaignsAndPrograms = 'show_campaigns_and_programs',
  showOtherExpenses = 'show_other_expenses',
  showActualSpend = 'show_actual_spend',
  showRemainingPlannedCommitted = 'show_remaining_planned_committed',
  remainingPlannedCommittedSeparate = 'remaining_planned_committed_separate',
  showAvailable = 'show_available',
  rowsFormula = 'rows_formula',
  columnsFormula = 'columns_formula'
}

export interface ManagePageExportParams {
  [ManagePageExportParamKeys.viewMode]: string;
  [ManagePageExportParamKeys.timeframeMode]: string;
  [ManagePageExportParamKeys.showCampaignsAndPrograms]?: boolean;
  [ManagePageExportParamKeys.showOtherExpenses]?: boolean;
  [ManagePageExportParamKeys.showActualSpend]?: boolean;
  [ManagePageExportParamKeys.showRemainingPlannedCommitted]?: boolean;
  [ManagePageExportParamKeys.remainingPlannedCommittedSeparate]?: boolean;
  [ManagePageExportParamKeys.showAvailable]?: boolean;
  [ManagePageExportParamKeys.rowsFormula]?: boolean;
  [ManagePageExportParamKeys.columnsFormula]?: boolean;
}

export interface ObjectStatusPayload {
  ids: number[],
  amount_status: string;
}
