<th class="name-col">
  <div class="cell-content">
    <div class="name-wrap">
      <div class="selection" *ngIf="!performanceMode">
        <mat-checkbox
          color="primary"
          (change)="handleSelectAllChange($event)"
          [checked]="selectAllValue === SelectionValue.Active"
          [indeterminate]="selectAllValue === SelectionValue.Indeterminate"
        ></mat-checkbox>
      </div>
      <span class="name" [ngClass]="{'perfomance-mode-name': performanceMode}">Name</span>
    </div>

    <div class="performance-cell">
      Perf.
    </div>
  </div>
</th>

<th *ngFor="let timeframe of timeframes; let i = index; trackBy: identifyTimeframe"
    class="timeframe-col"
    [ngClass]="{
      'locked': timeframe.locked,
      'is-hidden': !budgetMode || !timeframe.isVisible
    }"
    [id]="'timeframe-label' + i"
    >
  <div class="cell-content">
    <div class="header-cell-title">
      <fa-icon [icon]="['fas', 'lock-keyhole']" *ngIf="timeframe.locked" class="locked-icon"></fa-icon>
      <span id="timeperiod">{{ timeframe.shortName }}</span>
    </div>
    <div class="header-cell-description sub-columns">
      <ng-container *ngFor="let column of budgetColumns; trackBy: identifyColumn">
        <div *ngIf="budgetColumnsVisibility[column.id]" [ngClass]="{ 'forecast-column-container': column.label.includes('Forecast')  }">
          <span *ngIf="column.label.includes('Forecast')" class="forecast-icon"><img src ="../../../../../../assets/images/icon-forecast.svg" alt="Forecast"></span>
          <div [id]="'label-' + column.id" class="label"
                dynamicPortalTooltip
                [tooltipContext]="tooltipContext[column.id]"
                [tooltipPosition]="tooltipPosition"
                [tooltipPositionCssClass]="'above'">
            <span *ngFor="let labelPart of column.label">{{ labelPart }}</span>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</th>

<th *ngIf="performanceMode" class="performance-column" @slideFromRightColumn>
  <div class="cell-content">
    <div class="header-cell-title">Performance</div>
    <div class="header-cell-description sub-columns">
      <span *ngFor="let performanceColumn of performanceColumns">{{ performanceColumn.label }}</span>
    </div>
  </div>
</th>

<th class="total-column" *ngIf="totalsSidebarState !== sidebarState.Hidden" @slideFromRightColumn>
  <div class="cell-content">
    <div class="header-cell-title totals-title">
      <expand-toggler
        [totalsSidebarState]="totalsSidebarState"
        (toggleTotalsState)="totalsSidebarStateChanged.emit($event)"
      ></expand-toggler>
      <span>Totals</span>
    </div>
    <div class="header-cell-description sub-columns jc-end">
      <ng-container *ngFor="let column of budgetColumns; trackBy: identifyColumn">
        <div *ngIf="column.id === budgetColumnName.Budget || (budgetColumnsVisibility[column.id] && totalsSidebarState === sidebarState.FullWidth) || (budgetColumnsVisibility[column.id] && (column.id === budgetColumnName.Forecast) )" class="label" [ngClass]="{ 'forecast-column-container': column.label.includes('Forecast')  }">
          <span *ngIf="column.label.includes('Forecast')" class="forecast-icon"><img src ="../../../../../../assets/images/icon-forecast.svg" alt="Forecast"></span>
          <span *ngFor="let labelPart of column.label">{{ labelPart }}</span>
        </div>
      </ng-container>
    </div>
  </div>
</th>
