<article
  class="details-page-wrapper"
  [matTooltip]="currentState?.name"
  [matTooltipDisabled]="isActive"
  matTooltipClass="dark-theme-tooltip simple drawer-name-tooltip"
  matTooltipPosition="left"
>
  <loader [isVisible]="isLoading"></loader>
  <drawer-header
    [detailsFormGroupData]="formData"
    [actions]="menuActions"
    [budgetList]="budgets"
    [isReadOnlyMode]="isReadOnlyMode"
    [hasUnsavedChanges]="unsavedChangesFlag"
    [objectHierarchy]="hierarchy"
    [childHierarchy]="childHierarchy"
    [nameFormControl]="fdNameControl"
    [maxObjectNameLength]="campaignNameMaxLength"
    [objectType]="objectType"
    [objectLabel]="objectLabel"
    [createdDate]="currentState?.created"
    [updatedDate]="currentState?.updated"
    [headerBackgroundClass]="headerBackgroundClass[objectType]"
    [isCEGBudget]="cegStatusEnabled"
    [disableSaveAndCloseButton]="isCustomFieldsEnabledForCG && !isCustomFieldsFormValid"
    (budgetToMoveSelected)="handleBudgetToMoveSelected($event)"
    (cancel)="handleCancelAction()"
    (save)="submitChanges(handleSaveAction.bind(this))"
    (saveAndNew)="submitChanges(handleSaveAndNewAction.bind(this))"
    (saveAndClose)="submitChanges(handleSaveAndCloseAction.bind(this))"
    (onChildHierarchyItemClick)="childHierarchyClickHandler($event)"
  ></drawer-header>

  <drawer-tab-switch
    [activeTabId]="activeTabId"
    [tabs]="tabsData"
    (activeTabChanged)="setActiveTab($event)"
  ></drawer-tab-switch>

  <div class="details-content tab-content-wrap">
    <campaign-drawer-details-form
    *ngIf="currentState"
      #detailsForm
      [ngClass]="{ 'visible': activeTabId === ObjectDetailsTabControl.Details }"
      [currentCompanyUser]="currentCompanyUser"
      [budgetTodayDate]="budgetTodayDate"
      [isReadOnlyMode]="isReadOnlyMode"
      [objectType]="objectType"
      [formData]="formData"
      [currentState]="currentState"
      [companyCurrencyCode]="companyCurrency?.code"
      [parentCampaignIsCommitted]="parentCampaignIsCommitted"
      [selectedSegmentId]="selectedSegmentId"
      [isChildCampaignCreation]="isChildCampaignCreation"
      [currencyList]="currencyList"
      (onAmountClick)="showAllocationTab()"
      (objectCurrencyChanged)="objectCurrencyChanged($event)"
      [locationItems]="locationItems"
      [selectSegmentsConfig]="selectSegmentsConfig"
      [allowedSegmentSelectItems]="allowedSegmentSelectItems"
      (handleSegmentChanged)="handleSegmentChanged($event, false)"
      (handleParentSelectionChange)="handleParentSelectionChange($event)"
      [ownerOptions]="ownerOptions"
      (handleOwnerChange)="handleOwnerChange($event)"
      [objectTypes]="campaignTypes"
      [isCustomTypeEntering]="isCustomTypeEntering"
      [unsavedCustomTypeId]="budgetObjectDetailsManager.unsavedCustomTypeId"
      (handleTypeChange)="handleTypeChange()"
      (handleCustomTypeChange)="handleCustomTypeChange()"
      [getVendorName]="getVendorName"
      [maxVendorNameLength]="configuration.MAX_VENDOR_NAME_LENGTH"
      (handleVendorChange)="handleVendorChange()"
      [glCodes]="glCodes"
      [vendors]="vendors"
      [companyId]="companyId"
      [objectId]="objectId"
      [tagsAutocompleteItems]="tagsManager.autocompleteItems"
      [attachmentsManager]="attachmentsManager"
      [isCustomFieldsEnabledForCG]="isCustomFieldsEnabledForCG"
      [resetCustomFieldsFormGroup]="resetCustomFieldsFormGroups"
      [resetFormAndFetchCustomField]="resetFormAndFetchCustomField"
      [commonCFInheritanceValues]="commonCFInheritanceValues"
      (handleFileAttached)="handleFileAttached($event)"
      (handleFileDelete)="handleFileDelete($event)"
      (handleFileDownload)="handleFileDownload($event)"
      (handleTasksUpdate)="handleTasksUpdate($event)"
      (syncUnsavedChangesFlag)="syncUnsavedChangesFlag($event)"
      (syncCustomFieldsFormValidity)="syncCustomFieldsFormValidity($event)"
      (syncCustomFieldsUsageChanges)="syncCustomFieldsUsageChanges($event)"
      (customFieldsStateDiff)="customFieldsStateDiff($event)"
      (syncCustomFieldsFormGroup)="syncCustomFieldsFormGroup($event)"

    >
    </campaign-drawer-details-form>

    <div class="tab-content-container"
         [ngClass]="{ 'visible': activeTabId === ObjectDetailsTabControl.Allocation }">
      <create-object-toolbar
        *ngIf="!isReadOnlyMode"
        [objectId]="objectId"
        [parentObjectLabel]="objectLabel"
        [objectTypes]="allowedChildrenList"
        (createObject)="onCreateObject($event)"
      ></create-object-toolbar>
      <ceg-budget-allocations
        [objectId]="currentState?.objectId"
        [objectType]="objectType"
        [companyId]="companyId"
        [budgetId]="budget?.id"
        [isReadOnlyMode]="isReadOnlyMode"
        [campaignIsClosed]="currentState?.mode === 'Closed'"
        [budgetTimeframes]="budgetTimeframes"
        [objectAllocations]="currentState?.allocations"
        [allocationTotals]="allocationTotals"
        [isSegmentlessCampaign]="isSegmentlessCampaign"
        [externalIntegrationType]="externalIntegration"
        (onChange)="handleAllocationsUpdate($event)"
      ></ceg-budget-allocations>
    </div>

    <div class="tab-content-container mt-1"
         [ngClass]="{ 'visible': activeTabId === ObjectDetailsTabControl.Performance }">
      <integration-mapping-menu
        [salesforceHandler]="connectedIntegrations[MetricIntegrationName.Salesforce]?.length && currentState?.objectId && openSalesforceMappingModal"
        [hubSpotHandler]="connectedIntegrations[MetricIntegrationName.Hubspot]?.length && currentState?.objectId && openHubspotMappingModal"
      ></integration-mapping-menu>
      <details-metric-control
        [defaultStartDate]="currentState?.startDate || budget?.budget_from"
        [defaultEndDate]="budget?.budget_to"
        [todayDate]="budgetTodayDate"
        [objectType]="objectType"
        [objectId]="currentState?.objectId"
        [companyCurrency]="companyCurrency?.symbol"
        [objectBudget]="allocationTotals?.budget || 0"
        [totalSpend]="allocationTotals?.actual || 0"
        [savedMetricMappings]="currentState?.metricMappings"
        [isReadOnlyMode]="isReadOnlyMode"
        [products]="metricsManager.products"
        [metricTypes]="metricsManager.types"
        [keyMetricSelection]="true"
        [keyMetricId]="currentState?.keyMetricId"
        [actualBusinessValue]="actualBusinessValue"
        [targetBusinessValue]="targetBusinessValue"
        [isPowerUser]="isPowerUser"
        (onAddMapping)="addMetricMappings($event)"
        (setKeyMetric)="setKeyMetric($event)"
        (keyMetricPerformanceData)="updatePerformanceTabData($event)"
        (onOpenMetric)="openMetric($event)"
      ></details-metric-control>
    </div>
  </div>
</article>
