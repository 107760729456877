import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { UtilityService } from 'app/shared/services/utility.service';
import { Configuration } from 'app/app.constants';
import { Validations } from 'app/app.validations';
import { Subscription } from 'rxjs';
import { HttpStatusCode } from '@angular/common/http';
import { CompanyService } from '@shared/services/backend/company.service';

@Component({
  selector: 'app-root',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit, OnDestroy {
  @ViewChild('form', { static: true }) FormData: NgForm;

  public form: any;
  public subscriptions: Subscription[] = [];
  public email_warning: any;
  public company_exists = false;
  public buttonDisabled = false;
  public company_name_required = false;

  public currencyList = [];
  public selectedCurrency = null;
  public currencyListOpened = false;
  public displayEmailTip = false;
  private readonly freeRegistration = true;

  constructor(
    public configuration: Configuration,
    public companyService: CompanyService,
    public utilityService: UtilityService,
    public router: Router,
    public validations: Validations
  ) {
    this.form = {
      first_name: '',
      last_name: '',
      company_name: '',
      currency: '',
      email: '',
      salesforce_tenant_id: '',
      acceptTerms: false
    };
    this.email_warning = this.configuration.email_warning;
  }

  ngOnInit() {
    const isAllowed = this.utilityService.getNoRegistrationFlag();
    if (!isAllowed) {
      this.router.navigate([this.configuration.ROUTING_CONSTANTS.LOGIN]);
    }
  }

  onToggleCurrencyList(value) {
    this.currencyListOpened = value;
  }

  onSelectCurrency(e) {
    this.FormData.controls.currency.patchValue(e.title);
    this.selectedCurrency = e;
    this.onToggleCurrencyList(false);
  }

  companyExists(company_name) {
    let value;
    if (company_name.value === '') {
      this.company_name_required = true;
      this.buttonDisabled = true;
    } else {
      this.company_name_required = false;
      if (company_name.model != null) {
        value = company_name.model.trim();
      }
      if (company_name.control.errors == null) {
        this.buttonDisabled = true;
        return new Promise<any>((resolve, reject) => {
          if (value.trim() !== '') {
            const data = {'name': value};
            this.companyService.companyExists(data).subscribe(
              (response: any) => {
                this.buttonDisabled = false;
                if (Number(response?.status) === HttpStatusCode.Ok) {
                  if (company_name.control.errors) {
                    company_name.control.errors['companyExists'] = true;
                  } else {
                    company_name.control.errors = {};
                    company_name.control.errors.companyExists = true;
                  }
                  company_name.control.status = 'INVALID';
                  this.company_exists = true;

                } else {
                  if (company_name.control.errors) {
                    company_name.control.errors['companyExists'] = null;
                  } else {
                    company_name.control.errors = {};
                    company_name.control.errors.companyExists = null;
                  }
                  company_name.control.status = 'VALID';
                  this.company_exists = false;
                }
                resolve(null);
              },
              error => {
                if (company_name.value === '') {
                  this.company_name_required = true;
                  this.buttonDisabled = true;
                } else {
                  this.buttonDisabled = false;
                  this.company_name_required = false;
                  if (company_name.control.errors) {
                    company_name.control.errors['companyExists'] = null;
                  } else {
                    company_name.control.errors = {};
                    company_name.control.errors.companyExists = null;
                  }
                  company_name.control.status = 'VALID';
                  this.company_exists = false;
                  resolve(null);
                }
              }
            );
          } else {
            this.company_name_required = true;
            this.buttonDisabled = true;
            resolve(null);
          }
        });
      }
    }
  }

  register(form, isValid: boolean, ngForm: any) {
    if (form['first_name']) {
      form['first_name'] = (form['first_name'].replace(/ +/g, ' ')).trim();
    }

    if (form['last_name']) {
      form['last_name'] = (form['last_name'].replace(/ +/g, ' ')).trim();
    }

    if (form['salesforce_tenant_id']) {
      form['salesforce_tenant_id'] = (form['salesforce_tenant_id'].replace(/ +/g, ' ')).trim();
    }

    if (form['company_name']) {
      form['company_name'] = (form['company_name'].replace(/ +/g, ' ')).trim();
    }

    if (form['currency']) {
      form['currency'] = this.selectedCurrency.value;
    }

    if (form['email']) {
      form['email'] = form['email'].toLowerCase();
    }

    if (isValid) {
      this.utilityService.showLoading(true);

      if (this.freeRegistration) {
        form['is_free_account'] = true;
      }
      this.displayEmailTip = false;

      const registerData = { ...form };
      delete registerData.confirmPassword;
      delete registerData.acceptTerms;

      this.subscriptions.push(
        this.companyService.register(form).subscribe(
        data => this.success(data, ngForm, registerData),
        error => this.utilityService.handleError(error)
        )
      );
    }
  }

  checkEmail(email) {
    const emailRegex = /^[a-zA-Z0-9._+-]+[a-zA-Z0-9_+-]@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,16}$/;
    if ((email !== undefined) && (email.search(/gmail.com/gi) === -1) &&
      (email.search(/yahoo.com/gi) === -1) && (email.search(/aol.com/gi) === -1)
    ) {
      this.displayEmailTip = false;
    } else {
      this.displayEmailTip = emailRegex.test(email);
    }
  }

  success(data, ngForm, registrationData) {
    this.utilityService.showLoading(false);
    if (Number(data?.status) === HttpStatusCode.Ok && registrationData['is_free_account']) {
      this.onRegistrationReady(data, ngForm);
    } else {
      this.utilityService.showToast({ Title: '', Message: data.message || 'Failed to register account', Type: 'error' });
    }
  }

  private onRegistrationReady(data, ngForm) {
    this.utilityService.showToast({ Title: '', Message: data.message, Type: 'success' });
    ngForm.resetForm();
    this.router.navigate([this.configuration.ROUTING_CONSTANTS.REGISTER_SUCCESS]);
  }

  ngOnDestroy() {
    this.utilityService.setNoRegistrationFlag(false);
    this.subscriptions.forEach(s => s.unsubscribe());
  }
}
