import { Injectable, inject } from '@angular/core';
import { UserManager } from '../../user/services/user-manager.service';
import { StartTimerEvent, StopTimerEvent, KillWorkerEvent } from './perf.interface';
import { API_V2_URL } from '@common-lib/lib/injection-tokens/url.tokens';

@Injectable({
  providedIn: 'root'
})
export class PerfService {
  public componentsToTrack = {
    login: 'Login to Home', // <poc - across components flow>
    homePage: 'Home' // <poc - within component flow>
  };

  private token = null;
  private tokenExpiry = Date.now()-10;
  private stopEventGenerators = {};
  private readonly worker: Worker = null;
  private readonly apiUrl: string = inject(API_V2_URL);
  private readonly urls = {
    logEvent: this.apiUrl+'company/log_event/'
  }

  constructor(
    private readonly userManager: UserManager
  ) {
    if (typeof Worker !== 'undefined') {
      this.worker = new Worker(new URL('./perf.worker', import.meta.url), { type: 'module' });
    } else {
      console.warn('Web Workers are not supported in this environment.');
    }
  }

  startTrackEvent(eventName: string) {
    const message:StartTimerEvent = {
      event_type: 'start',
      event_name: eventName
    }
    this.worker?.postMessage(message);
  }

  handleTokenExpiry(eventName: string) {
    if (this.tokenExpiry < Date.now()) {
      let userManagerSubscription$ = this.userManager.getCurrentSession().subscribe(session => {
        userManagerSubscription$.unsubscribe();
        userManagerSubscription$ = null; // for garbage collection
        this.token = session.getIdToken().getJwtToken();
        this.tokenExpiry = session.getIdToken().getExpiration() * 1000;
        // continue the rest of the execution
        this.stopEventGenerators[eventName].next();
        this.stopEventGenerators[eventName] = null;
      });
    } else {
      queueMicrotask(() => {
        this.stopEventGenerators[eventName].next();
      });
    }
  }

  *stopTrackEventGenerator(eventName: string, data: any) {
    yield this.handleTokenExpiry(eventName);
    // sends the stop event which sends a log to Sumo Logic
    const message: StopTimerEvent = {
      event_type: 'stop',
      event_name: eventName,
      request: {
        url: this.urls.logEvent,
        token: this.token,
        body: data
      }
    };
    this.worker?.postMessage(message);
  }

  stopTrackEvent(eventName: string, data: any) {
    this.stopEventGenerators[eventName] = this.stopTrackEventGenerator(eventName, data);
    this.stopEventGenerators[eventName].next();
  }

  stopMultipleTrackEvents(eventNames: string[], data: any) {
    eventNames.forEach(eventName => this.stopTrackEvent(eventName, data));
  }

  terminate(): void {
    this.worker?.postMessage({event_type:'kill'});
  }
}
