import { BudgetTableRecord, BudgetTableRecordType, BudgetTableRecordValues } from '../components/budget-table/budget-table.types';
import { SortParams } from 'app/shared/types/sort-params.interface';
import { BudgetSegment, BudgetSegmentAmount } from 'app/shared/types/segment.interface';
import { createDeepCopy, generateGUID, roundDecimal } from 'app/shared/utils/common.utils';
import { BudgetTimeframe } from 'app/shared/types/timeframe.interface';
import { SegmentGroup } from 'app/shared/types/segment-group.interface';
import { UserOwner } from '../components/budget-settings-page/budget-settings-page.type';

export class BudgetTableHelpers {
  public static sortData(appliedSorting: SortParams, data: BudgetTableRecord[]) {
    const { column, reverse } = appliedSorting;
    const compareFn = (itemA, itemB) => (
      reverse
        ? itemB[column].localeCompare(itemA[column])
        : itemA[column].localeCompare(itemB[column])
    );

    // Groupless segments should go first
    data.sort((itemA, itemB) => {
      if (itemA.type === itemB.type) {
        return compareFn(itemA, itemB);
      }

      if (itemA.type === BudgetTableRecordType.Segment) {
        return -1;
      }

      if (itemB.type === BudgetTableRecordType.Segment) {
        return 1;
      }

      return 0;
    });

    data.forEach(record => {
      if (record.type === BudgetTableRecordType.Group && record.nestedRecords.length) {
        record.nestedRecords.sort(compareFn);
      }
    });
  }

  public static provideWithOwnerDetails(data: BudgetTableRecord[], ownersMap: Record<number, UserOwner> = {}) {
    data.forEach(record => {
      const ownerID = record.owner?.id;
      if (record.type === BudgetTableRecordType.Group && record.nestedRecords?.length) {
        BudgetTableHelpers.provideWithOwnerDetails(record.nestedRecords, ownersMap);
      }

      record.owner = {
        ...record.owner,
        name: ownersMap[ownerID]?.name,
        initials: ownersMap[ownerID]?.initials,
        status: ownersMap[ownerID]?.status
      };
    });
  }

  public static mapSegmentToRecord(budgetSegment: BudgetSegment): BudgetTableRecord {
    const mappedValues = BudgetTableHelpers.mapSegmentAmountsToRecordValues(budgetSegment.amounts);

    return {
      id: budgetSegment.id,
      key: budgetSegment.key,
      name: budgetSegment.name,
      owner: {
        id: budgetSegment.owner,
        name: '',
        initials: ''
      },
      type: BudgetTableRecordType.Segment,
      segmentGroup: budgetSegment.segmentGroup,
      segmentGroupKey: budgetSegment.segmentGroupKey,
      values: {
        ...mappedValues,
        projected: budgetSegment.projectedAmount
      }
    };
  }

  public static mapSegmentAmountsToRecordValues(amounts: BudgetSegmentAmount[]): BudgetTableRecordValues {
    const initialValues = {
      allocated: {},
      forecasted: {},
      total: 0,
      totalForecast: 0
    };
  
    if (!amounts || amounts.length === 0) {
      return initialValues;
    }
  
    return amounts.reduce((result, alloc) => {
    
      const newTotalForecast = result.totalForecast + (alloc?.forecastAmount ?? 0);
    
      const updatedResult = {
        allocated: {
          ...result.allocated,
          [alloc.budgetAllocationId]: alloc.amount
        },
        forecasted: {
          ...result.forecasted,
          [alloc.budgetAllocationId]: alloc?.forecastAmount
        },
        total: roundDecimal(result.total + alloc.amount, 2),
        totalForecast: roundDecimal(newTotalForecast, 2)
      };
    
    
      return updatedResult;
    }, initialValues);
    
  }  

  public static sumRecordValues(target: BudgetTableRecordValues, source: BudgetTableRecordValues): BudgetTableRecordValues {
    const result: BudgetTableRecordValues = {
      allocated: {},
      forecasted: {},
      totalForecast: 0,
      total: 0
    };

    if (source.hasOwnProperty('projected') && target.hasOwnProperty('projected')) {
      result.projected = roundDecimal(target.projected + source.projected, 2);
    }
    result.total = roundDecimal(target.total + source.total, 2);
    result.allocated = BudgetTableHelpers.sumAllocatedAmounts(target, source);
    result.forecasted = BudgetTableHelpers.sumForecastedAmounts(target, source);
    result.totalForecast = roundDecimal(target.totalForecast + source.totalForecast, 2);

    return result;
  }

  public static sumAllocatedAmounts(target: BudgetTableRecordValues, source: BudgetTableRecordValues): Record<number, number> {
    const result = { ...target.allocated };

    Object.entries(source.allocated).forEach(([ key, value ]) => {
      result[key] = roundDecimal((target.allocated[key] || 0) + value, 2);
    });

    return result;
  }

  public static sumForecastedAmounts(target: BudgetTableRecordValues, source: BudgetTableRecordValues): Record<number, number> {
    const result = { ...target.forecasted };

    Object.entries(source.forecasted).forEach(([ key, value ]) => {
      result[key] = roundDecimal((target.forecasted[key] || 0) + value, 2);
    });

    return result;
  }

  public static createSegmentAmounts(budgetAllocations: BudgetTimeframe[], budget): BudgetSegmentAmount[] {
    return budgetAllocations.map(
      alloc => ({
        id: null,
        segmentId: null,
        amount: 0,
        budget,
        budgetAllocationId: alloc.id
      })
    )
  };

  public static createSegment(budgetAllocations: BudgetTimeframe[], budget): BudgetSegment {
    return {
      id: null,
      name: '',
      owner: null,
      projectedAmount: 0,
      budget,
      amounts: BudgetTableHelpers.createSegmentAmounts(budgetAllocations, budget),
      key: generateGUID(),
      segmentGroup: null,
      segmentGroupKey: null
    }
  };

  public static duplicateSegment(sourceSegment: BudgetSegment, data: Partial<BudgetSegment>): BudgetSegment {
    return {
      ...sourceSegment,
      id: null,
      key: generateGUID(),
      name: '',
      amounts: sourceSegment.amounts.map(
        segmentAmount => ({ ...segmentAmount, id: null, segmentId: null })
      ),
      ...data
    };
  }

  public static createGroup(data: Partial<SegmentGroup>): SegmentGroup {
    return {
      id: null,
      name: '',
      key: null,
      budget: null,
      owner: null,
      numberOfSegments: 0,
      ...data
    };
  }

  public static duplicateGroup(sourceGroup: SegmentGroup, data: Partial<SegmentGroup>): SegmentGroup {
    return {
      ...sourceGroup,
      id: null,
      key: generateGUID(),
      name: '',
      ...data
    };
  }

  public static mapGroupToRecord(group: SegmentGroup, nestedRecords: BudgetTableRecord[]): BudgetTableRecord {
    const initialGroupValues = {
      allocated: {},
      forecasted: {},
      totalForecast: 0,
      total: 0,
      projected: 0
    };
    const values = nestedRecords.reduce(
      (resultValues, record) => BudgetTableHelpers.sumRecordValues(resultValues, record.values),
      initialGroupValues
    );

    return {
      id: group.id,
      key: group.key,
      type: BudgetTableRecordType.Group,
      name: group.name,
      owner: {
        id: group.owner,
        name: '',
        initials: ''
      },
      nestedRecords,
      values
    };
  }

  public static deepCopyAsMap<T extends { id: number }>(data: T[]): Record<string, T> {
    return data.reduce((store, item) => {
      store[item.id] = createDeepCopy(item);

      return store;
    }, {});
  };
}
