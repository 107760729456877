import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, inject, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { WidgetConfig, WidgetState } from '../../types/widget.interface';
import { WidgetStateService } from '../../services/widget-state.service';
import { Subject } from 'rxjs';
import { filter, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { CompanyDO } from 'app/shared/types/company.interface';
import { UserManager } from 'app/user/services/user-manager.service';
import { Budget, BudgetTimeframesType } from 'app/shared/types/budget.interface';
import { BudgetDataService } from '../../../dashboard/budget-data/budget-data.service';
import { CompanyDataService } from 'app/shared/services/company-data.service';
import { UtilityService } from 'app/shared/services/utility.service';
import { BudgetTimeframe } from 'app/shared/types/timeframe.interface';
import {
  BudgetMinusExpenseRow,
  BudgetMinusExpenseRowType
} from '../../types/budget-minus-expenses-widget.types';
import { Configuration } from 'app/app.constants';
import { FilterName, FilterSet } from 'app/header-navigation/components/filters/filters.interface';
import { FilterManagementService } from 'app/header-navigation/components/filters/filter-services/filter-management.service';
import { BudgetSegmentAccess } from 'app/shared/types/segment.interface';
import {
  WidgetService,
  BudgetTimeframeData,
  BudgetTimeframeDataItem
} from '../../../dashboard/widget.service';
import { defineTimeframe } from 'app/shared/utils/budget.utils';
import { HomePageService } from '../../services/home-page.service';
import { ExpenseDO, ExpenseTotalsDO } from 'app/shared/types/expense.interface';
import { ExpenseAllocationMode } from 'app/shared/types/expense-allocation-mode.type';

@Component({
  selector: 'budget-minus-expenses-widget-old-world',
  templateUrl: './budget-minus-expenses-widget.component.html',
  styleUrls: ['./budget-minus-expenses-widget.component.scss']
})
export class BudgetMinusExpensesWidgetComponent implements OnInit, OnDestroy {
  @Input() config: WidgetConfig;
  @Output() onLoaded = new EventEmitter();

  private timeframesList: BudgetTimeframe[];
  private readonly cdRef = inject(ChangeDetectorRef);

  private readonly destroy$ = new Subject<void>();
  private timeframesData: BudgetTimeframeData;
  private expenses: ExpenseDO[];
  private expenseTotals: ExpenseTotalsDO;
  private contextChanged = false;
  public widgetState = WidgetState;
  public state = WidgetState.INITIAL;
  public company: CompanyDO;
  public currentBudget: Budget;
  public budgetTimeframes: BudgetTimeframe[];
  public budgetSegments: BudgetSegmentAccess[];
  public budgetType: BudgetTimeframesType;
  public currencySymbol: string;
  public tableHeaderCells: string[] = [];
  public tableRows: BudgetMinusExpenseRow[] = [];

  private static getFiltersForOverdueExpenses(pastTimeframeIds: number[]): FilterSet {
    return {
      [FilterName.Timeframes]: pastTimeframeIds || [],
      [FilterName.Statuses]: [ExpenseAllocationMode.Planned, ExpenseAllocationMode.Committed]
    };
  }

  private static getExpenseAmount(amount: number, actualAmount: number, mode: string): number {
    return (mode === ExpenseAllocationMode.Planned ? amount : actualAmount) || 0;
  }

  constructor(
    private readonly widgetStateManager: WidgetStateService,
    private readonly userManager: UserManager,
    private readonly budgetDataService: BudgetDataService,
    private readonly companyDataService: CompanyDataService,
    private readonly utilityService: UtilityService,
    private readonly router: Router,
    private readonly configuration: Configuration,
    private readonly filterManager: FilterManagementService,
    private readonly widgetService: WidgetService,
    private readonly homePageService: HomePageService
  ) { }

  ngOnInit(): void {
    this.setState(WidgetState.LOADING);
    this.loadContextData();
    this.homePageService.noBudgets$
      .pipe(
        takeUntil(this.destroy$),
        take(1)
      )
      .subscribe(
        () => {
          this.setState(WidgetState.HIDDEN);
          this.destroy$.next();
        }
      );
    this.companyDataService.selectedCompanyDO$
      .pipe(takeUntil(this.destroy$))
      .subscribe(company => {
        this.company = company
        this.currencySymbol = this.company && this.company.currency_symbol;
      });
    this.budgetDataService.timeframeList$.pipe(
      tap(tfList => this.timeframesList = tfList)
    );
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private setState(state: WidgetState) {
    this.state = state;
    this.widgetStateManager.setState(this.state, this.config);
    this.cdRef.detectChanges();
  }

  private onBudgetSelected(budget: Budget) {
    this.budgetType = budget && (<any> BudgetTimeframesType)[budget.type];
  }

  private loadContextData() {
    const totals$ = this.homePageService.expensesTotals$.pipe(
      filter(event => event && event.budgetId === this.currentBudget?.id),
      filter(event => event.forceReload || this.contextChanged),
      take(1),
      takeUntil(this.destroy$),
      tap(event => {
        this.expenseTotals = event.data;
      }),
    );
    const expenses$ = this.homePageService.expenses$.pipe(
      filter(event => event && event.budgetId === this.currentBudget?.id),
      filter(event => event.forceReload || this.contextChanged),
      tap(event => {
        this.expenses = event.data;
      }),
      switchMap(() => totals$),
      takeUntil(this.destroy$)
    );

    this.homePageService.contextData$.pipe(
      filter(data => data != null),
      tap((data) => {
        if (this.state !== WidgetState.HIDDEN) {
          this.setState(WidgetState.LOADING);
        }
        this.contextChanged = true;
        this.currentBudget = data.budget;
        this.budgetTimeframes = data.timeframes;
        this.budgetSegments = data.segments;
        this.onBudgetSelected(data.budget);
        this.homePageService.loadExpenses({
          budgetId: this.currentBudget.id,
          companyId: this.currentBudget.company,
          allocationsIds: this.budgetTimeframes.map(tf => tf.id)
        })
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          error: err => this.utilityService.handleError(err)
        });
      }),
      switchMap(() => expenses$),
      takeUntil(this.destroy$)
    )
    .subscribe(
      () => { this.loadData(); },
      (err) => this.utilityService.handleError(err)
    );
  }

  private loadData(): void {
    this.contextChanged = false;
    this.widgetService.getTimeframeGraphDetails({
      'budget': this.currentBudget.id,
      'allocations': this.budgetTimeframes.map(tf => tf.id).join(','),
      'segment1': this.budgetSegments.map(tf => tf.id).join(',')
    }).pipe(
      tap(data => this.timeframesData = data)
    ).subscribe(
      () => {
        this.prepareTableData();
        this.setState(WidgetState.READY);
      },
      error => {
        this.setState(WidgetState.FAILED);
        this.utilityService.handleError(error);
      }
    );
  }

  private goToExpensesPage(filterSet?: FilterSet) {
    this.navigateTo([this.configuration.ROUTING_CONSTANTS.SPENDING_MANAGEMENT], filterSet)
  }

  private goToDashboardPage(filterSet?: FilterSet) {
    this.navigateTo([this.configuration.ROUTING_CONSTANTS.DASHBOARD], filterSet);
  }

  private navigateTo(commands: any[], filterSet?: FilterSet) {
    if (filterSet) {
      this.filterManager.updateCurrentFilterSet(filterSet);
    }
    this.router.navigate(commands);
  }

  private getTFBudget(
    tfData: BudgetTimeframeDataItem[] = [],
    tfId?: number
  ): number {
    return tfData
      .filter(tf => !tfId || tf.company_budget_alloc_id === tfId)
      .map(tf => tf.total)
      .reduce((a, sum) => a + sum, 0);
  }

  private prepareTableData() {
    if (!this.timeframesData || !this.expenses || !this.expenseTotals) {
      return;
    }

    this.tableHeaderCells = ['', 'This Year', ''];
    const currentTf = defineTimeframe(this.budgetTimeframes, this.currentBudget);
    const pastTimeframeIds = currentTf ? this.getPastTimeframeIds(currentTf) : [];

    const budgetDataRow: BudgetMinusExpenseRow = {
      rowType: BudgetMinusExpenseRowType.data,
      title: 'Budget',
      FYAmountCell: {
        value: this.getTFBudget(this.timeframesData.data),
        onClick: () => this.goToDashboardPage({})
      }
    };

    const expenseDataRow: BudgetMinusExpenseRow = {
      title: 'Expenses',
      rowType: BudgetMinusExpenseRowType.data,
      FYAmountCell: {
        value: this.expenseTotals.total_amount,
        warning:
          this.hasOverdueExpenses(this.expenses, pastTimeframeIds) ?
            {
              text: 'Includes overdue expenses',
              action: () => this.goToExpensesPage(BudgetMinusExpensesWidgetComponent.getFiltersForOverdueExpenses(pastTimeframeIds))
            } :
            null,
        onClick: () => this.goToExpensesPage({})
      }
    };

    const totalDataRow: BudgetMinusExpenseRow = {
      title: '',
      rowType: BudgetMinusExpenseRowType.total,
      FYAmountCell: {
        value: budgetDataRow.FYAmountCell.value -  expenseDataRow.FYAmountCell.value,
        hasProblem: budgetDataRow.FYAmountCell.value < expenseDataRow.FYAmountCell.value,
        highlighted: true
      }
    };

    if (this.budgetType !== BudgetTimeframesType.Year && currentTf != null) {
      const filtersByCurrentTf = {[FilterName.Timeframes]: [currentTf.id]};

      budgetDataRow.currentTFAmountCell = {
        value: this.getTFBudget(this.timeframesData.data, currentTf.id),
        onClick: () => this.goToDashboardPage(filtersByCurrentTf)
      };

      expenseDataRow.currentTFAmountCell = {
        value: this.getCurrentTFExpensesTotal(this.expenses, currentTf),
        onClick: () => this.goToExpensesPage(filtersByCurrentTf)
      };

      totalDataRow.currentTFAmountCell = {
        value: budgetDataRow.currentTFAmountCell.value -  expenseDataRow.currentTFAmountCell.value,
        hasProblem: budgetDataRow.currentTFAmountCell.value < expenseDataRow.currentTFAmountCell.value,
        highlighted: true
      };

      this.tableHeaderCells.splice(1, 0, `This ${this.budgetType}`);
    }

    this.tableRows = [budgetDataRow, expenseDataRow, totalDataRow];
  }

  private hasOverdueExpenses(expenses: ExpenseDO[], pastTimeframeIds: number[]): boolean {
    if (!pastTimeframeIds || !pastTimeframeIds.length) {
      return false;
    }

    return (expenses || []).some(exp =>
        pastTimeframeIds.includes(exp.company_budget_alloc) &&
        [ExpenseAllocationMode.Planned.toString(), ExpenseAllocationMode.Committed.toString()].includes(exp.mode)
    );
  }

  private getPastTimeframeIds(currentTimeframe: BudgetTimeframe) {
    return this.budgetTimeframes
      .filter(tf => tf.order < currentTimeframe.order)
      .map(tf => tf.id);
  }

  private getCurrentTFExpensesTotal(expenses: ExpenseDO[], currentTimeframe: BudgetTimeframe): number {
    if (!currentTimeframe || !expenses.length) {
      return 0;
    }
    const expSummary =
      this.calcExpensesSummary(
        expenses.filter(exp => exp.company_budget_alloc === currentTimeframe.id),
        exp => ({ amount: exp.amount, actualAmount: exp.actual_amount, mode: exp.mode})
      );
    return expSummary.amount;
  }

  private calcExpensesSummary(
    expenses: ExpenseDO[],
    amountsGetter: (expense: ExpenseDO) => { amount: number, actualAmount: number, mode: string }
  ): { count: number, amount: number } {
    const count = expenses.length;
    const totalAmount =
      expenses.reduce(
        (total, expense) => {
          const { amount, actualAmount, mode } = amountsGetter(expense);
          return total + BudgetMinusExpensesWidgetComponent.getExpenseAmount(amount, actualAmount, mode);
        },
        0
      );

    return { count, amount: totalAmount };
  }
}
