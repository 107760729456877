import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { Observable } from 'rxjs';
import { TagMapping } from './tag-mapping.interface';
import { TagControlEvent } from '../components/tags-control/tags-control.component';
import { AutocompleteItem } from './autocomplete-item.type';
import { BudgetBaseline } from '@shared/services/backend/budget.service';
import { BudgetDataSpecification } from '../../budget-settings/types/budget-data-specification.interface';

export enum DIALOG_ACTION_TYPE {
  STROKED = 'stroked',
  FLAT = 'flat',
  DEFAULT = 'default',
  WARNING = 'warning',
}

export interface DialogAction {
  handler: (data?: any) => any;
  label?: string;
  type?: DIALOG_ACTION_TYPE;
  disabled?: boolean;
  initialFocus?: boolean;
}

export enum BulkUpdateDialogType {
  text,
  textWithAutocomplete,
  tags,
  tagsRemoval
}

export interface DialogContext {
  title: string;
  content?: string;
  headerIcon?: IconProp;
  cancelAction?: DialogAction;
  submitAction?: DialogAction;
  actions?: DialogAction[];
  width?: string;
  noTitle?: boolean;
  parentChangeInheritance?: boolean;
}

export interface BulkUpdateDialogContext extends DialogContext {
  type: BulkUpdateDialogType;
  data?: {
    autocompleteItems?: AutocompleteItem[];
    maxItemLength?: number;
    tagMappings$?: Observable<TagMapping[]>;
    tagMappingsContainer?: TagMapping[];
    onAddTag?: (event: TagControlEvent) => void;
    onCreateTag?: (event: TagControlEvent) => void;
    onRemoveTag?: (event: TagControlEvent) => void;
  };
}

export interface DeleteProductDialogContext extends DialogContext {
  isMetricUsed: boolean;
}

export interface BudgetBaselineListContext extends DialogContext {
  baselineList: Observable<BudgetBaseline[]>;
}

export interface DuplicateBudgetContext extends DialogContext {
  context: BudgetDataSpecification
}
