import { animate, group, style, transition, trigger } from '@angular/animations';
import {
  ManageTableBudgetColumn, ManageTableBudgetColumnName,
  ManageTableDataConfig,
  ManageTableViewConfigSections,
  TableConfigActionType,
  TableConfigKeys
} from '@manage-ceg/types/manage-ceg-page.types';

export const slideFromRightColumnAnimations = trigger('slideFromRightColumn', [
  transition(':enter', [
    style({ opacity: 0, transform: 'translateX(300px)' }),
    group([
      animate('200ms 0s ease', style({ opacity: 1 })),
      animate('500ms 0s ease', style({ transform: 'translateX(0)' })),
    ])
  ]),
  transition(':leave', [
    group([
      animate('200ms 0s ease', style({ opacity: 0 })),
      animate('250ms 0s ease', style({ transform: 'translateX(300px)' })),
    ])
  ])
]);

export const slideFromRightChartAnimations = trigger('slideFromRightChart', [
  transition(':enter', [
    style({ opacity: 0, transform: 'translateX(500px)' }),
    group([
      animate('200ms 0s ease', style({ opacity: 1 })),
      animate('500ms 0s ease', style({ transform: 'translateX(0)' })),
    ])
  ])
]);

export const tableConfiguration: ManageTableDataConfig = {
  [ManageTableViewConfigSections.segmentBreakdownData]: [
    {
      type: TableConfigActionType.checkbox,
      label: 'Campaigns & Exp. Groups',
      key: TableConfigKeys.campaignsAndPrograms,
      value: true,
      disabled: false,
      visible: true,
      connectedWith: []
    }, {
      type: TableConfigActionType.checkbox,
      label: 'Unallocated',
      key: TableConfigKeys.unallocated,
      value: true,
      disabled: false,
      visible: true,
      connectedWith: []
    }, {
      type: TableConfigActionType.toggle,
      label: 'Formula',
      key: TableConfigKeys.formula,
      value: true,
      disabled: false,
      visible: true,
      connectedWith: []
    }
  ],
  [ManageTableViewConfigSections.columns]: [
    {
      type: TableConfigActionType.checkbox,
      label: 'Forecast',
      key: TableConfigKeys.forecast,
      value: true,
      disabled: false,
      visible: false
    },
    {
      key: TableConfigKeys.budget,
      value: true,
      visible: false
    }, {
      key: TableConfigKeys.planned,
      value: false,
      visible: false,
      connectedWith: [TableConfigKeys.committedAndPlanned, TableConfigKeys.separate]
    }, {
      key: TableConfigKeys.committed,
      value: false,
      visible: false,
      connectedWith: [TableConfigKeys.committedAndPlanned, TableConfigKeys.separate]
    }, {
      type: TableConfigActionType.checkbox,
      label: 'Actual Spend',
      key: TableConfigKeys.actual,
      value: true,
      disabled: false,
      visible: true
    }, {
      type: TableConfigActionType.checkbox,
      label: 'Rmng Committed + Planned',
      key: TableConfigKeys.committedAndPlanned,
      value: true,
      disabled: false,
      visible: true,
      connectedWith: []
    }, {
      type: TableConfigActionType.checkbox,
      label: 'Available Budget',
      key: TableConfigKeys.available,
      value: true,
      disabled: false,
      visible: true
    }, {
      type: TableConfigActionType.toggle,
      label: 'Formula',
      key: TableConfigKeys.columnsFormula,
      value: false,
      disabled: false,
      visible: true,
      connectedWith: []
    }, {
      type: TableConfigActionType.toggle,
      label: 'Separate',
      key: TableConfigKeys.separate,
      value: true,
      disabled: false,
      visible: true,
      connectedWith: [TableConfigKeys.committedAndPlanned]
    }
  ],
  [ManageTableViewConfigSections.columnsFormula]: false
}

export const budgetAllocationColumns: Record<ManageTableBudgetColumnName, ManageTableBudgetColumn> = {
  [ManageTableBudgetColumnName.Budget]: { id: ManageTableBudgetColumnName.Budget, label: ['Total', 'Budget'] },
  [ManageTableBudgetColumnName.ConvertedBudget]: { id: ManageTableBudgetColumnName.ConvertedBudget, label: ['Total', 'Budget'] }, // in company currency
  [ManageTableBudgetColumnName.Actual]: { id: ManageTableBudgetColumnName.Actual, label: ['Actual', 'Spend'] },
  [ManageTableBudgetColumnName.Committed]: { id: ManageTableBudgetColumnName.Committed, label: ['Remaining', ' Committed', 'Budget'] },
  [ManageTableBudgetColumnName.Planned]: { id: ManageTableBudgetColumnName.Planned, label: ['Remaining', 'Planned', 'Budget'] },
  [ManageTableBudgetColumnName.CommittedAndPlanned]: { id: ManageTableBudgetColumnName.CommittedAndPlanned, label: ['Rmng Committed', '+ Planned'] },
  [ManageTableBudgetColumnName.Available]: { id: ManageTableBudgetColumnName.Available, label: ['Available', 'Budget'] },
  [ManageTableBudgetColumnName.Forecast]: { id: ManageTableBudgetColumnName.Forecast, label: ['Forecast'] },
}
