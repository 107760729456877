import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { appliedSelection, selectionTypes } from '../../custom-fields.type';
import { IconHeaderModalService } from '../../../../shared/services/modal-with-icon-header.service';
import { ModalContext } from '../../../../shared/components/modal-with-icon-header/modal-with-icon-header.component';
import { Configuration } from '../../../../app.constants';

@Component({
  selector: 'custom-field-list',
  templateUrl: './custom-field-list.component.html',
  styleUrls: ['./custom-field-list.component.scss']
})
export class CustomFieldListComponent implements OnInit {
  @ViewChild('captchaTemplate') captchaTemplate!: TemplateRef<any>;
  @Input() listTitle: string;
  @Input() selectionType: appliedSelection['type'];
  @Input() is_draft: boolean;
  @Input() for_campaign: boolean;
  @Input() for_program: boolean;
  @Input() for_expense: boolean;
  @Input() selected: boolean;
  @Input() showActions: boolean = true;
  @Input() listId: number;
  @Output() delete = new EventEmitter<number>();

  selectionTypes = selectionTypes;
  captchaInput: string = '';
  modalData!: ModalContext;
  redHeader: boolean = true;

  constructor(
    private modalService: IconHeaderModalService,
    private readonly config: Configuration
  ) {}

  ngOnInit(): void {
    this.modalData = {
      title: this.config.customFieldsDescriptions.deleteTitle,
      content: `You will permanently lose the '${this.listTitle}' custom field from everywhere it is used.`,
      icon: {
        prefix: 'fas',
        name: 'exclamation-triangle'
      },
      buttons: [
        { text: 'Delete', color: 'warn', disabled: true }
      ]
    };
  }

  deleteEvent() {
    this.captchaInput = '';
    this.modalService.openInitialUpdatePopup(this.modalData, this.captchaTemplate, this.redHeader).subscribe((result) => {
      if (result) {
        this.delete.emit(this.listId);
      }
    });
  }

  onCaptchaInputChange() {
    this.modalData.buttons[0].disabled = this.captchaInput !== 'DELETE';
  }
}
