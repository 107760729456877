import { Component, EventEmitter, Inject, Input, Output, TemplateRef } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';


@Component({
  selector: 'modal-with-icon-header',
  styleUrls: ['./modal-with-icon-header.component.scss'],
  templateUrl: './modal-with-icon-header.component.html'
})
export class ModalWithIconHeaderComponent {
  @Input() customContent = false;
  @Input() contentTemplate?: TemplateRef<any>;
  @Input() redHeader = false;
  @Input() panelClass: string;
  @Input() secondaryIcon= false;
  @Input() showDontShowAgainCheckbox = false;
  @Output() dontShowAgainChanged = new EventEmitter<boolean>();

  constructor(
    private readonly dialogRef: MatDialogRef<ModalWithIconHeaderComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ModalContext
  ) {
  }

  onCancel() {
    this.dialogRef.close();
  }

  onSubmit(e) {
    this.dialogRef.close(e);
  }

  onCheckboxChange(checked: boolean) {
    this.dontShowAgainChanged.emit(checked);
  }
}
export interface ModalContext {
  title: string;
  icon: {
    prefix: string;
    name: string;
  };
  content: string;
  buttons: { text: string, color: string, disabled: boolean }[];
}