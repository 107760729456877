import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { filter, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { WidgetConfig, WidgetState } from '../../types/widget.interface';
import { WidgetStateService } from '../../services/widget-state.service';
import { Company } from 'app/shared/types/company.interface';
import { Budget } from 'app/shared/types/budget.interface';
import { BudgetDataService } from '../../../dashboard/budget-data/budget-data.service';
import { UserManager } from 'app/user/services/user-manager.service';
import { CompanyDataService } from 'app/shared/services/company-data.service';
import { UtilityService } from 'app/shared/services/utility.service';
import { BudgetService } from 'app/shared/services/backend/budget.service';
import { UserBudgetSummaryDO } from 'app/shared/types/user-budget-summary.interface';
import { Configuration } from 'app/app.constants';
import { HomePageService } from '../../services/home-page.service';
import { isFreshBudget } from 'app/shared/utils/budget.utils';

enum SpendProgressState {
  FRESH = 'fresh',
  UNDERSPEND = 'underspend',
  SPENDING = 'spending',
  OVERSPEND = 'overspend'
}

@Component({
  selector: 'spend-progress-widget',
  styleUrls: ['./spend-progress-widget.component.scss'],
  templateUrl: './spend-progress-widget.component.html'
})
export class SpendProgressWidgetComponent implements OnInit, OnDestroy {
  @Input() config: WidgetConfig;
  @Output() onLoaded = new EventEmitter();

  private readonly destroy$ = new Subject<void>();
  private readonly SPENDING_RATE = 5;
  private readonly MAX_ABSOLUTE_RATE = 999;
  private contextChanged = false;
  private budgetSummary: UserBudgetSummaryDO = null;
  public currentBudget: Budget = null;
  public company: Company;
  public widgetState = WidgetState;
  public state = WidgetState.INITIAL;
  public overlayText = 'Click to go to the Dashboard';
  public value;
  public sign;
  public titleByProgressState = {
    [SpendProgressState.FRESH]: 'Spending YTD vs plan',
    [SpendProgressState.SPENDING]: 'Spending',
    [SpendProgressState.UNDERSPEND]: 'Underspend YTD vs plan',
    [SpendProgressState.OVERSPEND]: 'Overspend YTD vs plan'
  };
  public currentProgressState = SpendProgressState.SPENDING;
  public PROGRESS_STATE = SpendProgressState;

  constructor(
    private readonly widgetStateManager: WidgetStateService,
    private readonly budgetDataService: BudgetDataService,
    private readonly userManager: UserManager,
    private readonly companyDataService: CompanyDataService,
    private readonly homePageService: HomePageService,
    private readonly utilityService: UtilityService,
    private readonly budgetService: BudgetService,
    private readonly router: Router,
    private readonly configuration: Configuration
  ) {}

  ngOnInit(): void {
    this.setState(WidgetState.LOADING);
    this.loadContextData();
    this.homePageService.noBudgets$
      .pipe(
        takeUntil(this.destroy$),
        take(1)
      )
      .subscribe(
        () => {
          this.setState(WidgetState.HIDDEN);
          this.destroy$.next();
        }
      )
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private setState(state: WidgetState) {
    this.state = state;
    this.widgetStateManager.setState(this.state, this.config);
  }

  private handleError(err) {
    this.utilityService.handleError(err);
  }

  private prepareChartData() {
    const overspent = Math.round(Math.min(this.budgetSummary.overspent, this.MAX_ABSOLUTE_RATE));
    const underspent = Math.round(Math.min(this.budgetSummary.underspent, this.MAX_ABSOLUTE_RATE));

    this.contextChanged = false;
    this.currentProgressState = SpendProgressState.SPENDING;
    this.value = 0;

    if (isFreshBudget(this.currentBudget)) {
      this.currentProgressState = SpendProgressState.FRESH;
      this.value = 0;
      this.sign = null;
      return;
    }

    if (underspent) {
      this.currentProgressState = underspent > this.SPENDING_RATE ? SpendProgressState.UNDERSPEND : SpendProgressState.SPENDING;
      this.value = underspent;
      this.sign = '-';
    }

    if (overspent) {
      this.currentProgressState = overspent > this.SPENDING_RATE ? SpendProgressState.OVERSPEND : SpendProgressState.SPENDING;
      this.value = overspent;
      this.sign = this.currentProgressState === SpendProgressState.OVERSPEND ? '+' : '';
    }
  }

  public loadContextData() {
    const summary$ = this.homePageService.userBudgetSummary$
      .pipe(
        filter(event => event && event.budgetId === this.currentBudget?.id),
        filter(event => event.forceReload || this.contextChanged),
        tap(event => {
          this.budgetSummary = event?.data;
        })
      );

    this.homePageService.contextData$
      .pipe(
        filter(data => data != null && !this.homePageService.loadingHomePage),
        tap((data) => {
          this.currentBudget = data.budget;
          this.company = data.company;
          this.contextChanged = true;
        }),
        switchMap(() => summary$),
        takeUntil(this.destroy$)
      )
      .subscribe(
        () => {
          this.prepareChartData();
          setTimeout(() => {
            this.setState(WidgetState.READY);
          });
        },
        (err) => this.handleError(err)
      );
  }

  public overlayAction() {
    this.router.navigate([this.configuration.ROUTING_CONSTANTS.DASHBOARD]);
  }
}
