import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';


@Component({
  selector: 'budget-table-controls',
  templateUrl: './budget-table-controls.component.html',
  styleUrls: ['./budget-table-controls.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BudgetTableControlsComponent {
  @Input() showProjected = true;
  @Input() editableBudget = false;
  @Input() isForecastActive = false;
  @Input() showForecast = true;
  @Output() setProjected = new EventEmitter<boolean>();
  @Output() setForecast = new EventEmitter<boolean>();
  @Output() addSegment = new EventEmitter<void>();

  public toggleProjected() {
    this.setProjected.emit(!this.showProjected);
  }

  public toggleForecast() {
    this.setForecast.emit(!this.showForecast);
  }
}
